import React, { useContext, useEffect, useState } from "react";

import { Form } from "react-bootstrap";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";
import { useForm } from "react-hook-form";

import { CURRENT_USER } from "../../../config";
import { axiosInstance } from "../../../api";
import { useImageDirectUpload } from "../../../hooks";
import { CurrentUserProviderContext } from "../../../contexts";

import {
  LoanOfficerLicenseInfoSection,
  WorkPlaceInfoSection,
  PersonalInfoSection,
} from "./profileSections";

import {
  transformRequestOfficerData,
  transformResponseOfficerData,
} from "./helpers";

import "./styles.scss";

export const OfficerProfile = () => {
  const { imageUpload } = useImageDirectUpload();
  const { currentUser, updateCurrentUser } = useContext(
    CurrentUserProviderContext,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevPrimaryServiceState, setPrevPrimaryServiceState] = useState();

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const [realtorPhoto] = watch(["realtorPhoto"]);
  const [primaryServiceState] = watch(["primaryServiceState"]);
  const [serviceArea] = watch(["serviceArea"]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const imageLink =
        typeof data.realtorPhoto === "object"
          ? await imageUpload({ image: data.realtorPhoto })
          : data.realtorPhoto || "";

      const requestData = transformRequestOfficerData({
        imageLink,
        officerData: data,
      });

      const officerData = await axiosInstance.patch(CURRENT_USER, requestData);

      if (officerData) {
        updateCurrentUser({
          ...currentUser,
          ...officerData.data,
        });
        UiToast.success("Officer data successfully saved!");
        setIsSubmitting(false);
      }
    } catch (err) {
      UiToast.error(err.message || "Something went wrong");
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const formData = transformResponseOfficerData(currentUser);

    reset(formData);
    setPrevPrimaryServiceState(formData.primaryServiceState);
  }, [currentUser, reset]);

  return (
    <div className="w-100">
      <Form
        className="w-100 mt-4 d-flex flex-column align-items-start"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-100 " style={{ maxWidth: "380px" }}>
          <PersonalInfoSection
            errors={errors}
            control={control}
            currentUser={currentUser}
            realtorPhoto={realtorPhoto}
          />

          <WorkPlaceInfoSection
            control={control}
            errors={errors}
            currentUser={currentUser}
          />

          <LoanOfficerLicenseInfoSection
            errors={errors}
            control={control}
            setValue={setValue}
            serviceArea={serviceArea}
            primaryServiceState={primaryServiceState}
            prevPrimaryServiceState={prevPrimaryServiceState}
          />
        </div>

        <ButtonWithSpinner
          spinnerVariant="light"
          data-cy="btn-submit"
          variant="primary"
          type="submit"
          className="mt-4 px-4"
          loading={isSubmitting}
          loadingIndicator="Saving..."
        >
          Save
        </ButtonWithSpinner>
      </Form>
    </div>
  );
};
