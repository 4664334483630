import { subscriptionNames } from "../../common";

export const reEnterContentConfig = {
  [subscriptionNames.agent.premium]: {
    itemName: "zipCode",
    holdItemsName: "zipCodes",
    pageTitle: "Purchase zip code to continue use AskChristee service",
    modalText:
      "To continue using AskChristee service, please enter zip code and proceed to update your payment information.",
    validateMessage: "Please validate your zip code",
  },
  [subscriptionNames["loan officer"].premium]: {
    itemName: "state",
    holdItemsName: "states",
    pageTitle: "Purchase state to continue use AskChristee service",
    modalText:
      "To continue using AskChristee service, please enter state and proceed to update your payment information.",
    validateMessage: "Please validate your state",
  },
};
