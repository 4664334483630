import React, { useCallback, useEffect, useState } from "react";

export const AccountDataProviderContext = React.createContext({
  accountData: {
    paymentData: null,
    nextInvoice: null,
    billingHistory: null,
    subscriptionItems: null,
    totalSubscriptionsPrice: null,
    subscriptionLatestEndDate: null,
  },
  updateAccountData() {},
  setIsDataFetched() {},
});

export const AccountDataProvider = ({ children }) => {
  const [accountData, setAccountData] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState({
    paymentDataFetched: false,
    nextInvoiceFetched: false,
    billingHistoryFetched: false,
    subscriptionItemsFetched: false,
    subscriptionLatestEndDate: false,
  });

  const updateAccountData = useCallback((dataToUpdate) => {
    setAccountData((prevState) => ({ ...prevState, ...dataToUpdate }));
  }, []);

  useEffect(() => {
    if (accountData?.subscriptionItems?.length) {
      updateAccountData({
        totalSubscriptionsPrice: accountData.subscriptionItems.reduce(
          (price, subscription) => price + subscription.amountPaid,
          0,
        ),
      });
    }
  }, [accountData?.subscriptionItems, updateAccountData]);

  return (
    <AccountDataProviderContext.Provider
      value={{
        accountData,
        isDataFetched,
        setIsDataFetched,
        updateAccountData,
      }}
    >
      {children}
    </AccountDataProviderContext.Provider>
  );
};
