import PropTypes from "prop-types";

import { columnConfig } from "./config";
import { Loader } from "../../atoms";

export const EmptyTableState = ({ isLoading }) => {
  return (
    <tr>
      <td
        colSpan={columnConfig.length}
        className=""
        style={{
          height: "460px",
          backgroundColor: "#DFE3EA",
        }}
      >
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          {isLoading ? <Loader /> : <h4>No users found!</h4>}
        </div>
      </td>
    </tr>
  );
};

EmptyTableState.propTypes = {
  isLoading: PropTypes.bool,
};
