import React, { useCallback, useContext, useState } from "react";

import PropTypes from "prop-types";
import { UiToast } from "@ask-christee/ui-response";

import {
  ActionModal,
  VerifyState,
  PaymentSummary,
  ItemsToPurchaseSection,
  PurchaseItem,
} from "../../molecules";
import { axiosInstance } from "../../../api";
import { SUBSCRIPTION_ITEMS } from "../../../config";
import { SubscriptionPricesProviderContext } from "../../../contexts";

import { SubscriptionItemForm } from "../SubscriptionItemForm";
import { useHoldSubscriptionItems } from "../../../hooks";

export const AddStatesModal = ({
  text,
  title,
  maxItems = 10,
  showModal,
  onSuccessSubmit,
  handleCloseModal,
  submitUrl = SUBSCRIPTION_ITEMS,
  submitMethod = "POST",
}) => {
  const { getPriceByType } = useContext(SubscriptionPricesProviderContext);

  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useHoldSubscriptionItems({
    itemsType: "states",
    subscriptionItems: states?.map(({ state }) => state),
  });

  const handleAddState = useCallback(({ fieldValue }) => {
    setStates((prev) => [...prev, { state: fieldValue }]);
  }, []);

  const handleRemoveState = (index) => {
    setStates((prev) => prev.filter((_, i) => i !== index));
  };

  const purchaseStates = async () => {
    if (!states.length) {
      UiToast.error("Enter at least one state to purchase");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axiosInstance({
        url: submitUrl,
        method: submitMethod,
        data: {
          items: states.map(({ state }) => state),
        },
      });

      if (response) {
        onSuccessSubmit(response);
      }
    } catch (error) {
      UiToast.error(error.response.data || "Something went wrong!");
    } finally {
      setIsLoading(false);
      onCloseModal();
    }
  };

  const onCloseModal = () => {
    handleCloseModal();

    setTimeout(() => {
      setStates([]);
    }, 300);
  };

  const statePrice = getPriceByType({
    subscriptionType: "loan_officer_state",
  })?.amount;

  return (
    <ActionModal
      isLoading={isLoading}
      actionLabel="Pay"
      title={title}
      showModal={showModal}
      onActionClick={purchaseStates}
      closeModal={onCloseModal}
    >
      {text && <p style={{ fontSize: "14px", textAlign: "justify" }}>{text}</p>}
      <SubscriptionItemForm
        onSubmit={handleAddState}
        onRemove={handleRemoveState}
        items={states}
        fieldName="state"
        label="state"
        maxItems={maxItems}
        VerifyItemComponent={VerifyState}
        displayFieldName="state"
      />
      {!!states.length && (
        <ItemsToPurchaseSection className="mt-2">
          {states.map((state, index) => (
            <PurchaseItem
              key={state.state}
              value={state.state}
              onRemoveItem={() => handleRemoveState(index)}
              purchaseItemName="State"
            />
          ))}
        </ItemsToPurchaseSection>
      )}
      {statePrice && (
        <PaymentSummary
          title="State"
          className="mt-3"
          priceItems={states?.map((state) => ({
            value: state.state,
            price: statePrice,
          }))}
        />
      )}
    </ActionModal>
  );
};

AddStatesModal.propTypes = {
  onSuccessSubmit: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  submitMethod: PropTypes.string,
  maxItems: PropTypes.number,
  text: PropTypes.string,
};
