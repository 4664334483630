import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { ZIP_CODE_VALIDATOR } from "../../../config";
import { usePurchaseValidator } from "../../../hooks";

import { VerifyInput } from "../VerifyInput";
import { ZipCodeMessages } from "./ZipCodeMessages";

import "./styles.scss";

const FIELD_NAME = "zipCode";
const ZIP_PATTERN = /^\d{5}$/;

export const VerifyZipCode = ({
  label,
  rules,
  errors,
  control,
  trigger,
  fieldName,
  inputValue,
  placeholder,
  preValidation,
  setIsZipCodeValid,
  enableToastMessages,
  onSuccessVerification,
}) => {
  const inputFieldName = fieldName || FIELD_NAME;

  const { isLoading, validate, validationInfo, validationError } =
    usePurchaseValidator({
      fieldName: inputFieldName,
      validatorUrl: ZIP_CODE_VALIDATOR,
      inputValue,
      trigger,
      enableToastMessages,
    });

  const handleVerifyClick = async () => {
    if (!preValidation || (preValidation && preValidation())) {
      await validate();
    }
  };

  useEffect(() => {
    if (setIsZipCodeValid) {
      setIsZipCodeValid(!!validationInfo);
    }

    if (validationInfo && onSuccessVerification) {
      onSuccessVerification(validationInfo);
    }
  }, [onSuccessVerification, setIsZipCodeValid, validationInfo]);

  return (
    <>
      <Controller
        name={inputFieldName}
        rules={{
          ...rules,
          pattern: { value: ZIP_PATTERN, message: "Invalid zip code provided" },
        }}
        control={control}
        render={({ field: { onChange, value = "", ref } }) => (
          <VerifyInput
            errors={errors}
            value={value}
            forwardedRef={ref}
            name={inputFieldName}
            label={label}
            onChange={onChange}
            isLoading={isLoading}
            placeholder={placeholder}
            handleVerifyClick={handleVerifyClick}
          />
        )}
      />
      {!enableToastMessages && (
        <ZipCodeMessages
          zipError={validationError}
          zipCodeInfo={validationInfo}
          enableToastMessages={enableToastMessages}
        />
      )}
    </>
  );
};

VerifyZipCode.propTypes = {
  trigger: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  inputValue: PropTypes.string,
  rules: PropTypes.object,
  errors: PropTypes.object,
  label: PropTypes.string,
  onVerify: PropTypes.func,
  placeholder: PropTypes.string,
  preValidation: PropTypes.func,
  fieldName: PropTypes.string,
  setIsZipCodeValid: PropTypes.func,
  enableToastMessages: PropTypes.bool,
};
