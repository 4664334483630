import PropTypes from "prop-types";

import { LANDING_URL } from "../../../config";

export const defaultMenuItems = [
  {
    name: "About",
    subItems: [
      {
        name: "About Christee",
        link: `${LANDING_URL}/about`,
      },
      {
        name: "For Home Buyers",
        link: `${LANDING_URL}/about/christee-for-home-buyers`,
      },
      {
        name: "For Real Estate Agents",
        link: `${LANDING_URL}/about/christee-for-real-estate-agents`,
      },
      {
        name: "For Loan Officers",
        link: `${LANDING_URL}/about/for-loan-officers`,
      },
      {
        name: "AskChristee Smarter Agent Network",
        link: `${LANDING_URL}/about/christee-smarter-agent-network`,
      },
    ],
  },
  {
    name: "Modules",
    appLink: "/modules",
  },
  {
    name: "Resources",
    subItems: [
      {
        name: "Articles",
        link: `${LANDING_URL}/resources`,
      },
      {
        name: "Real Estate Terms",
        link: `${LANDING_URL}/real-estate-terms`,
      },
      {
        name: "Frequently Asked Questions",
        link: `${LANDING_URL}/faqs`,
      },
    ],
  },
  {
    name: "Videos",
    subItems: [
      {
        name: "AskChristee Videos",
        link: `${LANDING_URL}/askchristee-video-series`,
      },
      {
        name: "Smart Homebuyer Videos",
        link: `${LANDING_URL}/smart-homebuyer-series`,
      },
    ],
  },
  {
    name: "Contact",
    link: `${LANDING_URL}/contact`,
  },
];

export const subItemPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
});

export const menuItemPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  subItems: PropTypes.arrayOf(subItemPropTypes),
  appLink: PropTypes.string,
  link: PropTypes.string,
});

export const menuItemsPropTypes = PropTypes.arrayOf(menuItemPropTypes);
