import React from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { CustomInput } from "../CustomInput";

export const CustomHookFormInput = ({
  control,
  name,
  rules,
  inputComponent: ComponentToRender = CustomInput,
  ...props
}) => (
  <Controller
    name={name}
    rules={rules}
    control={control}
    render={({ field: { onChange, value = "", ref } }) => (
      <ComponentToRender
        {...props}
        name={name}
        value={value}
        forwardedRef={ref}
        onChange={onChange}
      />
    )}
  />
);

CustomHookFormInput.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  rules: PropTypes.object,
  inputComponent: PropTypes.elementType,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  groupClassName: PropTypes.string,
  options: PropTypes.array,
  inputClassName: PropTypes.string,
};
