import PropTypes from "prop-types";

import { FormMessage } from "../../atoms";

export const ZipCodeMessages = ({ zipError, zipCodeInfo }) => (
  <>
    {!zipError && !zipCodeInfo && (
      <FormMessage status="info" text="Enter a zip code and verify it." />
    )}
    {zipError && <FormMessage status="error" text={zipError} />}
    {zipCodeInfo && (
      <>
        <FormMessage
          status="success"
          text="This zip code is available for purchase."
          addition={
            <div className="form-message-addition">
              <p className="form-message-addition__item form-message-addition__item--bold">
                Zip Code Information: {zipCodeInfo.code}
              </p>
              <p className="form-message-addition__item">
                City: {zipCodeInfo.city}
              </p>
              <p className="form-message-addition__item">
                County: {zipCodeInfo.area}
              </p>
              <p className="form-message-addition__item">
                State: {zipCodeInfo.state}
              </p>
            </div>
          }
        />
        <FormMessage
          status="info"
          text="Zip code will be reserved for 24 hours unless purchase is finalized."
        />
      </>
    )}
  </>
);

ZipCodeMessages.propTypes = {
  zipError: PropTypes.string,
  zipCodeInfo: PropTypes.shape({
    code: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }),
};
