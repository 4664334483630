export const getSubscriptionChange = ({
  providedPrice,
  itemsConfig,
  currentPrice,
}) => {
  if (currentPrice === providedPrice) {
    return itemsConfig.active;
  } else if (providedPrice > currentPrice) {
    return itemsConfig.upgrade;
  } else {
    return itemsConfig.downgrade;
  }
};
