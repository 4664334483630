import React, { useEffect, useState } from "react";

import { UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../api";
import { SUBSCRIPTIONS_PRICES } from "../config";

export const SubscriptionPricesProviderContext = React.createContext({
  prices: [],
  isLoading: true,
});

export const SubscriptionPricesProvider = ({ children }) => {
  const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getPriceByType = ({ subscriptionType, isSubsequent = false }) => {
    return prices.find(
      (price) =>
        price.subscriptionType === subscriptionType &&
        price.isSubsequent === isSubsequent,
    );
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance(SUBSCRIPTIONS_PRICES);

        if (response.data) {
          setPrices(response.data);
        }
      } catch (error) {
        UiToast.error(error.response.data || "Something went wrong!");
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <SubscriptionPricesProviderContext.Provider
      value={{ getPriceByType, prices, isLoading }}
    >
      {children}
    </SubscriptionPricesProviderContext.Provider>
  );
};
