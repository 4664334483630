import { Tab } from "react-bootstrap";

import "./styles.scss";

export const CustomTab = ({ eventKey, title, children }) => {
  return (
    <Tab className="custom-tab" eventKey={eventKey} title={title}>
      <div className="custom-tab__content">{children}</div>
    </Tab>
  );
};
