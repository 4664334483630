import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Carousel } from "react-bootstrap";

import { useMediaQuery } from "../../../hooks";

import { CarouselCard } from "./CarouselCard";

import leftArrow from "../../../assets/images/carouselArrowLeft.svg";
import rightArrow from "../../../assets/images/carouselArrowRight.svg";

/**
 * This is the home page carousel.  It can be implemented using
 * any react-carousel library.  In this case, I am using react-bootstrap to
 * layout the component structure.
 *
 * This component needs to receive a data structure with the following
 * properties:
 *
 * title
 * subtitle
 * body
 * module_title
 * module_link
 * video_link
 */

export const ModulesCarousel = ({ data }) => {
  const matchTablet = useMediaQuery(
    "(min-width: 768px) and (max-width: 1200px)",
  );
  const matchPhone = useMediaQuery("(max-width: 768px)");

  const [showData, setShowData] = useState([]);

  useEffect(() => {
    let itemsCount;

    if (matchTablet) itemsCount = 2;
    else if (matchPhone) itemsCount = 1;
    else itemsCount = 3;

    setShowData(
      data.reduce(
        (rows, key, index) =>
          (index % itemsCount === 0
            ? rows.push([key])
            : rows[rows.length - 1].push(key)) && rows,
        [],
      ),
    );
  }, [data, matchPhone, matchTablet]);

  return (
    <Carousel
      nextIcon={<img src={rightArrow} alt="nextIcon" />}
      prevIcon={<img src={leftArrow} alt="prevIcon" />}
      className="help-carousel py-4"
    >
      {showData.map((item, index) => (
        <Carousel.Item key={index} className="help-carousel__item">
          <div className="d-flex justify-content-center h-100 py-4">
            {item.map((cardEl, index) => (
              <div key={index} className="carousel-help-card-wrapper">
                <CarouselCard {...cardEl} />
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

ModulesCarousel.propTypes = {
  data: PropTypes.array,
};
