import { useState } from "react";

import { UiRecaptcha } from "@ask-christee/ui-response";

export const useCustomReCaptcha = (action = "default") => {
  const { executeRecaptcha } = UiRecaptcha.useGoogleReCaptcha();
  const [token, setToken] = useState(null);

  const verifyRecaptcha = async () => {
    if (!executeRecaptcha) {
      throw new Error("Execute recaptcha not yet available");
    }

    const recaptchaToken = await executeRecaptcha(action);

    if (!recaptchaToken) {
      throw new Error("RECAPTCHA verification failed");
    }

    setToken(recaptchaToken);
    return recaptchaToken;
  };

  return { token, verifyRecaptcha };
};
