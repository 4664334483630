import React, { useState } from "react";

import PropTypes from "prop-types";
import { UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../../../../api";
import { ActionModal } from "../../../molecules";
import { ADMIN_HOMEBUYERS } from "../../../../config";

import ActiveBinIcon from "../../../../assets/images/bin-red-icon.svg";
import DisabledBinIcon from "../../../../assets/images/bin-disabled-icon.svg";

export const Action = ({ row, fetchData }) => {
  const { accountType, fullName, Id } = row;
  const isHomebuyer = accountType === "homebuyer";

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleActionClick = async () => {
    try {
      setIsLoading(true);
      await axiosInstance.delete(`${ADMIN_HOMEBUYERS}/${Id}`);
      await fetchData();
    } catch (error) {
      UiToast.error(error?.response?.data || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleIconClick = () => {
    if (isHomebuyer) {
      setShowModal(true);
    }
  };

  return (
    <>
      <div
        className={isHomebuyer ? "cursor-pointer" : ""}
        onClick={handleIconClick}
      >
        <img src={isHomebuyer ? ActiveBinIcon : DisabledBinIcon} alt="" />
      </div>
      <ActionModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        isLoading={isLoading}
        actionLabel="Delete"
        title="Delete user"
        onActionClick={handleActionClick}
      >
        Are you sure you want to delete this user -{" "}
        <span className="fw-bold">{fullName}</span>?
      </ActionModal>
    </>
  );
};

Action.propTypes = {
  row: PropTypes.shape({
    accountType: PropTypes.string,
    fullName: PropTypes.string,
    Id: PropTypes.string,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
};
