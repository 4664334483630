import { useState } from "react";

import { UiToast } from "@ask-christee/ui-response";

import { BaseCard } from "../../molecules";
import { axiosInstance } from "../../../api";
import { BaseSectionTitle, Loader } from "../../atoms";
import { BILLING_HISTORY } from "../../../config";

import { BillingHistoryTable } from "./BillingHistoryTable";

export const BillingHistory = ({ billingHistory, updateBillingHistory }) => {
  const [direction, setDirection] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const changePage = async (newPageDirection) => {
    try {
      setIsLoading(true);

      const isNext = newPageDirection === "next";
      let paramKey = isNext ? "starting_after_id" : "ending_before_id";
      let dataIndex = isNext ? billingHistory.data.length - 1 : 0;

      if (
        (newPageDirection === direction && billingHistory.hasMore) ||
        newPageDirection !== direction
      ) {
        const params = { [paramKey]: billingHistory.data[dataIndex].id };

        const response = await axiosInstance(BILLING_HISTORY, {
          params,
        });

        setDirection(newPageDirection);
        updateBillingHistory(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data || error.message;
      UiToast.error(errorMessage || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BaseSectionTitle title="Billing history" />

      {billingHistory ? (
        <BaseCard
          className="w-100"
          body={() =>
            isLoading ? (
              <div
                style={{ height: "500px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <Loader />
                />
              </div>
            ) : (
              <BillingHistoryTable
                direction={direction}
                changePage={changePage}
                billingHistory={billingHistory}
              />
            )
          }
        />
      ) : (
        <p>There are no payments in history</p>
      )}
    </>
  );
};
