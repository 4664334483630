import PropTypes from "prop-types";

import "./styles.scss";

export const BaseSectionTitle = ({ title, titleClassName }) => (
  <h5 className={`base-section-title ${titleClassName}`}>{title}</h5>
);

BaseSectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
};
