export const configCancellationMessage = ({
  status,
  formattedDate,
  subscriptionItemName,
  isLastActiveSubscriptionItem,
}) => {
  if (status === "past_due") {
    return `By clicking this button you will immediately delete your ${subscriptionItemName}. Are you sure you want to delete?`;
  } else if (isLastActiveSubscriptionItem) {
    return `Are you sure you want to cancel? Your membership will be active till ${formattedDate}, after which your membership will be deactivated.`;
  } else {
    return `If you delete the ${subscriptionItemName} now, you can still use it until the end of the paid period on ${formattedDate}`;
  }
};

export const displayStatusConfig = ({
  status,
  cancelAt,
  itemNextInvoiceDate,
}) => {
  let returnConfig;

  if (cancelAt) {
    returnConfig = {
      text: "Active until:",
      date: cancelAt,
      allowDelete: false,
    };
  } else if (status === "past_due") {
    returnConfig = {
      text: "Past Due",
      allowDelete: false,
    };
  } else {
    returnConfig = {
      text: "Next invoice:",
      date: itemNextInvoiceDate,
      allowDelete: true,
    };
  }

  return returnConfig;
};
