import React from "react";
import PropTypes from "prop-types";

import { Form } from "react-bootstrap";
import { resolvePath } from "../../../utils";

import "./styles.scss";

export const CustomFormSelect = ({
  label,
  value,
  forwardedRef,
  onChange,
  disabled,
  placeholder,
  inputClassName,
  defaultValue,
  errors,
  options,
}) => {
  const currentValue = !value && defaultValue ? "DEFAULT" : value;

  return (
    <Form.Select
      value={currentValue}
      data-cy={name}
      ref={forwardedRef}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder || label}
      isInvalid={!!resolvePath(name, errors)}
      className={`custom-select ${
        currentValue === "DEFAULT" ? "custom-select--default" : ""
      } ${inputClassName || ""}`}
    >
      {defaultValue && !value ? (
        <option value="DEFAULT" disabled>
          Choose {label.toLowerCase()}
        </option>
      ) : null}
      {options.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </Form.Select>
  );
};

CustomFormSelect.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  value: PropTypes.any,
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
