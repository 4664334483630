import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

import "./styles.scss";

export const BaseCard = ({
  title,
  body: BodyToRender,
  topLabel: TopLabelToRender,
  className,
  ...props
}) => {
  return (
    <Card className={`custom-card ${className || ""}`} {...props}>
      {TopLabelToRender && <TopLabelToRender />}
      {title && <Card.Title className="custom-card__title">{title}</Card.Title>}
      <Card.Body className="custom-card__body">
        <BodyToRender />
      </Card.Body>
    </Card>
  );
};

BaseCard.propTypes = {
  title: PropTypes.string,
  topLabel: PropTypes.elementType,
  body: PropTypes.elementType.isRequired,
  className: PropTypes.string,
  ...Card.propTypes,
};
