import { BaseCard, Discount } from "../../molecules";

import PropTypes from "prop-types";

import BinIcon from "../../../assets/images/bin-icon.svg";

export const PlanCard = ({
  totalPrice,
  setShowModal,
  disableCancel,
  subscriptionItem,
  currentSubscriptionInfo,
}) => (
  <BaseCard
    className="w-100"
    body={() => (
      <div className="current-plan">
        <div className="current-plan__name">
          <div className="current-plan__title">
            {currentSubscriptionInfo.title}
          </div>
          <div
            className={`current-plan__type ${
              currentSubscriptionInfo.premium
                ? "current-plan__type--premium"
                : "current-plan__type--basic"
            }`}
          >
            {currentSubscriptionInfo.premium ? "Premium" : "Basic"}
          </div>
        </div>
        <div className="current-plan__details">
          <div className="current-plan__price">
            {currentSubscriptionInfo.premium ? (
              <>
                <span className="current-plan__price-total">Total:</span>
                &nbsp;${totalPrice}
              </>
            ) : (
              <div className="d-flex">
                {subscriptionItem?.discount && (
                  <Discount {...subscriptionItem} className="me-3" />
                )}
                &nbsp;${totalPrice}
              </div>
            )}
            <span className="current-plan__price-period">&nbsp;/mo</span>
          </div>
          {!disableCancel && (
            <div
              onClick={() => setShowModal(true)}
              className="current-plan__cancel"
            >
              <img src={BinIcon} alt="bin icon" />
            </div>
          )}
        </div>
      </div>
    )}
  />
);

PlanCard.propTypes = {
  totalPrice: PropTypes.number,
  subscriptionItem: PropTypes.shape({
    price: PropTypes.number.isRequired,
    discount: PropTypes.object,
  }),
  currentSubscriptionInfo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    premium: PropTypes.bool.isRequired,
  }).isRequired,
  setShowModal: PropTypes.func.isRequired,
  disableCancel: PropTypes.bool,
};
