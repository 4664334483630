import { SpecialLastRow } from "./SpecialLastRow";
import { CustomTableRow } from "./CustomTableRow";
import { CustomTableNavigation } from "./CustomTableNavigation";

import "./styles.scss";

export const CustomBillingTable = ({
  records,
  columns,
  changePage,
  expandableColumns,
  paginationDirection,
  specialLastRow,
}) => {
  const dataToRender = records.data || records;

  return (
    <div className="w-100">
      <div className="w-100 overflow-auto">
        <table className="custom-table">
          <thead className="custom-table__head">
            <tr className="custom-table__row">
              {columns.map((column) => (
                <th key={column.label} className="custom-table__column-name">
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataToRender.map((record) => (
              <CustomTableRow
                key={record.id}
                record={record}
                columns={columns}
                expandableColumns={expandableColumns}
              />
            ))}

            {specialLastRow?.length ? (
              <SpecialLastRow itemsToRender={specialLastRow} />
            ) : null}
          </tbody>
        </table>
      </div>

      {changePage && (
        <CustomTableNavigation
          changePage={changePage}
          hasMore={records.hasMore}
          paginationDirection={paginationDirection}
        />
      )}
    </div>
  );
};
