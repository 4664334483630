export const trimData = (data) => {
  Object.keys(data).forEach((k) => {
    if (typeof data[k] === "string") {
      data[k] = data[k].trim();
    } else if (
      typeof data[k] === "object" &&
      !Array.isArray(data[k]) &&
      data[k] !== null
    ) {
      data[k] = trimData(data[k]);
    }
  });

  return data;
};
