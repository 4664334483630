import React, { useContext, useEffect } from "react";

import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";

import { PageLoader } from "../PageLoader";
import { CurrentUserProviderContext } from "../../../contexts";

export const PublicOnlyRoute = ({ redirectRoute }) => {
  const { updateCurrentUser, currentUser } = useContext(
    CurrentUserProviderContext,
  );

  // give one time pass for processLogin
  useEffect(() => {
    if (currentUser.processLogin) {
      updateCurrentUser({ ...currentUser, processLogin: false });
    }
  }, [currentUser, updateCurrentUser]);

  if (currentUser.isLoading) return <PageLoader />;
  if (!currentUser.isAuthenticated || currentUser.processLogin)
    return <Outlet />;

  return <Navigate to={redirectRoute} />;
};

PublicOnlyRoute.propTypes = {
  redirectRoute: PropTypes.string.isRequired,
};
