import React from "react";

import PropTypes from "prop-types";

import { ButtonCard } from "../ButtonCard";

import { Feature } from "./Feature";
import { TopLabel } from "./TopLabel";

import "./styles.scss";

export const SubscriptionCard = ({
  title,
  price,
  premium,
  features,
  discount,
  disabled,
  isLoading,
  buttonVariant,
  onButtonClick,
  buttonText = "subscribe",
}) => {
  return (
    <ButtonCard
      title={title}
      isLoading={isLoading}
      buttonText={buttonText}
      buttonDisabled={disabled}
      onButtonClick={onButtonClick}
      buttonVariant={buttonVariant}
      topLabel={() => <TopLabel premium={premium} />}
      description={() => (
        <div>
          <ul className="custom-card__features">
            {features.map((feature) => (
              <Feature key={feature.text} {...feature} />
            ))}
          </ul>
          <div className="custom-card__price">
            <div>
              ${price} <span className="custom-card__price-period">/ mo</span>
            </div>
            {discount && (
              <div className="custom-card__discount">
                <p className="custom-card__discount-period">
                  {discount.period}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};

SubscriptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  premium: PropTypes.bool.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      included: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  price: PropTypes.number.isRequired,
  buttonVariant: PropTypes.string,
  disabled: PropTypes.bool,
  buttonText: PropTypes.string,
  isLoading: PropTypes.bool,
  discount: PropTypes.shape({
    newPrice: PropTypes.number,
    period: PropTypes.string,
  }),
  onButtonClick: PropTypes.func,
};
