import { Fragment, useContext, useState } from "react";

import PropTypes from "prop-types";

import { formatDate } from "../../../utils";
import { subscriptionsInfo } from "../../../shared-data";
import { BaseSectionTitle, CustomTooltip } from "../../atoms";
import { SubscriptionPricesProviderContext } from "../../../contexts";

import { PlanCard } from "./PlanCard";
import { getTitleConfig } from "./helpers";
import { CancelSubscriptionModal } from "./CancelSubscriptionModal";

import ErrorIcon from "../../../assets/images/error-icon.svg";

import "./styles.scss";

export const CurrentPlan = ({
  currentUser,
  nextInvoiceDate,
  subscriptionItems,
  totalSubscriptionsPrice,
  subscriptionLatestEndDate,
}) => {
  const { getPriceByType } = useContext(SubscriptionPricesProviderContext);

  const [showModal, setShowModal] = useState(false);

  const { text, date, isRetracted } = getTitleConfig({
    status: currentUser.subscriptionStatus,
    nextInvoiceDate,
    subscriptionLatestEndDate,
  });

  const currentSubscriptionInfo = subscriptionsInfo[
    currentUser.accountType
  ]?.content?.find(({ type }) => type === currentUser.subscriptionType);

  const currentSubscriptionPrice = getPriceByType({
    subscriptionType: currentUser.subscriptionType,
  });

  const isHomebuyerPremium =
    currentUser.subscriptionType === "homebuyer_premium";

  const isDisabledCancel =
    isHomebuyerPremium ||
    currentUser.subscriptionStatus === "pending cancellation";

  return (
    <Fragment>
      <div className="current-plan-title">
        <BaseSectionTitle titleClassName="m-0" title="Current plan" />

        {(!isHomebuyerPremium || isRetracted) && (
          <div className="current-plan-title__next-invoice">
            <div
              className={`current-plan-title__next-invoice-text ${
                isRetracted
                  ? "current-plan-title__next-invoice-text--danger"
                  : ""
              }`}
            >
              {currentUser.subscriptionStatus === "past_due" ? (
                <CustomTooltip content="Recharge your balance or update your payment details. Plan and subscription management temporarily unavailable during this period.">
                  <div className="current-plan-title__past-due-text">
                    <img
                      width="20"
                      className="current-plan-title__icon"
                      src={ErrorIcon}
                      alt="error icon"
                    />
                    {text}
                  </div>
                </CustomTooltip>
              ) : (
                text
              )}
            </div>

            {date && (
              <div className="current-plan-title__next-invoice-date">
                {formatDate(date)}
              </div>
            )}
          </div>
        )}
      </div>

      {currentSubscriptionPrice && (
        <PlanCard
          subscriptionItem={subscriptionItems?.[0]}
          totalPrice={totalSubscriptionsPrice}
          setShowModal={setShowModal}
          disableCancel={isDisabledCancel}
          currentSubscriptionPrice={currentSubscriptionPrice}
          currentSubscriptionInfo={currentSubscriptionInfo}
        />
      )}

      <CancelSubscriptionModal
        showModal={showModal}
        useUntilDate={nextInvoiceDate}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

CurrentPlan.propTypes = {
  currentUser: PropTypes.shape({
    accountType: PropTypes.string.isRequired,
    subscriptionType: PropTypes.string.isRequired,
    subscriptionStatus: PropTypes.string.isRequired,
  }).isRequired,
  nextInvoiceDate: PropTypes.string,
  subscriptionLatestEndDate: PropTypes.string,
};
