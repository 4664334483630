import { BaseCard } from "../../molecules";
import { formatDate } from "../../../utils";
import { BaseSectionTitle } from "../../atoms";

import { InvoiceTable } from "./NextInvoiceTable";

export const NextInvoice = ({ nextInvoice }) => (
  <>
    <BaseSectionTitle title="Invoice" />

    <BaseCard
      className="w-100"
      body={() => (
        <div className="w-100">
          <p className="fs-6">
            Next invoice date:{" "}
            <b>{formatDate(new Date(nextInvoice.createdAt))}</b>
          </p>
          <InvoiceTable invoiceData={nextInvoice} />
        </div>
      )}
    />
  </>
);
