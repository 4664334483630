import React, { useEffect } from "react";

import { Auth } from "aws-amplify";
import { UiToast } from "@ask-christee/ui-response";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PageLoader } from "../../components/molecules";

export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();

  useEffect(() => {
    const resolveConfirmation = ({ notifyType, message }) => {
      setTimeout(() => {
        UiToast[notifyType](message);
        navigate("/login");
      }, 1600);
    };

    const confirmEmail = async ({ username, code }) => {
      try {
        const res = await Auth.confirmSignUp(username, code);
        if (res) {
          resolveConfirmation({
            notifyType: "success",
            message: "Your email has been verified!",
          });
        }
      } catch (error) {
        resolveConfirmation({
          notifyType: "error",
          message: error.message,
        });
      }
    };

    const code = query.get("code");
    const username = query.get("username");

    if (!code || !username) {
      navigate("/login");
    } else {
      confirmEmail({ username, code });
    }
  }, [navigate, query]);

  return <PageLoader text="Verifying email..." />;
};
