import { useContext, useState } from "react";

import PropTypes from "prop-types";
import { UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../../../api";
import { formatDate } from "../../../utils";
import { ActionModal } from "../ActionModal";
import { SUBSCRIPTIONS } from "../../../config";
import { AccountDataProviderContext } from "../../../contexts";

import { configCancellationMessage } from "./helpers";

export const CancelSubscriptionItemModal = ({
  showModal,
  status,
  closeModal,
  subscriptionId,
  itemNextInvoiceDate,
  subscriptionItemName,
}) => {
  const { updateAccountData, accountData } = useContext(
    AccountDataProviderContext,
  );

  const [isLoading, setIsLoading] = useState(false);

  const updateSubscriptionItems = (updatedItem) => {
    let updatedAccountData =
      updatedItem.status === "canceled"
        ? accountData.subscriptionItems.filter(
            (item) => item.id !== updatedItem.id,
          )
        : accountData.subscriptionItems.map((item) =>
            item.id === updatedItem.id ? updatedItem : item,
          );

    updateAccountData({ subscriptionItems: updatedAccountData });
  };

  const handleSubscriptionCancel = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.delete(
        `${SUBSCRIPTIONS}/${subscriptionId}`,
      );

      if (response) {
        updateSubscriptionItems(response.data);

        closeModal();
        UiToast.success("Subscription item cancellation was initiated");
      }
    } catch (error) {
      const errorMessage = error?.response?.data || error.message;
      UiToast.error(errorMessage || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const isLastActiveSubscriptionItem =
    accountData.subscriptionItems.filter((item) => !item.cancelAt).length === 1;

  const cancellationMessage = configCancellationMessage({
    status,
    subscriptionItemName,
    isLastActiveSubscriptionItem,
    formattedDate: formatDate(itemNextInvoiceDate),
  });

  return (
    <ActionModal
      showModal={showModal}
      isLoading={isLoading}
      actionLabel="Delete"
      closeModal={closeModal}
      onActionClick={handleSubscriptionCancel}
      title={`Delete ${subscriptionItemName}`}
    >
      <p className="m-0 fs-6">{cancellationMessage}</p>
    </ActionModal>
  );
};

CancelSubscriptionItemModal.propTypes = {
  status: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  subscriptionItemName: PropTypes.string.isRequired,
  itemNextInvoiceDate: PropTypes.string.isRequired,
};
