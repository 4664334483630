import React from "react";

import { Popover } from "react-bootstrap";

import "./styles.scss";

export const CustomPopover = React.forwardRef(
  ({ title, children, ...props }, ref) => {
    return (
      <Popover ref={ref} {...props} className="custom-popover">
        <Popover.Header className="custom-popover__title" as="h3">
          {title}
        </Popover.Header>
        <Popover.Body className="custom-popover__body">{children}</Popover.Body>
      </Popover>
    );
  },
);

CustomPopover.displayName = "CustomPopover";
