import React from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import "./styles.scss";

const Hero = ({
  lastName,
  firstName,
  speciality,
  financeType,
  accountType,
  realtorPhoto,
  specialityMultiple,
}) => {
  return (
    <Row className="profile-hero-section">
      <Col md={4} className="text-left">
        <div className="profile-hero-section__photo-wrapper">
          <img
            className="profile-hero-section__photo"
            src={realtorPhoto || "/images/avatarPlaceholder.jpeg"}
            alt="realtorPhoto"
          />
        </div>
      </Col>
      <Col md={8} className="profile-hero-section__info">
        <h1 className="h3 mt-3">
          {firstName} {lastName}
        </h1>
        <p className="profile-hero-section__description-text">
          <span className="profile-hero-section__info-label">
            Recommended:{" "}
          </span>
          {accountType}
        </p>
        {(speciality || specialityMultiple?.length > 0) && (
          <p className="profile-hero-section__description-text">
            <span className="profile-hero-section__info-label">
              Specialties:{" "}
            </span>
            {speciality || specialityMultiple.join(", ")}
          </p>
        )}
        {financeType?.length && (
          <p className="profile-hero-section__description-text">
            <span className="profile-hero-section__info-label">
              Mortgage types:{" "}
            </span>
            {financeType.join(", ")}
          </p>
        )}
      </Col>
    </Row>
  );
};

Hero.propTypes = {
  speciality: PropTypes.string,
  realtorPhoto: PropTypes.string,
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  brokerage: PropTypes.string.isRequired,
  accountType: PropTypes.string.isRequired,
  specialityMultiple: PropTypes.arrayOf(PropTypes.string),
  financeType: PropTypes.arrayOf(PropTypes.string),
};

export default Hero;
