import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";
import { UiToast } from "@ask-christee/ui-response";

import { LANDING_URL } from "../../../config";
import { CurrentUserProviderContext } from "../../../contexts";

import { MobileNav } from "./MobileNav";
import { DesktopNav } from "./DesktopNav";

import christeeLogo from "../../../assets/images/askchristee-logo.svg";

import { defaultMenuItems, menuItemsPropTypes } from "./data";

import "./styles.scss";

export const Header = ({ menuItems = defaultMenuItems }) => {
  const navigate = useNavigate();
  const { currentUser, updateCurrentUser } = useContext(
    CurrentUserProviderContext,
  );

  const [showMobileNav, setShowMobileNav] = useState(false);

  const logOut = async () => {
    try {
      await Auth.signOut();
      updateCurrentUser({ isAuthenticated: false });
      UiToast.info("You are logged out");

      navigate("/login");
    } catch (err) {
      UiToast.error(err.message);
    }
  };

  const userName = `${currentUser.firstName} ${currentUser.lastName}`;

  return (
    <>
      <header className="header" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <div className="row flex-nowrap align-items-center justify-content-between px-2">
            <div className="col-6 col-sm-4 col-lg-2 col-xl-3 header__section">
              <a
                href={LANDING_URL}
                className="custom-logo-link"
                rel="home"
                aria-current="page"
              >
                <img
                  width="225"
                  height="94"
                  src={christeeLogo}
                  className="custom-logo"
                  alt="Christee"
                  style={{ maxWidth: "100%" }}
                />
              </a>
            </div>

            <DesktopNav
              userName={userName}
              setShowMobileNav={setShowMobileNav}
              menuItems={menuItems}
              logOut={logOut}
              currentUser={currentUser}
            />
            <div className="col-4 col-md-6 d-lg-none header__section">
              <div className="tools-wrapper">
                <div className="item menu-button d-inline d-lg-none">
                  <button
                    onClick={() => setShowMobileNav(true)}
                    className="btn hamburger"
                    aria-expanded="false"
                    aria-label="Open Main Menu"
                  >
                    {" "}
                    <span className="bar1"> </span>{" "}
                    <span className="bar2"> </span>{" "}
                    <span className="bar3"> </span>{" "}
                  </button>
                </div>
              </div>
            </div>
            <MobileNav
              logOut={logOut}
              menuItems={menuItems}
              userName={userName}
              currentUser={currentUser}
              setShowMobileNav={setShowMobileNav}
              showMobileNav={showMobileNav}
            />
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  menuItems: menuItemsPropTypes,
};
