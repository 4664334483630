export const ExpandableTableRow = ({ items, columns }) => (
  <>
    <tr className="custom-table__row">
      {columns.map((column, index) => (
        <td
          key={index}
          className="custom-table__row-item custom-table__row-item--expanded custom-table__row-item--expanded-header"
        >
          {column.label}
        </td>
      ))}
    </tr>
    {items.map((item) => (
      <tr key={item.id} className="custom-table__row">
        {columns.map((column, index) => (
          <td
            key={index}
            className="custom-table__row-item custom-table__row-item--expanded"
          >
            {column.format
              ? column.format(item[column.field])
              : item[column.field]}
          </td>
        ))}
      </tr>
    ))}
  </>
);
