import { OverlayTrigger, Tooltip } from "react-bootstrap";

import PropTypes from "prop-types";

import "./styles.scss";

export const CustomTooltip = ({ content, children }) => {
  return (
    <OverlayTrigger
      placement="top"
      trigger={["hover", "focus"]}
      rootClose
      overlay={<Tooltip className="custom-tooltip">{content}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

CustomTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  children: PropTypes.node.isRequired,
};
