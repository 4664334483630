import { Outlet } from "react-router-dom";
import { MobileOverlay } from "@ask-christee/ui-response";

import { LANDING_URL } from "../../config";
import { Footer, Header } from "../../components/organisms";
import { useMediaQuery, useToastOnQuery } from "../../hooks";

import { carouselData, modulesData } from "./data";
import { ModulesCarousel, ModulesGrid } from "./components";

import "./styles.scss";

export const Modules = () => {
  useToastOnQuery();

  return (
    <>
      <Header />
      <MobileOverlay />
      <main id="maincontent" className="bg-ltgray">
        <section className="home-spacing home-intro gradient">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1
                  data-aos="fade-right"
                  data-aos-duration="600"
                  className="aos-init aos-animate"
                >
                  The Smarter Path to Homebuying
                </h1>
              </div>
            </div>
          </div>
        </section>
        {/* Display the module grid */}
        <ModulesGrid modulesData={modulesData} />

        <div className="help-section bg-white py-5">
          <div
            className={useMediaQuery("(min-width: 992px)") ? "container" : ""}
          >
            <h2 className="help-section__title">
              Need Help Getting Started with Christee?
            </h2>
            <ModulesCarousel data={carouselData} />
          </div>
        </div>
        <section className="home-spacing bg-white">
          <div className="container pt-2 pb-2">
            <div className="home-help row align-items-center justify-content-center">
              <div className="item col-12 col-md-3 col-lg-3 order-2 order-md-1">
                <p>
                  <a
                    href={`${LANDING_URL}/resources`}
                    className="btn btn-ltblue"
                  >
                    Resources
                  </a>
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-7 order-1 order-md-2">
                <p>
                  Detailed help is available for all the modules. Everything you
                  need to know to get started with Christee modules.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/*For modal windows*/}
      <Outlet />

      <Footer />
    </>
  );
};
