import React from "react";

import PropTypes from "prop-types";

import { SectionWrapper } from "./SectionWrapper";
import {
  CustomHookFormInput,
  VerifyState,
} from "../../../components/molecules";

import suitcaseIcon from "../../../assets/images/suitcase-icon.svg";

export const WorkDetailsOfficer = ({
  setIsStateValid,
  trigger,
  isPremium,
  errors,
  formValues,
  control,
}) => {
  return (
    <SectionWrapper icon={suitcaseIcon} text="Work Details">
      <CustomHookFormInput
        label="NMLS Number"
        name="nmls"
        errors={errors}
        control={control}
        rules={{
          required: "Required",
          pattern: {
            value: /^\d{6,10}$/,
            message: "NMLS number must be between 6 and 10 digits",
          },
        }}
      />

      {isPremium && (
        <VerifyState
          setIsStateValid={setIsStateValid}
          label="Primary State"
          placeholder="Enter state"
          trigger={trigger}
          rules={{
            required: "Required",
          }}
          errors={errors}
          control={control}
          inputValue={formValues.state}
        />
      )}
    </SectionWrapper>
  );
};

WorkDetailsOfficer.propTypes = {
  setIsStateValid: PropTypes.func.isRequired,
  trigger: PropTypes.func.isRequired,
  isPremium: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
};
