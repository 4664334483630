import { useContext } from "react";

import PropTypes from "prop-types";

import { BaseSectionTitle } from "../../atoms";
import { subscriptionsInfo } from "../../../shared-data";
import { SubscriptionPricesProviderContext } from "../../../contexts";

import { getSubscriptionChange } from "./helpers";
import { SubscriptionActive } from "./SubscriptionActive";
import { SubscriptionToUpgrade } from "./SubscriptionToUpgrade";
import { SubscriptionToDowngrade } from "./SubscriptionToDowngrade";

import "./styles.scss";

const itemsConfig = {
  upgrade: SubscriptionToUpgrade,
  downgrade: SubscriptionToDowngrade,
  active: SubscriptionActive,
};

export const UserAvailablePlans = ({
  accountType,
  subscriptionType,
  subscriptionStatus,
}) => {
  const { prices, getPriceByType } = useContext(
    SubscriptionPricesProviderContext,
  );
  const subscriptionsByUserType = subscriptionsInfo[accountType].content;

  const currentPriceData = getPriceByType({ subscriptionType });

  return (
    <>
      <BaseSectionTitle title="All plans" />
      {!!prices.length && (
        <div className="subscriptions-wrapper">
          {subscriptionsByUserType.map((subscription) => {
            const priceData = getPriceByType({
              subscriptionType: subscription.type,
            });

            const SubscriptionComponent = getSubscriptionChange({
              itemsConfig,
              currentPrice: currentPriceData?.amount,
              providedPrice: priceData?.amount,
            });

            return (
              <div key={subscription.type} className="m-3">
                <SubscriptionComponent
                  subscriptionStatus={subscriptionStatus}
                  {...subscription}
                  price={priceData.amount}
                  features={priceData.features}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

UserAvailablePlans.propTypes = {
  accountType: PropTypes.string.isRequired,
  subscriptionType: PropTypes.string.isRequired,
  subscriptionStatus: PropTypes.string.isRequired,
};
