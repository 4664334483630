import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { UiToast } from "@ask-christee/ui-response";
import { Button, Form } from "react-bootstrap";

import { useCustomReCaptcha } from "../../hooks";
import { CustomInput } from "../../components/molecules";

const EnterEmail = ({ setForgotEmail }) => {
  const { verifyRecaptcha } = useCustomReCaptcha("enter_email");

  const [email, setEmail] = useState("");

  useEffect(() => {
    return () => {
      setEmail("");
    };
  }, []);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email) {
      try {
        const captcha = await verifyRecaptcha();
        const deliveryDetails = await Auth.forgotPassword(email, { captcha });

        if (deliveryDetails) {
          UiToast.success(
            "A verification code has been sent at your registered email address",
          );
          setForgotEmail(email);
        }
      } catch (err) {
        UiToast.error(
          /5\d{2}/.test(err.response.status) || !err.message
            ? "Something went wrong!"
            : err.message,
        );
      }
    } else {
      UiToast.error("Please Enter an Email address!");
    }
  };

  return (
    <>
      <h3 className="h3">Enter your email to reset your password</h3>
      <Form
        className="w-100 mt-5 d-flex justify-content-center align-items-end"
        onSubmit={handleSubmit}
      >
        <CustomInput
          groupClassName="w-100"
          name="email"
          label="Email"
          type="email"
          value={email}
          onChange={handleChange}
        />
        <Button
          data-cy="btn-submit"
          variant="primary"
          type="submit"
          className="mx-3 px-4"
          style={{ padding: "11px 0" }}
        >
          Submit
        </Button>
      </Form>
    </>
  );
};

export default EnterEmail;
