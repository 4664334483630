import React from "react";

import { OverlayTrigger } from "react-bootstrap";

import { CustomPopover } from "../../../molecules";

import warningIcon from "../../../../assets/images/warning-icon.svg";
import defaultPerson from "../../../../assets/images/default-person.png";
import arrowBottom from "../../../../assets/images/arrow-bottom-blue-icon.svg";

export const UsernameToggle = React.forwardRef(
  ({ imgUrl, showCompleteProfileIcon, onClick, children }, ref) => (
    <div ref={ref} className="header-dropdown__toggle">
      {showCompleteProfileIcon ? (
        <OverlayTrigger
          placement="bottom-start"
          trigger="click"
          rootClose={true}
          overlay={
            <CustomPopover title="Complete your profile">
              Complete your profile to be recommended for potential Homebuyers
            </CustomPopover>
          }
        >
          <div className="header-dropdown__user-image-wrapper">
            <img
              className="header__user-image"
              src={imgUrl || defaultPerson}
              alt="user photo"
            />
            <img
              className="header-dropdown__warning-icon"
              src={warningIcon}
              alt="warning icon"
            />
          </div>
        </OverlayTrigger>
      ) : (
        <img
          className="header__user-image"
          src={imgUrl || defaultPerson}
          alt="user photo"
        />
      )}
      <div
        className="header-dropdown__user-name"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
      <img
        className="header-dropdown__arrow"
        src={arrowBottom}
        alt="arrow bottom"
      />
    </div>
  ),
);

UsernameToggle.displayName = "UsernameToggle";
