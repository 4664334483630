import React, { useContext } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { CurrentUserProviderContext } from "../../../contexts";

import "./style.scss";
import { modalText } from "./data";
import { BaseModal } from "../../atoms";

export const FillProfileMessageModal = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(CurrentUserProviderContext);

  let textToDisplay;

  if (
    ["loan_officer_state", "agent_zip_code"].includes(
      currentUser?.subscriptionType,
    )
  ) {
    textToDisplay = modalText.premium;
  } else if (
    ["agent_basic", "loan_officer_basic"].includes(
      currentUser?.subscriptionType,
    )
  ) {
    textToDisplay = modalText.basic;
  }

  const handleHideModal = () => {
    navigate("/modules");
  };

  if (
    !textToDisplay ||
    (currentUser?.isCompletedProfile && currentUser?.subscriptionType)
  )
    return <Navigate to="/modules" />;

  return (
    <BaseModal
      show={true}
      size="md"
      handleClose={handleHideModal}
      title="Complete Your Profile"
    >
      <div className="d-flex flex-column">
        <p style={{ fontSize: "20px" }}>{textToDisplay}</p>
        <Button
          className="align-self-end mt-3"
          variant="primary"
          onClick={() => navigate("/account/profile")}
        >
          Go to profile
        </Button>
      </div>
    </BaseModal>
  );
};
