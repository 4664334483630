import { useState } from "react";

import PropTypes from "prop-types";

import { BaseCard } from "../BaseCard";
import { Discount } from "../Discount";
import { formatDate } from "../../../utils";

import { displayStatusConfig } from "./helpers";
import { CancelSubscriptionItemModal } from "./CancelSubscriptionItemModal";

import BinIcon from "../../../assets/images/bin-icon.svg";
import CalendarIcon from "../../../assets/images/calendar-icon.svg";

import "./styles.scss";

export const SubscriptionItemCard = ({
  value,
  price,
  status,
  cancelAt,
  discount,
  amountPaid,
  subscriptionId,
  descriptionItems,
  itemNextInvoiceDate,
  subscriptionItemName,
}) => {
  const [showModal, setShowModal] = useState(false);

  const {
    date,
    allowDelete,
    text: statusText,
  } = displayStatusConfig({
    status,
    cancelAt,
    itemNextInvoiceDate,
  });

  return (
    <>
      <BaseCard
        className="subscription-item-card"
        body={() => (
          <div className="subscription-item">
            <div className="subscription-item__header">
              <div>
                <span className="subscription-item__name">
                  {subscriptionItemName}:
                </span>
                <span className="subscription-item__value">&nbsp;{value}</span>
              </div>
              {allowDelete && (
                <div
                  onClick={() => setShowModal(true)}
                  className="subscription-item__cancel"
                >
                  <img width="24" src={BinIcon} alt="bin icon" />
                </div>
              )}
            </div>
            <div className="subscription-item__body">
              {descriptionItems?.map((descriptionItem) => (
                <p
                  key={descriptionItem.title}
                  className="subscription-item__body-item"
                >
                  <span className="subscription-item__body-item-title">
                    {descriptionItem.title}
                  </span>
                  :
                  <span className="subscription-item__body-item-value">
                    &nbsp;{descriptionItem.value}
                  </span>
                </p>
              ))}
            </div>
            <div className="subscription-item__footer">
              <div className="subscription-item__price">
                <span className="subscription-item__price-dollar-sign">$</span>
                <span className="subscription-item__price-title">Price:</span>
                <span className="subscription-item__price-amount">
                  &nbsp;${amountPaid}/mo
                </span>
                {discount && (
                  <Discount
                    price={price}
                    discount={discount}
                    className="ms-2"
                  />
                )}
              </div>
              <div className="subscription-item__next-invoice">
                <img width="24" src={CalendarIcon} alt="calendar icon" />
                <p className="subscription-item__next-invoice-text ">
                  <span
                    className={`subscription-item__next-invoice-title ${
                      cancelAt || status === "past_due" ? "text-danger" : ""
                    }`}
                  >
                    {statusText}
                  </span>
                  {date && (
                    <span className="subscription-item__next-invoice-date">
                      &nbsp;{formatDate(date)}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
      />
      <CancelSubscriptionItemModal
        showModal={showModal}
        status={status}
        closeModal={() => setShowModal(false)}
        subscriptionId={subscriptionId}
        itemNextInvoiceDate={itemNextInvoiceDate}
        subscriptionItemName={subscriptionItemName}
      />
    </>
  );
};

SubscriptionItemCard.propTypes = {
  subscriptionItemName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  cancelAt: PropTypes.string,
  status: PropTypes.string.isRequired,
  discount: PropTypes.shape({
    percentOff: PropTypes.number,
  }),
  amountPaid: PropTypes.number.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  descriptionItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  itemNextInvoiceDate: PropTypes.string.isRequired,
};
