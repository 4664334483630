import React from "react";

import PropTypes from "prop-types";

import { ButtonWithSpinner } from "@ask-christee/ui-response";

import { BaseCard } from "../BaseCard";

import "./styles.scss";

export const ButtonCard = ({
  title,
  topLabel,
  isLoading,
  buttonDisabled,
  buttonText = "submit",
  description: DescriptionToRender,
  buttonVariant = "primary",
  onButtonClick,
}) => {
  return (
    <BaseCard
      title={title}
      topLabel={topLabel}
      body={() => (
        <div className="custom-card__body-wrapper">
          <div className="custom-card__description">
            {typeof DescriptionToRender === "string" ? (
              DescriptionToRender
            ) : (
              <DescriptionToRender />
            )}
          </div>
          <ButtonWithSpinner
            spinnerVariant="light"
            loading={isLoading}
            disabled={buttonDisabled}
            onClick={onButtonClick}
            type="submit"
            variant={buttonVariant}
            className="mt-4 px-4 w-100"
            loadingIndicator={`${buttonText}...`}
          >
            {buttonText}
          </ButtonWithSpinner>
        </div>
      )}
    />
  );
};

ButtonCard.propTypes = {
  isLoading: PropTypes.bool,
  onButtonClick: PropTypes.func,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
  buttonText: PropTypes.string,
  title: PropTypes.string.isRequired,
  topLabel: PropTypes.elementType,
  buttonVariant: PropTypes.string,
  buttonDisabled: PropTypes.bool,
};
