import { useContext, useState } from "react";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../../api";
import { RENEW_CHECKOUT_SESSION } from "../../config";
import { subscriptionsInfo } from "../../shared-data";
import { PageLoader, SubscriptionCard } from "../../components/molecules";
import { SubscriptionPricesProviderContext } from "../../contexts";
import { SignupSelectFlowWrapper } from "../../components/organisms";

import "./styles.scss";

export const SelectSubscriptionType = ({ accountType, isRenew }) => {
  const navigate = useNavigate();
  const {
    prices,
    getPriceByType,
    isLoading: isPricesLoading,
  } = useContext(SubscriptionPricesProviderContext);

  const [isLoadingSubscription, setIsLoadingSubscription] = useState(null);

  const createRenewCheckoutSession = async (subscriptionType) => {
    try {
      setIsLoadingSubscription(subscriptionType);

      const response = await axiosInstance.post(RENEW_CHECKOUT_SESSION, {
        subscriptionType,
      });

      if (response) {
        window.location.href = response.data.sessionUrl;
      }
    } catch (error) {
      UiToast.error(error?.response?.data || "Something went wrong");
    } finally {
      setIsLoadingSubscription(null);
    }
  };

  const onButtonClick = async ({ link, type, renewLink }) => {
    if (isRenew && renewLink) {
      navigate(renewLink);
    } else if (isRenew) {
      await createRenewCheckoutSession(type);
    } else {
      navigate(link);
    }
  };

  if (isPricesLoading) return <PageLoader />;

  return (
    <SignupSelectFlowWrapper
      title="Select Subscription Type"
      additionalLink={subscriptionsInfo[accountType].learnMoreLink}
    >
      {!!prices.length && (
        <div className="subscription-cards">
          {subscriptionsInfo[accountType].content.map((subscription) => {
            const priceData = getPriceByType({
              subscriptionType: subscription.type,
            });

            return (
              <div key={subscription.type} className="subscription-card">
                <SubscriptionCard
                  {...subscription}
                  price={priceData.amount}
                  features={priceData.features}
                  onButtonClick={() => onButtonClick(subscription)}
                  isLoading={isLoadingSubscription === subscription.type}
                />
              </div>
            );
          })}
        </div>
      )}
    </SignupSelectFlowWrapper>
  );
};

SelectSubscriptionType.props = {
  accountType: PropTypes.oneOf(["agent", "loan officer", "homebuyer"])
    .isRequired,
  isRenew: PropTypes.bool,
};
