import React, { useContext, useEffect, useMemo, useState } from "react";

import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import {
  CustomHookFormInput,
  PhoneHookFormInput,
  ZipCodeLocationInput,
} from "../../molecules";
import { CURRENT_USER } from "../../../config";
import { axiosInstance } from "../../../api";
import { BaseSectionTitle, CustomTooltip } from "../../atoms";
import { CurrentUserProviderContext } from "../../../contexts";

import infoIcon from "../../../assets/images/info-icon.svg";

import "./styles.scss";

export const HomebuyerProfile = () => {
  const { currentUser, updateCurrentUser } = useContext(
    CurrentUserProviderContext,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isZipCodeValid, setIsZipCodeValid] = useState(false);

  const {
    handleSubmit,
    trigger,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const zipCodeValue = watch(["zipCode"])?.[0];

  const onSubmit = async (data) => {
    if (data.zipCode !== currentUser.zipCode && !isZipCodeValid) {
      return UiToast.error("Please verify your zip code");
    }

    try {
      setIsLoading(true);
      const homebuyerData = await axiosInstance.patch(CURRENT_USER, data);

      if (homebuyerData) {
        updateCurrentUser({
          ...currentUser,
          ...homebuyerData.data,
        });
        UiToast.success("Homebuyer data successfully saved!");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data ||
        error.message ||
        "An unexpected error has occurred";

      UiToast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    reset(currentUser);
  }, [currentUser, reset]);

  const providedLocation = useMemo(
    () => ({
      area: currentUser.area,
      state: currentUser.state,
    }),
    [currentUser.area, currentUser.state],
  );

  return (
    <div className="w-100">
      <Form
        className="w-100 d-flex flex-column align-items-start"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-100" style={{ maxWidth: "380px" }}>
          <BaseSectionTitle titleClassName="m-0" title="Personal Information" />
          <CustomHookFormInput
            label="First Name"
            rules={{ required: "Required" }}
            name="firstName"
            control={control}
            errors={errors}
          />
          <CustomHookFormInput
            label="Last Name"
            rules={{ required: "Required" }}
            name="lastName"
            control={control}
            errors={errors}
          />
          <CustomHookFormInput
            label="Email Address"
            rules={{ required: "Required" }}
            name="email"
            disabled={true}
            control={control}
            errors={errors}
          />
          <PhoneHookFormInput
            label="Cell phone"
            rules={{
              required: "Required",
            }}
            name="primaryPhone"
            control={control}
            errors={errors}
          />

          <Form.Group className="mt-3">
            <div className="d-flex align-items-center">
              Are you 62 years or older?
              <CustomTooltip content="If you are 62 years or older AskChristee Reverse Mortgage module will show you how to purchase a home without a required monthly payment">
                <img
                  className="ms-1"
                  width="18"
                  src={infoIcon}
                  alt="info icon"
                />
              </CustomTooltip>
            </div>
            <Controller
              name="isReverseMortgage"
              control={control}
              render={({ field }) => (
                <div className="mt-2">
                  <Form.Check
                    inline
                    onChange={() => field.onChange(true)}
                    checked={field.value === true}
                    label="Yes"
                    type="radio"
                    id="isReverseMortgageYes"
                  />
                  <Form.Check
                    inline
                    onChange={() => field.onChange(false)}
                    checked={field.value === false}
                    label="No"
                    type="radio"
                    id="isReverseMortgageNo"
                  />
                </div>
              )}
            />
          </Form.Group>

          <BaseSectionTitle title="Location" />

          <ZipCodeLocationInput
            rules={{
              required: "Required",
            }}
            showInfoMessages={false}
            setFormValue={setValue}
            label="Zip Code"
            name="zipCode"
            errors={errors}
            trigger={trigger}
            control={control}
            inputValue={zipCodeValue}
            providedLocation={providedLocation}
            setIsZipCodeValid={setIsZipCodeValid}
          />
        </div>

        <ButtonWithSpinner
          spinnerVariant="light"
          data-cy="btn-submit"
          variant="primary"
          type="submit"
          className="mt-4 px-4"
          loading={isLoading}
          loadingIndicator="Saving..."
        >
          Save
        </ButtonWithSpinner>
      </Form>
    </div>
  );
};
