import states from "../../../common/States/StatesAll";

export const transformResponseAgentData = (agentData) => {
  let formData;

  if (agentData?.isCompletedProfile) {
    formData = {
      ...agentData,
      financeType: agentData?.financeType?.map((type) => ({
        value: type,
        label: type,
      })),
      specialityMultiple: agentData?.specialityMultiple?.map((type) => ({
        value: type,
        label: type,
      })),
      states: agentData.states.length
        ? agentData.states.map((state) => ({
            ...state,
            areas: state.areas?.map((area) => ({
              value: area.area,
              label: area.area,
            })),
          }))
        : [{}],
      commissionRates: true,
    };
  } else {
    formData = {
      firstName: agentData?.firstName,
      lastName: agentData?.lastName,
      email: agentData?.email,
      primaryPhone: agentData?.primaryPhone || "",
      states: agentData?.states || [{}],
      commissionRates: true,
      mlsSearch: true,
    };
  }

  return formData;
};

export const transformRequestAgentData = ({ agentData, imageLink }) => ({
  ...agentData,
  realtorPhoto: imageLink,
  commissionRate: "1.55",
  states: agentData.states?.map((state) => ({
    ...state,
    areas: state?.areas?.map((area) => ({
      area: area.value,
      state: state.state,
    })),
  })),
  specialityMultiple: agentData.specialityMultiple?.map((type) => type.value),
});

export const transformResponseOfficerData = (officerData) => {
  let formData;

  if (officerData?.isCompletedProfile) {
    formData = {
      ...officerData,
      financeType: officerData.financeType?.map((type) => ({
        value: type,
        label: type,
      })),
      additionalMortgages: officerData.additionalMortgages?.map((mortgage) => ({
        value: mortgage,
        label: mortgage,
      })),
      licenseStates: officerData.licenseStates?.map((stateCode) => ({
        value: stateCode,
        label: states.find((stateItem) => stateItem.code === stateCode)?.text,
      })),
      commissionRates: true,
    };
  } else {
    formData = {
      firstName: officerData?.firstName,
      lastName: officerData?.lastName,
      email: officerData?.email,
      primaryPhone: officerData?.primaryPhone || "",
      speciality: "mortgage broker",
      nmls: officerData?.nmls,
      mlsSearch: true,
    };
  }

  return formData;
};

export const transformRequestOfficerData = ({ officerData, imageLink }) => ({
  ...officerData,
  realtorPhoto: imageLink,
  financeType: officerData.financeType?.map((type) => type.value),
  additionalMortgages: officerData.additionalMortgages?.map(
    (type) => type.value,
  ),
  licenseStates: officerData.licenseStates?.map((type) => type.value),
});
