import { useEffect, useState } from "react";

import { UiToast } from "@ask-christee/ui-response";

import { ADMIN_USERS } from "../../../config";
import { useDebounce } from "../../../hooks";
import { axiosInstance } from "../../../api";
import { CustomPagination } from "../../molecules";
import { snakifyKeys, snakifyString } from "../../../utils";

import { UsersTable } from "./UsersTable";
import { TableControls } from "./TableControls";

import "./styles.scss";

const itemsPerPage = 10;

export const CustomUsersTable = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sortField, setSortField] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);

  const debouncedSearch = useDebounce({
    value: search,
    delay: 600,
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const formattedSortField = snakifyString(sortField);

      const params = {
        sort:
          sortOrder === "asc" ? formattedSortField : `-${formattedSortField}`,
        page: currentPage,
        limit: itemsPerPage,
        search: debouncedSearch,
        ...snakifyKeys(filter),
      };
      const response = await axiosInstance.get(ADMIN_USERS, { params });
      setData(response.data.docs);
      setTotalItems(response.data.totalDocs);
    } catch (error) {
      UiToast.error(error?.response?.data || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, debouncedSearch, sortField, sortOrder, currentPage]);

  const handlePageChange = (pageNumber) => {
    if (!isLoading) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <>
      <TableControls
        filter={filter}
        search={search}
        setFilter={setFilter}
        setSearch={setSearch}
        setCurrentPage={setCurrentPage}
      />

      <UsersTable
        data={data}
        fetchData={fetchData}
        isLoading={isLoading}
        sortField={sortField}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        setSortField={setSortField}
      />

      {!!data?.length && (
        <div className="w-100 d-flex justify-content-end">
          <CustomPagination
            paginate={handlePageChange}
            totalCount={totalItems}
            currentPage={currentPage}
            pageSize={itemsPerPage}
          />
        </div>
      )}
    </>
  );
};
