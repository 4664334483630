import React, { useState } from "react";

import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UiToast } from "@ask-christee/ui-response";

import { useCustomReCaptcha } from "../../hooks";
import { configureCognitoParams } from "../../utils";
import { AuthCover } from "../../components/organisms";

import {
  Agreements,
  Buttons,
  Password,
  PersonalInformation,
  WorkDetailsAgent,
} from "./sections";
import { signupContentConfig } from "./config";

import "./index.scss";

export const AgentSignup = ({ isPremium = false }) => {
  const { verifyRecaptcha } = useCustomReCaptcha("signup_agent");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isZipCodeValid, setIsZipCodeValid] = useState(false);

  const subscriptionType = isPremium ? "agent_zip_code" : "agent_basic";

  const contentState = signupContentConfig[subscriptionType];

  const {
    control,
    trigger,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  const onSubmit = async (submitData) => {
    if (isPremium && !isZipCodeValid) {
      return UiToast.error("Please validate your zip code");
    }

    try {
      setIsLoading(true);

      const captcha = await verifyRecaptcha();

      const params = configureCognitoParams({
        isPremium,
        submitData,
        accountType: "agent",
        captcha: captcha,
      });

      await Auth.signUp(params);

      UiToast.success("Success Sign up! Please confirm your email");
      setIsLoading(false);

      navigate("/onboarding");
    } catch (error) {
      // get rid of prefix that comes from cognito
      const errorMessage = error.message.replace(
        "PreSignUp failed with error ",
        "",
      );

      setIsLoading(false);
      UiToast.error(errorMessage);
    }
  };

  return (
    <AuthCover title={contentState.title}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation control={control} errors={errors} />

        <WorkDetailsAgent
          errors={errors}
          trigger={trigger}
          control={control}
          isPremium={isPremium}
          formValues={formValues}
          setIsZipCodeValid={setIsZipCodeValid}
        />

        <Password errors={errors} control={control} />

        <Agreements errors={errors} control={control} />

        <Buttons isLoading={isLoading} navigate={navigate} />
      </Form>
    </AuthCover>
  );
};

AgentSignup.propTypes = {
  isPremium: PropTypes.bool,
};
