import React, { useState } from "react";

import { AuthCover } from "../../components/organisms";

import EnterCode from "./EnterCode";
import EnterEmail from "./EnterEmail";

function ResetPassword() {
  const [forgotEmail, setForgotEmail] = useState(null);

  return (
    <AuthCover isCentered>
      {forgotEmail ? (
        <EnterCode forgotEmail={forgotEmail} />
      ) : (
        <EnterEmail setForgotEmail={setForgotEmail} />
      )}
    </AuthCover>
  );
}

export default ResetPassword;
