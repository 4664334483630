import { columnConfigs } from "./data";

export const getConfigForInvoiceType = (invoiceType) =>
  columnConfigs[invoiceType] || columnConfigs.default;

const addDiscountToRecords = ({ records, discount, config }) => {
  const [firstColumn, secondColumn] = config.columns;

  const { name, percentOff, totalDiscountAmounts } = discount;

  const discountLabel = `Discount (${name})${
    percentOff ? ` -${percentOff}%` : ""
  }`;
  const discountAmount = `-${totalDiscountAmounts}`;

  const discountRow = {
    [firstColumn.field]: discountLabel,
    [secondColumn.field]: discountAmount,
  };

  return [...records, discountRow];
};

export const prepareRecords = ({ invoiceData, config }) => {
  const recordsToRender = invoiceData[config.field] || [invoiceData];

  return invoiceData.discount
    ? addDiscountToRecords({
        records: recordsToRender,
        discount: invoiceData.discount,
        config,
      })
    : recordsToRender;
};
