import React, { useContext, useEffect, useState } from "react";

import { Form } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { CURRENT_USER } from "../../../config";
import { axiosInstance } from "../../../api";
import { useImageDirectUpload } from "../../../hooks";
import { CurrentUserProviderContext } from "../../../contexts";

import {
  AgentLicenseInfoSection,
  PersonalInfoSection,
  WorkPlaceInfoSection,
} from "./profileSections";
import {
  transformRequestAgentData,
  transformResponseAgentData,
} from "./helpers";

import "./styles.scss";

export const AgentProfile = () => {
  const { imageUpload } = useImageDirectUpload();

  const { currentUser, updateCurrentUser } = useContext(
    CurrentUserProviderContext,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prevLicenseStates, setPrevLicenseStates] = useState([{}]);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "states",
  });

  const [realtorPhoto] = watch(["realtorPhoto"]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const imageLink =
        typeof data.realtorPhoto === "object"
          ? await imageUpload({ image: data.realtorPhoto })
          : data.realtorPhoto || "";

      const agentData = await axiosInstance.patch(
        CURRENT_USER,
        transformRequestAgentData({
          agentData: data,
          imageLink,
        }),
      );

      if (agentData) {
        updateCurrentUser({
          ...currentUser,
          ...agentData.data,
        });
        UiToast.success("Agent data successfully saved!");
        setIsSubmitting(false);
      }
    } catch (error) {
      const errorMessage =
        error.response?.data ||
        error.message ||
        "An unexpected error has occurred";

      UiToast.error(errorMessage);

      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const formData = transformResponseAgentData(currentUser);

    reset(formData);

    if (formData.states) {
      replace(formData.states);
      setPrevLicenseStates(formData.states);
    }
  }, [currentUser, replace, reset]);

  return (
    <div className="w-100">
      <Form
        className="w-100 d-flex flex-column align-items-start"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-100" style={{ maxWidth: "380px" }}>
          <PersonalInfoSection
            realtorPhoto={realtorPhoto}
            control={control}
            errors={errors}
            currentUser={currentUser}
          />

          <WorkPlaceInfoSection
            errors={errors}
            control={control}
            currentUser={currentUser}
          />

          <AgentLicenseInfoSection
            fields={fields}
            errors={errors}
            append={append}
            remove={remove}
            control={control}
            setValue={setValue}
            updatePrevLicenseStates={setPrevLicenseStates}
            prevLicenseStates={prevLicenseStates}
          />
        </div>

        <ButtonWithSpinner
          spinnerVariant="light"
          data-cy="btn-submit"
          variant="primary"
          type="submit"
          className="mt-4 px-4"
          loading={isSubmitting}
          loadingIndicator="Saving..."
        >
          Save
        </ButtonWithSpinner>
      </Form>
    </div>
  );
};
