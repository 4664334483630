import React, { useContext, useState } from "react";

import PropTypes from "prop-types";
import { UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../../../api";
import { formatDate } from "../../../utils";
import { ActionModal } from "../../molecules";
import { SUBSCRIPTIONS } from "../../../config";
import {
  AccountDataProviderContext,
  CurrentUserProviderContext,
} from "../../../contexts";

export const CancelSubscriptionModal = ({ showModal, closeModal }) => {
  const { loadCurrentUser } = useContext(CurrentUserProviderContext);
  const { setIsDataFetched, accountData } = useContext(
    AccountDataProviderContext,
  );

  const [isLoading, setIsLoading] = useState(false);

  const onSubscriptionCancel = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.delete(SUBSCRIPTIONS);

      if (response) {
        setIsDataFetched((prevState) => ({
          ...prevState,
          nextInvoiceFetched: false,
          subscriptionItemsFetched: false,
        }));

        await loadCurrentUser();

        UiToast.success("Your subscription has been cancelled.");
      }
    } catch (error) {
      const errorMessage = error?.response?.data || error.message;
      UiToast.error(errorMessage || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ActionModal
      showModal={showModal}
      closeModal={closeModal}
      isLoading={isLoading}
      actionLabel="Cancel"
      title="Cancel Subscription"
      onActionClick={onSubscriptionCancel}
    >
      <p className="cancel-modal__text">
        {accountData?.subscriptionLatestEndDate
          ? `By cancel now, you can still use your subscription until
        ${formatDate(accountData.subscriptionLatestEndDate)}.`
          : "By cancelling now, the subscription will be cancelled immediately."}
      </p>
    </ActionModal>
  );
};

CancelSubscriptionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
