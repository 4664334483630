import buyWait from "../../../assets/images/buyWait.svg";
import buyRent from "../../../assets/images/buyRent.svg";
import buyerChoice from "../../../assets/images/buyerChoice.svg";
import buyerPrequal from "../../../assets/images/buyerPrequal.svg";
import reverseManager from "../../../assets/images/reverseManager.svg";
import investmentAnalysis from "../../../assets/images/investmentAnalysis.svg";
import QuickBuyerChoice from "../../../assets/images/quick-buyer-choice.svg";
import QuickPrequal from "../../../assets/images/quick-prequal.svg";
import sellerNet from "../../../assets/images/sellerNet.svg";
import vacation from "../../../assets/images/vacation.svg";
import budget from "../../../assets/images/budget.svg";

export const modulesData = [
  {
    title: "Quick PreQual",
    description:
      "Explore an affordable home price based upon desired mortgage payment. Report includes total payment with any mortgage insurance, closing cost, downpayment, and mortgage deductions.  Easy to use. Most suitable for homebuyers and agents.",
    route: "/buyer-prequal/quick",
    image: QuickPrequal,
  },
  {
    title: "Quick Buyer Choice",
    description:
      "Explore purchase options for a specific home based upon loan type and desired mortgage payment. Report includes total payment with any mortgage insurance, closing cost, downpayment, and mortgage deduction. Easy to use. Most suitable for homebuyers and agents.",
    route: "/buyer-choice/quick",
    image: QuickBuyerChoice,
  },
  {
    title: "Buyer Prequal",
    description:
      "Explore an affordable price home based upon income, debts, credit score, and desired location. Professional results include multiple loan options, total monthly payment, closing cost and downpayment requirements, qualifying ratios, tax savings, and much more. Optional entries available to customize report.  Most suitable for loan officers and homebuyers.",
    route: "/buyer-prequal",
    image: buyerPrequal,
  },
  {
    title: "Buyer Choice",
    description:
      "Determine your best finance option for a specific property based upon income, debts, and credit score. Customize the report with entries such as desired payment, available cash, or closing credits. Professional results include total monthly payment, downpayment, closing cost and potential tax savings. Most suitable for loan officers and homebuyers.",
    route: "/buyer-choice",
    image: buyerChoice,
  },
  {
    title: "Closing Cost",
    description:
      "See detailed closing cost estimate based upon location, purchase price, close date, and loan type. Optional entries allow for complete customization of report. Report includes loan fees, governmental charges, Prepaids items, Escrows, and Settlement fees. Suitable for Agents, loan officers, and homebuyers.",
    route: "/closing-cost",
    image: sellerNet,
  },
  {
    title: "Buy or Rent",
    description:
      "Review complete objective criteria for buying or renting a home. Report includes payment comparisons, downpayment and closing cost, tax deduction, and tax savings plus potential equity. Suitable for Agents, loan officers, and homebuyers.",
    route: "/buy-or-rent",
    image: buyRent,
  },
  {
    title: "Buy or Wait",
    description:
      "Complete analytics provided to assist deciding right time to purchase a home. Report based entirely upon user entries such as projected future interest rates or property appreciation rate. Report includes comparative mortgage payments, cash requirements, tax implications, and potential equity. Suitable for Agents, loan officer and homebuyers.",
    route: "/buy-or-wait",
    image: buyWait,
  },
  {
    title: "Reverse Mortgage",
    description:
      "Also known as the Home Equity Conversion Mortgage. For many seniors, 62 years or older, a reverse mortgage is a viable option. This program provides the concise data necessary to allow the senior to make an intelligent decision knowing all the available options.",
    route: "/reverse",
    image: reverseManager,
  },
  {
    title: "Budget",
    description:
      "What is an affordable monthly mortgage payment? The amount a Lender establishes? Maybe. Realistically, an affordable mortgage payment is one that fits within your monthly budget. Monthly Budget allows an overview of current monthly obligations including Federal and State Income Taxes and the effects and of the proposed mortgage payment.",
    route: "/budget",
    image: budget,
  },
  {
    title: "Vacation",
    description:
      "Explore the details of purchasing an affordable vacation home or view detailed financial option to purchase a specific vacation property. Professional report includes total payment, cash requirements, tax implications, and potential property tax adjustments. Optional entries available to customize report. Most suitable for loan officers and homebuyers.",
    route: "/vacation",
    image: vacation,
  },
  {
    title: "Seller Net",
    description:
      "Estimate the cash proceeds from sale based upon sales price and location. Net proceeds based upon normal expenses plus optional entries to customize results based upon terms of sale. Suitable for Agents, loan officer and homebuyers.",
    route: "/seller-net",
    image: sellerNet,
  },
  {
    title: "Investment Analysis",
    description:
      "Complete analytics to evaluate the purchase of income producing properties. Analytics include required Cash, Mortgage details, Debt Service, Debt Service Ratio, NOI, Cash flow, Tax deduction, Tax Savings, Future Values, Potential Equity and Depreciation schedule. Most suitable for loan officer and investors.",
    route: "/investment",
    image: investmentAnalysis,
  },
  {
    title: "Home Wealth",
    description:
      "Explore your potential wealth home ownership. Report includes year by year details of potential property equity-based loan reduction and future property values. Very easy to use and suitable for anyone.",
    route: "/equity",
    image: investmentAnalysis,
  },
  {
    title: "Amortization",
    description:
      "The program performs the basic amortization of any loan based upon interest rate and term of loan. Additionally, you may enter additional principal payments or enter a term which you would like the loan to be fully amortized. The results detail total interest paid over the mortgage term, annual allocation of payments as a percentage to principal and interest.",
    route: "/amortization",
    image: buyerPrequal,
  },
  {
    title: "Payment Subsidy",
    description:
      "Explore the possibility of either a 1-, 2-, or 3-year payment subsidy to reduce mortgage payment. Report includes payment reduction and cost of selected subsidy period. Most suitable for loan officers.",
    route: "/subsidy",
    image: buyerChoice,
  },
  {
    title: "Consumer Debt",
    description:
      "Explore the potentially devastating effects of consumer debt such as credit cards. Explore options to reduce consumer debt when purchasing a home.  Very easy to use and suitable for anyone.",
    route: "/consumer-debt",
    image: sellerNet,
  },
];
