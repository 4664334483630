import React from "react";

import PropTypes from "prop-types";

import {
  CustomFormControl,
  CustomFormGroup,
  CustomInputGroup,
} from "../../atoms";

export const CustomInput = ({ ...props }) => (
  <CustomFormGroup {...props}>
    <CustomInputGroup {...props}>
      <CustomFormControl {...props} />
    </CustomInputGroup>
  </CustomFormGroup>
);

CustomInput.propTypes = {
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.any,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  groupClassName: PropTypes.string,
  endAdornment: PropTypes.func,
  inputClassName: PropTypes.string,
};
