import { useEffect, useState } from "react";

import { UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../api";

export const usePurchaseValidator = ({
  fieldName,
  validatorUrl,
  inputValue,
  trigger,
  enableToastMessages,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [validationInfo, setValidationInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validate = async () => {
    try {
      setIsLoading(true);
      const isZipValid = await trigger(fieldName);
      if (isZipValid) {
        const response = await axiosInstance(`${validatorUrl}/${inputValue}`);
        setValidationInfo(response.data);
      }
    } catch (error) {
      const errorMessage = error.response.data;

      if (enableToastMessages) {
        UiToast.error(errorMessage);
      } else {
        setValidationError(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
    setIsSubmitted(true);
  };

  useEffect(() => {
    setValidationError(null);
    setValidationInfo(null);
  }, [inputValue]);

  useEffect(() => {
    const validate = async () => {
      await trigger(fieldName);
    };

    if (isSubmitted) {
      validate();
    }
  }, [isSubmitted, inputValue, trigger, fieldName]);

  return {
    validationError,
    validationInfo,
    isLoading,
    validate,
    setIsSubmitted,
  };
};
