import React from "react";

import PropTypes from "prop-types";
import { Container } from "react-bootstrap";

import { Footer, Header } from "../../organisms";

import "./styles.scss";

export const PageWrapper = ({ className, children }) => (
  <div className={`page-wrapper ${className}`}>
    <Header />
    <Container className="page-wrapper__container">{children}</Container>
    <Footer />
  </div>
);

PageWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
