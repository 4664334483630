import PropTypes from "prop-types";

import { SubscriptionItemCard } from "../../molecules";

export const ZipCodeCard = ({
  id,
  zipCode,
  status,
  price,
  endDate,
  cancelAt,
  discount,
  amountPaid,
}) => (
  <SubscriptionItemCard
    status={status}
    subscriptionId={id}
    subscriptionItemName="Zip Code"
    value={zipCode.code}
    price={price}
    amountPaid={amountPaid}
    discount={discount}
    cancelAt={cancelAt}
    itemNextInvoiceDate={endDate}
    descriptionItems={[
      { title: "City", value: zipCode.city },
      { title: "County", value: zipCode.area },
      { title: "State", value: zipCode.state },
    ]}
  />
);

ZipCodeCard.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  zipCode: PropTypes.shape({
    code: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
  }),
  discount: PropTypes.object,
  price: PropTypes.number.isRequired,
  amountPaid: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  cancelAt: PropTypes.string,
};
