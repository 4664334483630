import React, { useState } from "react";

import { Auth } from "aws-amplify";
import { UiToast } from "@ask-christee/ui-response";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useCustomReCaptcha } from "../../hooks";

const EnterCode = ({ forgotEmail }) => {
  const { verifyRecaptcha } = useCustomReCaptcha("enter_code");
  const navigate = useNavigate();

  const [forgotData, setForgotData] = useState({
    code: "",
    password: "",
  });

  const handleChange = (e) => {
    setForgotData({
      ...forgotData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!forgotData.code || !forgotData.password) {
      UiToast.error("Please enter a code and password!");
      return;
    }

    if (forgotData.password.length < 6) {
      UiToast.error("Password must be at least 6 characters");
      return;
    }

    try {
      const captcha = await verifyRecaptcha();

      const newPasswordData = await Auth.forgotPasswordSubmit(
        forgotEmail,
        forgotData.code,
        forgotData.password,
        { captcha },
      );
      if (newPasswordData) {
        UiToast.success(
          "Password changed successfuly. You can now login using your new password",
        );
        navigate("/login");
      }
    } catch (err) {
      UiToast.error(err.message);
    }
  };

  return (
    <div className="auth-container m-auto">
      <h3 className="h3 text-center">
        Enter new password and the code you <br /> received on your registered
        email
      </h3>
      <Form
        className="w-100 mt-5 d-flex flex-column"
        style={{ maxWidth: "350px" }}
        onSubmit={handleSubmit}
      >
        <Form.Group value={forgotData.code} onChange={handleChange}>
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            data-cy="code"
            name="code"
            type="text"
            className="input-field"
            placeholder="Verification Code"
          />
        </Form.Group>
        <Form.Group
          className="mt-4"
          value={forgotData.password}
          onChange={handleChange}
        >
          <Form.Label>New Password</Form.Label>
          <Form.Control
            data-cy="password"
            name="password"
            type="password"
            className="input-field"
            placeholder="New Password"
          />
        </Form.Group>
        <Button
          data-cy="btn-submit"
          variant="primary"
          type="submit"
          className="mt-5 m-auto px-4 py-2"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default EnterCode;
