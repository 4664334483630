import React from "react";

import PropTypes from "prop-types";

import States from "../../../../common/States";
import { CustomHookFormInput, CustomSelect } from "../../../molecules";

const statesOptions = States.map((item) => ({
  label: item.text,
  value: item.code,
}));

export const WorkPlaceInfoSection = ({ currentUser, control, errors }) => {
  const workPlaceName =
    currentUser.account_type === "agent" ? "Brokerage" : "Company";

  return (
    <>
      <h6 className="mt-3">Brokerage Information:</h6>
      <CustomHookFormInput
        label={`${workPlaceName} Name`}
        rules={{
          required: "Required",
          maxLength: { value: 70, message: "Maximum length is 70" },
        }}
        name="brokerage"
        placeholder={workPlaceName}
        control={control}
        errors={errors}
      />

      <CustomHookFormInput
        label={`${workPlaceName} Street Address`}
        rules={{
          required: "Required",
          maxLength: { value: 100, message: "Maximum length is 100" },
        }}
        name="brokerageAddress"
        placeholder={`${workPlaceName} address`}
        control={control}
        errors={errors}
      />

      <CustomHookFormInput
        label={`${workPlaceName} City`}
        rules={{
          required: "Required",
          maxLength: { value: 50, message: "Maximum length is 50" },
        }}
        name="brokerageCity"
        control={control}
        errors={errors}
      />

      <CustomHookFormInput
        name="brokerageState"
        inputComponent={CustomSelect}
        rules={{
          required: "Required",
        }}
        label={`${workPlaceName} State`}
        defaultValue={true}
        errors={errors}
        control={control}
        options={statesOptions}
      />

      <CustomHookFormInput
        label={`${workPlaceName} Zip Code`}
        rules={{
          required: "Required",
          pattern: {
            value: /^\d{5}(-\d{4})?$/,
            message: "Invalid ZIP Code",
          },
        }}
        name="brokerageZip"
        control={control}
        errors={errors}
      />
    </>
  );
};

WorkPlaceInfoSection.propTypes = {
  currentUser: PropTypes.object,
  control: PropTypes.object,
  errors: PropTypes.object,
};
