import PropTypes from "prop-types";

import { SubscriptionItemCard } from "../../molecules";
import { formatDate } from "../../../utils";

export const StateCard = ({
  id,
  price,
  state,
  status,
  discount,
  endDate,
  cancelAt,
  amountPaid,
}) => (
  <SubscriptionItemCard
    status={status}
    subscriptionId={id}
    subscriptionItemName="State"
    value={state?.state}
    amountPaid={amountPaid}
    discount={discount}
    price={price}
    cancelAt={cancelAt}
    itemNextInvoiceDate={formatDate(endDate)}
  />
);

StateCard.propTypes = {
  id: PropTypes.string.isRequired,
  state: PropTypes.shape({
    state: PropTypes.string.isRequired,
  }),
  discount: PropTypes.object,
  status: PropTypes.string.isRequired,
  amountPaid: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  cancelAt: PropTypes.string,
};
