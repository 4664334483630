import React from "react";

import PropTypes from "prop-types";

const About = ({ bio }) => {
  return (
    <div>
      <h2 className="h4">About Me</h2>
      <p>{bio}</p>
    </div>
  );
};

About.propTypes = {
  bio: PropTypes.string,
};

export default About;
