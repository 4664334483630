import React from "react";

import PropTypes from "prop-types";

import {
  CustomHookFormInput,
  PhoneHookFormInput,
} from "../../../components/molecules";

import personIcon from "../../../assets/images/person-icon.svg";

import { SectionWrapper } from "./SectionWrapper";

export const PersonalInformation = ({ control, errors }) => (
  <SectionWrapper icon={personIcon} text="Personal Information">
    <CustomHookFormInput
      label="First Name"
      rules={{
        required: "Required",
        pattern: {
          value: /\S/,
          message: "Can’t be blank",
        },
      }}
      name="firstName"
      control={control}
      errors={errors}
    />
    <CustomHookFormInput
      label="Last Name"
      rules={{
        required: "Required",
        pattern: {
          value: /\S/,
          message: "Can’t be blank",
        },
      }}
      name="lastName"
      control={control}
      errors={errors}
    />
    <CustomHookFormInput
      label="Email"
      type="email"
      name="email"
      errors={errors}
      control={control}
      rules={{
        required: "Required",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid email address",
        },
      }}
    />

    <PhoneHookFormInput
      label="Phone"
      rules={{
        required: "Required",
      }}
      name="phone"
      control={control}
      errors={errors}
    />
  </SectionWrapper>
);

PersonalInformation.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
