import React, { useContext, useEffect } from "react";

import PropTypes from "prop-types";
import {
  Navigate,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Auth } from "aws-amplify";
import { UiToast } from "@ask-christee/ui-response";

import { PageLoader } from "../PageLoader";
import { CurrentUserProviderContext } from "../../../contexts";

export const ProtectedRoute = ({ redirectRoute }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { currentUser, updateCurrentUser } = useContext(
    CurrentUserProviderContext,
  );

  useEffect(() => {
    const logOut = async () => {
      try {
        await Auth.signOut();
        updateCurrentUser({ isAuthenticated: false });
        UiToast.info("You are logged out");

        navigate("/login");
      } catch (err) {
        UiToast.error(err.message);
      }
    };

    if (searchParams.get("logout") === "true") {
      logOut();
    }
  }, [navigate, searchParams, updateCurrentUser]);

  if (currentUser.isLoading) return <PageLoader />;
  if (currentUser.isAuthenticated) return <Outlet />;
  return <Navigate to={redirectRoute} />;
};

ProtectedRoute.propTypes = {
  redirectRoute: PropTypes.string.isRequired,
};
