import React, { useState } from "react";

import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { useCustomReCaptcha } from "../../hooks";
import ResendCodeModal from "../../components/molecules/ResendCodeModal";
import { CustomHookFormInput, PasswordInput } from "../../components/molecules";

export const LoginForm = ({ setAuthInfo }) => {
  const { verifyRecaptcha } = useCustomReCaptcha("login");
  const [isLoading, setIsLoading] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const authData = watch();

  const resendConfirmation = async () => {
    try {
      const res = await Auth.resendSignUp(authData.email);
      if (res) {
        UiToast.success(
          `A verification link has been sent to ${authData.email}`,
        );
        setShowResendModal(false);
      }
    } catch (err) {
      UiToast.error(err.message);
    }
  };

  const onSubmit = async (submitData) => {
    try {
      setIsLoading(true);
      const captcha = await verifyRecaptcha();

      const { email, password } = submitData;
      const cognitoUser = await Auth.signIn(
        email.toLocaleLowerCase(),
        password,
        {
          captcha: captcha,
        },
      );

      UiToast.success(`A one-time passcode has been sent to your email`);

      setAuthInfo({ cognitoUser, email, password });
    } catch (err) {
      setIsLoading(false);
      UiToast.error(err.message);
      if (err.message === "User is not confirmed.") {
        setShowResendModal(true);
      }
    }
  };

  return (
    <>
      <ResendCodeModal
        showModal={showResendModal}
        handleHideModal={() => setShowResendModal(false)}
        buttonOnClick={resendConfirmation}
      />
      <h3 className="h3 text-center">Welcome Back to AskChristee!</h3>
      <Form className="w-100 mt-5" onSubmit={handleSubmit(onSubmit)}>
        <CustomHookFormInput
          label="Email"
          type="email"
          name="email"
          errors={errors}
          control={control}
          rules={{ required: "Required" }}
        />

        <PasswordInput
          rules={{ required: "Required" }}
          errors={errors}
          label="Password"
          control={control}
        />

        <ButtonWithSpinner
          spinnerVariant="light"
          data-cy="btn-submit"
          data-test="btn-submit"
          variant="primary"
          type="submit"
          className="mt-4 w-100"
          loading={isLoading}
          loadingIndicator="Log in..."
        >
          Log in
        </ButtonWithSpinner>
      </Form>

      <div className="d-flex flex-column mt-4 align-items-center">
        <Link to="/forgot-password" className="primaryText text-center">
          Forgot Password?
        </Link>
        <Link to="/signup" className="primaryText text-center">
          Don't have an account ? Sign up now.
        </Link>
      </div>
    </>
  );
};

LoginForm.propTypes = {
  setAuthInfo: PropTypes.func.isRequired,
};
