import axios from "axios";
import { Auth } from "aws-amplify";

import { camelizeKeys, snakifyKeys } from "../utils";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const session = await Auth.currentSession();
    config.headers.token = session.getAccessToken().getJwtToken();
  } catch (error) {
    console.error("Error getting access token", error);
  }

  if (config.data && typeof config.data === "object") {
    config.data = snakifyKeys(config.data);
  }

  return config;
});

axiosInstance.interceptors.response.use((response) => {
  if (
    response.data &&
    response.headers["content-type"].includes("application/json") &&
    typeof response.data === "object"
  ) {
    response.data = camelizeKeys(response.data);
  }
  return response;
});

export { axiosInstance };
