import React from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { formatPhoneNumber } from "../../../utils";
import { CustomInput } from "../CustomInput";

export const PhoneHookFormInput = ({
  control,
  name,
  type = "text",
  rules,
  ...props
}) => {
  const handleChange = (e) =>
    formatPhoneNumber(e.target.value).replace(/\D/g, "");

  return (
    <Controller
      name={name}
      rules={{
        ...rules,
        pattern: {
          value: /^\d{10}$/,
          message: "Invalid phone number",
        },
      }}
      control={control}
      render={({ field: { onChange, value = "", ref } }) => (
        <CustomInput
          type="tel"
          placeholder="(000) 000-0000"
          name={name}
          forwardedRef={ref}
          value={formatPhoneNumber(value)}
          onChange={(e) => onChange(handleChange(e))}
          {...props}
        />
      )}
    />
  );
};

PhoneHookFormInput.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  rules: PropTypes.object,
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};
