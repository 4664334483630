import React from "react";
import { Link, NavLink } from "react-router-dom";

import PropTypes from "prop-types";
import { Button, NavDropdown } from "react-bootstrap";

import logOutIcon from "../../../../assets/images/logOutIcon.svg";

import {
  menuItemPropTypes,
  menuItemsPropTypes,
  subItemPropTypes,
} from "../data";
import defaultPerson from "../../../../assets/images/default-person.png";

const menuItemClasses =
  "menu-item menu-item-type-custom menu-item-object-custom menu-item-105";

const DropdownItem = ({ subItem, isLast }) => (
  <React.Fragment>
    <NavDropdown.Item className="menu__dropdown-item" href={subItem.link}>
      {subItem.name}
    </NavDropdown.Item>
    {!isLast && <NavDropdown.Divider />}
  </React.Fragment>
);

DropdownItem.propTypes = {
  subItem: subItemPropTypes,
  isLast: PropTypes.bool,
};

const MenuLink = ({ item }) =>
  item.appLink ? (
    <NavLink to={item.appLink}>{item.name}</NavLink>
  ) : (
    <a title={item.name} href={item.link}>
      {item.name}
    </a>
  );

MenuLink.propTypes = {
  item: menuItemPropTypes,
};

const DropdownMenu = ({ item }) => (
  <NavDropdown className="menu__dropdown text-center" title={item.name}>
    {item?.subItems?.map((subItem, index) => (
      <DropdownItem
        key={subItem.name}
        subItem={subItem}
        isLast={index === item.subItems.length - 1}
      />
    ))}
  </NavDropdown>
);

DropdownMenu.propTypes = {
  item: menuItemPropTypes,
};

const NavItems = ({ items }) => (
  <ul id="menu-main-menu-1" className="menu">
    {items?.map((item, index) => (
      <li key={index} className={menuItemClasses}>
        {item.subItems ? (
          <DropdownMenu item={item} />
        ) : (
          <MenuLink item={item} />
        )}
      </li>
    ))}
  </ul>
);

NavItems.propTypes = {
  items: PropTypes.arrayOf(menuItemPropTypes),
};

export const MobileNav = ({
  logOut,
  menuItems,
  currentUser,
  showMobileNav,
  userName,
  setShowMobileNav,
}) => {
  const { email, isAuthenticated, isLoading, realtorPhoto, accountType } =
    currentUser;

  return (
    <div
      style={{ display: showMobileNav ? "block" : "none" }}
      id="overlayNav"
      className="menu-overlay overlay-bg"
      aria-label="mobile navigation"
    >
      <div className="overlay-header">
        <div className="position-relative">
          <button
            aria-label="Click to Close Menu"
            className="close-menu"
            onClick={() => setShowMobileNav(false)}
          >
            ×
          </button>
        </div>
      </div>

      <div className="overlay-content">
        <div className="container">
          <nav>
            {isAuthenticated && !isLoading && (
              <ul className="menu top-auth-section">
                <li className={menuItemClasses} style={{ textAlign: "left" }}>
                  <div className="d-flex align-items-center">
                    <img
                      className="header__user-image"
                      src={realtorPhoto || defaultPerson}
                      alt="user photo"
                    />
                    <div className="ms-3">
                      <Link
                        title="Profile"
                        to="/account/profile"
                        className="p-0"
                      >
                        {userName} ({accountType})
                      </Link>
                    </div>
                  </div>
                  <p className="mt-3">{email}</p>
                </li>
              </ul>
            )}

            <NavItems items={menuItems} />

            {!isLoading && (
              <ul id="menu-secondary-menu-1" className="menu">
                {isAuthenticated ? (
                  <li
                    className={
                      menuItemClasses +
                      " bottom-auth-section d-flex justify-content-center"
                    }
                  >
                    <Button
                      className="text-capitalize fw-bold fs-5 d-flex align-items-center justify-content-center w-100"
                      onClick={logOut}
                    >
                      <img
                        src={logOutIcon}
                        alt="log-out"
                        className="img-fluid"
                      />
                      <span className="ms-2">Log out</span>
                    </Button>
                  </li>
                ) : (
                  <li className="button menu-item mt-4">
                    <Link title="Log In" to="/login">
                      Log In
                    </Link>
                    <Link title="Sign Up" to="/signup" className="sign-up">
                      Sign Up
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  showMobileNav: PropTypes.bool,
  setShowMobileNav: PropTypes.func,
  userName: PropTypes.string,
  menuItems: menuItemsPropTypes,
  currentUser: PropTypes.object,
  UsernameToggle: PropTypes.func,
  logOut: PropTypes.func,
};
