import { NavDropdown } from "react-bootstrap";

import { DropdownItem } from "./DropdownItem";

import { menuItemPropTypes } from "../data";

export const DropdownMenu = ({ menuItem }) => (
  <NavDropdown
    key={menuItem.name}
    title={menuItem.name}
    className="menu__item menu__dropdown"
  >
    {menuItem?.subItems?.map((subItem, index) => (
      <DropdownItem
        key={subItem.name}
        subItem={subItem}
        index={index}
        length={menuItem.subItems.length}
      />
    ))}
  </NavDropdown>
);

DropdownMenu.propTypes = {
  menuItem: menuItemPropTypes,
};
