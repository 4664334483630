import React, { useContext } from "react";

import { CurrentUserProviderContext } from "../../../contexts";

import { AgentProfile } from "./AgentProfile";
import { OfficerProfile } from "./OfficerProfile";
import { HomebuyerProfile } from "./HomebuyerProfile";

import "./styles.scss";

export const Profile = () => {
  const { currentUser } = useContext(CurrentUserProviderContext);

  const isAgent = currentUser.accountType === "agent";
  const isOfficer = currentUser.accountType === "loan officer";
  const isHomebuyer = currentUser.accountType === "homebuyer";

  return (
    <>
      {isAgent && <AgentProfile />}
      {isOfficer && <OfficerProfile />}
      {isHomebuyer && <HomebuyerProfile />}
    </>
  );
};
