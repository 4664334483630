import React, { useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { STATE_VALIDATOR } from "../../../config";
import { usePurchaseValidator } from "../../../hooks";

import "./styles.scss";
import { VerifyInput } from "../VerifyInput";
import { StateMessages } from "./StateMessages";
import States from "../../../common/States";

const FIELD_NAME = "state";

export const VerifyState = ({
  rules,
  label,
  errors,
  control,
  trigger,
  fieldName,
  inputValue,
  placeholder,
  preValidation,
  setIsStateValid,
  enableToastMessages,
  onSuccessVerification,
}) => {
  const inputFieldName = fieldName || FIELD_NAME;

  const { isLoading, validate, validationInfo, validationError } =
    usePurchaseValidator({
      fieldName: inputFieldName,
      validatorUrl: STATE_VALIDATOR,
      enableToastMessages,
      inputValue,
      trigger,
    });

  const handleVerifyClick = async () => {
    if (!preValidation || (preValidation && preValidation())) {
      await validate();
    }
  };

  useEffect(() => {
    if (setIsStateValid) {
      setIsStateValid(!!validationInfo);
    }

    if (validationInfo && onSuccessVerification) {
      onSuccessVerification(validationInfo);
    }
  }, [onSuccessVerification, setIsStateValid, validationInfo]);

  const selectOptions = useMemo(
    () =>
      States.map((item) => ({
        label: item.text,
        value: item.text,
      })),
    [],
  );

  return (
    <>
      <Controller
        name={inputFieldName}
        rules={rules}
        control={control}
        render={({ field: { onChange, value = "", ref } }) => (
          <VerifyInput
            options={selectOptions}
            errors={errors}
            value={value}
            forwardedRef={ref}
            name={inputFieldName}
            label={label}
            onChange={onChange}
            isLoading={isLoading}
            placeholder={placeholder}
            handleVerifyClick={handleVerifyClick}
          />
        )}
      />
      {!enableToastMessages && (
        <StateMessages
          stateInfo={validationInfo}
          stateError={validationError}
          state={inputValue}
        />
      )}
    </>
  );
};

VerifyState.propTypes = {
  preValidation: PropTypes.func,
  inputValue: PropTypes.string,
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
  enableToastMessages: PropTypes.bool,
  onSuccessVerification: PropTypes.func,
  errors: PropTypes.object,
  trigger: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};
