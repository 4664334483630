import { FormMessage } from "../../atoms";
import React from "react";
import PropTypes from "prop-types";

export const StateMessages = ({ stateError, stateInfo, state }) => {
  return (
    <>
      {!stateError && !stateInfo && (
        <FormMessage status="info" text="Enter a state and verify it." />
      )}
      {stateError && <FormMessage status="error" text={stateError} />}
      {stateInfo && (
        <>
          <FormMessage
            status="success"
            text="This state is available for purchase."
            addition={
              <div className="form-message-addition">
                <p className="form-message-addition__item form-message-addition__item--bold">
                  State: {state}
                </p>
              </div>
            }
          />
          <FormMessage
            status="info"
            text="State will be reserved for 24 hours unless purchase is finalized."
          />
        </>
      )}
    </>
  );
};

StateMessages.proptypes = {
  stateError: PropTypes.string,
  stateInfo: PropTypes.string,
  state: PropTypes.string,
};
