import React from "react";
import PropTypes from "prop-types";

export const SectionWrapper = ({ icon, text, children }) => (
  <div className="signup-section">
    {icon && text && (
      <div className="signup-section__header">
        <img className="signup-section__icon" src={icon} alt="section icon" />
        <div className="signup-section__text">{text}</div>
      </div>
    )}
    {children}
  </div>
);

SectionWrapper.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  // children: PropTypes.element.isRequired,
};
