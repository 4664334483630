import React, { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";

import { CurrentUserProviderContext } from "../../../contexts";

const NonHomebuyerRoute = ({ redirectRoute }) => {
  const { currentUser } = useContext(CurrentUserProviderContext);

  if (currentUser.accountType === "homebuyer")
    return <Navigate to={redirectRoute} />;

  return <Outlet />;
};

export default NonHomebuyerRoute;
