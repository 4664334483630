import React, { Fragment, useEffect, useState } from "react";

import Select from "react-select";
import PropTypes from "prop-types";
import { Button, Form } from "react-bootstrap";
import { Controller, useWatch } from "react-hook-form";

import Areas from "../../../../common/Areas";
import States from "../../../../common/States/StatesAll";
import { CustomHookFormInput, CustomSelect } from "../../../molecules";
import salesPrice from "../../../../common/SalesPrice/SalesPrice";

import { validateFutureDate } from "../ProfileValidators";

import CirclePlus from "../../../../assets/images/circle-plus-solid.svg";

export const AgentLicenseInfoSection = ({
  errors,
  fields,
  append,
  remove,
  control,
  setValue,
  prevLicenseStates,
  updatePrevLicenseStates,
}) => {
  const [statesAreas, setStatesAreas] = useState([]);

  const licenseStates = useWatch({ name: "states", control });

  useEffect(() => {
    if (licenseStates) {
      const data = licenseStates.map((state, index) => {
        if (
          prevLicenseStates[index]?.state !== state?.state &&
          !!state?.areas?.[0]?.value
        ) {
          setValue(`states[${index}].areas`, []);
        }
        return Areas(state.state).map((area) => ({
          label: area.text,
          value: area.text,
        }));
      });
      setStatesAreas(data);
      updatePrevLicenseStates(structuredClone(licenseStates));
    }
  }, [licenseStates]); // eslint-disable-line react-hooks/exhaustive-deps

  const statesOptions = States.map((item) => ({
    label: item.text,
    value: item.code,
  }));

  return (
    <>
      <h6 className="mt-3">License Information:</h6>

      {licenseStates &&
        fields.map((item, index) => (
          <Fragment key={item.id}>
            <CustomHookFormInput
              name={`states.${index}.state`}
              rules={{
                required: "Required",
              }}
              inputComponent={CustomSelect}
              label="License State"
              defaultValue={true}
              errors={errors}
              control={control}
              options={statesOptions}
            />

            <Form.Group className="mt-2">
              <Form.Label>Service Areas</Form.Label>

              <Controller
                name={`states[${index}].areas`}
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <Form.Control
                      type="hidden"
                      isInvalid={!!errors?.states?.[index]?.areas}
                    />
                    <Select
                      ref={ref}
                      isMulti
                      classNamePrefix={
                        !!errors.financeType
                          ? "small-input-error"
                          : "small-input"
                      }
                      noOptionsMessage={() => "First select a brokerage state"}
                      value={value}
                      onChange={onChange}
                      options={statesAreas[index]}
                    />
                  </>
                )}
              />
              <Form.Control.Feedback type="invalid">
                Required
              </Form.Control.Feedback>
            </Form.Group>
            <CustomHookFormInput
              rules={{
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9]{6,12}$/,
                  message: "Invalid license number",
                },
              }}
              label="License Number"
              name={`states.${index}.license`}
              control={control}
              errors={errors}
            />
            <CustomHookFormInput
              label="License Expiration Date"
              rules={{
                required: "Required",
                validate: (value) =>
                  validateFutureDate(value) ||
                  "The date should be greater than the current date.",
              }}
              type="date"
              name={`states.${index}.licenseExpiration`}
              control={control}
              errors={errors}
            />
            {licenseStates?.length > 1 && (
              <div className="w-100 d-flex justify-content-end">
                <Button
                  type="button"
                  className="delete-state"
                  variant="outline-danger"
                  onClick={() => {
                    remove(index);
                  }}
                >
                  Remove
                </Button>
              </div>
            )}
          </Fragment>
        ))}

      <button
        type="button"
        className="add-state"
        onClick={() => {
          append({});
        }}
      >
        <img
          className="add-state__icon"
          src={CirclePlus}
          alt="CirclePlus"
          width={15}
        />{" "}
        Add another state you are licensed in.
      </button>
      <Form.Group className="mt-2">
        <Form.Label>Speciality</Form.Label>

        <Controller
          rules={{
            required: true,
          }}
          defaultValue={[]}
          name="specialityMultiple"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <>
              <Form.Control
                type="hidden"
                isInvalid={!!errors.specialityMultiple}
              />
              <Select
                ref={ref}
                isMulti
                value={value}
                classNamePrefix={
                  !!errors.specialityMultiple
                    ? "small-input-error"
                    : "small-input"
                }
                onChange={onChange}
                options={[
                  { label: "Buyer Agent", value: "Buyer Agent" },
                  { label: "Seller Agent", value: "Seller Agent" },
                ]}
              />
            </>
          )}
        />
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </Form.Group>

      <CustomHookFormInput
        label="Number of transactions in the last 12 months."
        rules={{
          required: "Required",
          max: {
            value: 999,
            message: "The number must be less than 1000",
          },
        }}
        type="number"
        name="transactions"
        control={control}
        errors={errors}
      />

      <CustomHookFormInput
        inputComponent={CustomSelect}
        name="salesPriceRange"
        rules={{
          required: "Required",
        }}
        label="Average Sales Price of transactions"
        defaultValue={true}
        errors={errors}
        control={control}
        options={salesPrice.map((price) => ({
          label: price.text,
          value: price.code,
        }))}
      >
        {salesPrice.map((state) => (
          <option key={state.code} value={state.code}>
            {state.text}
          </option>
        ))}
      </CustomHookFormInput>

      <Form.Group className="mt-3">
        <p className="form-question">Do you have a website?</p>

        <Controller
          name="hasWebsite"
          control={control}
          render={({ field: { onChange, value = true, ref } }) => (
            <>
              <Form.Check
                data-cy="hasWebsite"
                inline
                onChange={(e) => onChange(e.target.checked)}
                checked={!!value}
                ref={ref}
                label="Yes"
                name="hasWebsite"
                type="radio"
              />
              <Form.Check
                data-cy="hasWebsite"
                inline
                onChange={(e) => onChange(!e.target.checked)}
                checked={!value}
                ref={ref}
                label="No"
                name="hasWebsite"
                type="radio"
              />
            </>
          )}
        />
      </Form.Group>

      <CustomHookFormInput
        label="URL for your web site."
        name="websiteUrl"
        rules={{
          pattern: {
            value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
            message: "Invalid URL format",
          },
        }}
        control={control}
        errors={errors}
      />

      <Form.Group className="mt-3">
        <p className="form-question">
          Is MLS Search available on your website?
        </p>

        <Controller
          name="mlsSearch"
          control={control}
          render={({ field: { onChange, value = true, ref } }) => (
            <>
              <Form.Check
                data-cy="mlsSearch"
                inline
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                ref={ref}
                label="Yes"
                name="mlsSearch"
                type="radio"
              />
              <Form.Check
                data-cy="mlsSearch"
                inline
                onChange={(e) => onChange(!e.target.checked)}
                checked={!value}
                ref={ref}
                label="No"
                name="mlsSearch"
                type="radio"
              />
            </>
          )}
        />
      </Form.Group>

      <Form.Group className="mt-3">
        <p className="form-question">
          Is property search on your website provided by IDXBroker.com?
        </p>
        <Controller
          name="idxBroker"
          control={control}
          render={({ field: { onChange, value = true, ref } }) => (
            <>
              <Form.Check
                data-cy="idxBroker"
                inline
                onChange={(e) => onChange(e.target.checked)}
                checked={value}
                ref={ref}
                label="Yes"
                name="idxBroker"
                type="radio"
              />
              <Form.Check
                data-cy="idxBroker"
                inline
                onChange={(e) => onChange(!e.target.checked)}
                checked={!value}
                ref={ref}
                label="No"
                name="idxBroker"
                type="radio"
              />
            </>
          )}
        />
      </Form.Group>

      <CustomHookFormInput
        label="Enter name of your local MLS or Board of Realtors."
        rules={{
          required: "Required",
          maxLength: { value: 100, message: "Maximum length is 100" },
        }}
        name="mlsName"
        control={control}
        errors={errors}
      />
    </>
  );
};

AgentLicenseInfoSection.propTypes = {
  errors: PropTypes.object,
  fields: PropTypes.array,
  append: PropTypes.func,
  remove: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  prevLicenseStates: PropTypes.array,
  updatePrevLicenseStates: PropTypes.func,
};
