import React from "react";

import "./styles.scss";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "../../../../utils";

const InfoSection = ({ title, items }) => {
  const infoItemsToRender = Array.isArray(items)
    ? items.map(capitalizeFirstLetter).join(",  ")
    : items;

  return (
    <section className="agentsection">
      <h2 className="section-title h4">{title}</h2>
      <ul className="prof-details">{infoItemsToRender}</ul>
    </section>
  );
};

InfoSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

export default InfoSection;
