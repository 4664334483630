import React, { useContext, useState } from "react";

import { Button, Form } from "react-bootstrap";

import { Navigate, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { REFERRAL } from "../../../config";
import { BaseModal } from "../../atoms";
import { axiosInstance } from "../../../api";
import { CustomHookFormInput } from "../CustomHookFormInput";
import { CurrentUserProviderContext } from "../../../contexts";

import { referralOptions } from "./data";

export const ReferralModal = () => {
  const navigate = useNavigate();
  const { currentUser, updateCurrentUser } = useContext(
    CurrentUserProviderContext,
  );

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const selectedReferral = watch("referral");

  const isLastOptionSelected =
    selectedReferral === referralOptions[referralOptions.length - 1].value;

  const getRedirectPath = () =>
    ["loan_officer_state", "agent_zip_code"].includes(
      currentUser.subscriptionType,
    )
      ? "/modules/fill-profile-modal"
      : "/modules";

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const referralInfo = isLastOptionSelected ? data.other : data.referral;
      await axiosInstance.patch(REFERRAL, {
        referral: referralInfo,
      });

      updateCurrentUser({ ...currentUser, referral: referralInfo });

      UiToast.success("Your referral information successfully saved");
      navigate(getRedirectPath());
    } catch (error) {
      const errorMessage =
        error.response?.data || "An unexpected error has occurred";

      UiToast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHideModal = () => {
    navigate(getRedirectPath());
  };

  if (currentUser?.subscriptionType && currentUser?.referral) {
    return <Navigate to={getRedirectPath()} />;
  }

  return (
    <BaseModal
      show={true}
      size="md"
      handleClose={handleHideModal}
      title="How did you hear about us?"
    >
      <div className="d-flex flex-column">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Controller
              name="referral"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <>
                  {referralOptions.map((option) => (
                    <Form.Check
                      type="radio"
                      style={{ fontSize: "17px" }}
                      className="mt-2"
                      key={option.value}
                      label={option.label}
                      value={option.value}
                      id={option.value}
                      {...field}
                      onChange={() => field.onChange(option.value)}
                      checked={field.value === option.value}
                    />
                  ))}
                </>
              )}
            />
          </Form.Group>

          {isLastOptionSelected && (
            <CustomHookFormInput
              errors={errors}
              as="textarea"
              placeholder="Please specify where you heard about us."
              label="Other"
              name="other"
              control={control}
              rules={{
                required: "Required",
                minLength: {
                  value: 5,
                  message: "Input must be at least 5 characters long",
                },
                maxLength: {
                  value: 200,
                  message: "Input must be no more than 200 characters long",
                },
              }}
            />
          )}

          <div className="d-flex mt-4 justify-content-end">
            <Button
              onClick={handleHideModal}
              type="button"
              variant="outline-primary"
              className="bg-white text-primary border-0 px-4"
            >
              Skip
            </Button>

            <ButtonWithSpinner
              spinnerVariant="light"
              data-cy="btn-submit"
              variant="primary"
              type="submit"
              className="px-4 ms-2"
              loading={isLoading}
              loadingIndicator="Sending..."
            >
              Send
            </ButtonWithSpinner>
          </div>
        </Form>
      </div>
    </BaseModal>
  );
};
