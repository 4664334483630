export const transformUserData = (userData, cognitoUser) => {
  const isAdmin = cognitoUser
    ? cognitoUser.attributes["custom:isAdmin"] === "true"
    : false;

  return {
    ...userData,
    isAdmin,
    isLoading: false,
    initialCompletion: userData.createdAt,
    cognitoSub: userData.id,
    isApproved: userData.isApproved === "true",
  };
};
