import PropTypes from "prop-types";

import { BaseSectionTitle } from "../../atoms";
import { usePagination } from "../../../hooks";
import { CustomPagination } from "../../molecules";

import { AddStates } from "./AddStates";
import { StateCard } from "./StateCard";
import { ZipCodeCard } from "./ZipCodeCard";
import { AddZipCodes } from "./AddZipCodes";

import "./styles.scss";

const config = {
  agent_zip_code: {
    title: "zip code",
    AddButton: AddZipCodes,
    SubscriptionItemCard: ZipCodeCard,
    itemsPerPage: 4,
  },
  loan_officer_state: {
    title: "state",
    AddButton: AddStates,
    SubscriptionItemCard: StateCard,
    itemsPerPage: 6,
  },
};

export const SubscriptionItems = ({
  subscriptionType,
  subscriptionItems,
  subscriptionStatus,
}) => {
  const { title, AddButton, SubscriptionItemCard, itemsPerPage } =
    config[subscriptionType];

  const { currentData, changePage, currentPage, maxPage } = usePagination({
    data: subscriptionItems,
    itemsPerPage,
  });

  const isAddButtonDisabled = subscriptionStatus !== "active";

  const showPagination = maxPage > 1;

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center my-4">
        <BaseSectionTitle titleClassName="m-0" title={`My ${title}(s)`} />
        <AddButton disabled={isAddButtonDisabled} />
      </div>
      <div
        className={
          showPagination ? "subscription-items-wrapper--fixed-min-width" : ""
        }
      >
        <div className="subscription-items-wrapper">
          {currentData?.map((subscriptionItem) => (
            <SubscriptionItemCard
              key={subscriptionItem.id}
              {...subscriptionItem}
            />
          ))}
        </div>
      </div>
      <div className="mt-4">
        {showPagination && (
          <CustomPagination
            paginate={changePage}
            currentPage={currentPage}
            pageSize={itemsPerPage}
            totalCount={subscriptionItems.length}
          />
        )}
      </div>
    </div>
  );
};

SubscriptionItems.propTypes = {
  subscriptionItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      zipCode: PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        area: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
      }),
      state: PropTypes.shape({
        id: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
      }),
      cancelAt: PropTypes.string,
    }),
  ).isRequired,
  subscriptionType: PropTypes.oneOf(Object.keys(config)),
  subscriptionStatus: PropTypes.string,
};
