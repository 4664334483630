import { LANDING_URL } from "../../../config";

import cardHouse from "../../../assets/images/cardHouse.svg";

export const carouselData = [
  {
    image: cardHouse,
    title: "Quick Start Explore Purchase Price",
    description:
      "Explore affordable price home based your desired payment. View different loan types and cash requirements including down payment and closing cost. No income input required.",
    link: "/buyer-choice/quick",
    linkTitle: "Quick Start: Quick Buyer Choice Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Quick Start Options for a Specific Home",
    description:
      "Evaluate different purchase options of total cash required and monthly payments for different loan types including options to reduce required cash. No income input required.",
    link: "/buyer-prequal/quick",
    linkTitle: "Quick Start: Quick PreQual Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Getting Started",
    description:
      "Explore different loan types that result in an affordable price home with an affordable monthly payment and options to reduce total cash including down payment and closing cost.",
    link: "/buyer-prequal",
    linkTitle: "Buyer PreQual Module",
    video: `${LANDING_URL}/video/buyer-prequal-basics`,
  },
  {
    image: cardHouse,
    title: "Purchase a Specific Property",
    description:
      "Evaluate smart financial options to purchase a desired property. View multiple loan options, monthly payments, down payment, closing cost, closing help, and required cash.",
    link: "/buyer-choice",
    linkTitle: "Buyer Choice Module",
    video: `${LANDING_URL}/video/buyer-choice-basics`,
  },
  {
    image: cardHouse,
    title: "Closing Cost",
    description:
      "Closing costs are a major expense when buying real estate. This module will provide detailed closing cost and monthly payment information based upon your location and closing date.",
    link: "/closing-cost",
    linkTitle: "Closing Cost Module",
    video: `${LANDING_URL}/video/understanding-closing-cost/`,
  },
  {
    image: cardHouse,
    title: "Buy or Rent Analysis",
    description:
      "A smart home buying decision should be made based upon objective facts such as monthly rent vs mortgage payment, required cash, potential tax savings, and potential property equity.",
    link: "/buy-or-rent",
    linkTitle: "Buy Rent Module",
    video: `${LANDING_URL}/video/buy-or-rent/`,
  },
  {
    image: cardHouse,
    title: "Buy Today or Wait",
    description:
      "Evaluate all relevant factors for a smart decision such as interest rates, future property values, potential equity, tax saving, and required cash including down payment and closing cost.",
    link: "/buy-or-wait",
    linkTitle: "Buy or Wait Module",
    video: `${LANDING_URL}/video/buy-or-wait/`,
  },
  {
    image: cardHouse,
    title: "Reverse Mortgage",
    description:
      "If you are 62 years or older, explore the options for purchasing a home with a reverse mortgage, which does not require a monthly mortgage payment. Only available on AskChristee.",
    link: "/reverse",
    linkTitle: "Reverse Mortgage Module",
    video: `${LANDING_URL}/video/reverse-mortgage-part-1/`,
  },
  {
    image: cardHouse,
    title: "Buying a Vacation Home",
    description:
      "Is very different than purchasing a primary home including loan types and potential tax deductions. Get complete details and analysis including potential property tax increases.",
    link: "/vacation",
    linkTitle: "Vacation Home Module",
    video: `${LANDING_URL}/video/vacation-part-1/`,
  },
  {
    image: cardHouse,
    title: "Investing in Real Estate",
    description:
      "Developing a comprehensive real estate investment analysis can be time consuming and expensive. Evaluate detailed investment matrix including cash flow and depreciation schedules.",
    link: "/investment",
    linkTitle: "Investment Module",
    video: `${LANDING_URL}/video/investment-analysis/`,
  },
  {
    image: cardHouse,
    title: "Budget",
    description:
      "Explore the effects of a new mortgage payment and your monthly budget. Analysis is based on your lifestyle including current obligations and Federal and State Income Taxes.",
    link: "/budget",
    linkTitle: "Budget Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Seller Net",
    description:
      "This module deducts normal expenses from the gross sales price to arrive at the ‘net’ dollar proceeds based upon the location of the property, closing date, loan type and other optional inputs.",
    link: "/seller-net",
    linkTitle: "Seller Net Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Home Wealth Analysis",
    description:
      "For most folks, their most valuable asset is their home. Explore potential wealth, both annually and long term, based upon property appreciation and mortgage amortization.",
    link: "/equity",
    linkTitle: "Home Wealth Analysis Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Amortization",
    description:
      "Track annual interest and principal reduction as a dollar amount and percent of payments. Explore the effects of paying additional principal toward saving interest and payments.",
    link: "/amortization",
    linkTitle: "Amortization Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Payment Subsidy",
    description:
      "Is a great option to consider in a time of rising interest rates. See payment reduction based upon a 2% subsidy or a 1% subsidy. Total cost for both options completely disclosed.",
    link: "/subsidy",
    linkTitle: "Payment Subsidy Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "Consumer Debt",
    description:
      "This module calculates the time required to pay off a consumer debt such as a credit card and the total interest to be paid. Compare interest on credit card debt and a mortgage loan.",
    link: "/consumer-debt",
    linkTitle: "Consumer Debt Module",
    video: "nil",
  },
  {
    image: cardHouse,
    title: "AskChristee Modules",
    description:
      "Christee offers 16 distinct modules to help guide you through the complexities of a real transaction. Each module provides accurate and critical information for smarter decisions.",
    link: "/modules",
    linkTitle: "AskChristee Modules",
    video: "nil",
  },
];
