import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import ArrowBottomIcon from "../../../../assets/images/arrow-bottom-light-blue-icon.svg";

export const States = ({ item }) => {
  const contentRef = useRef(null);
  const [show, setShow] = useState(false);
  const [enableTransition, setEnableTransition] = useState(false);

  const toggleShow = () => {
    if (show) {
      const firstItemHeight = contentRef.current.children[0].scrollHeight;
      contentRef.current.style.maxHeight = `${firstItemHeight}px`;
      setTimeout(() => {
        setShow(false);
      }, 300);
    } else {
      setShow(true);
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    }

    if (!enableTransition) {
      setTimeout(() => setEnableTransition(true), 0);
    }
  }, [enableTransition, show]);

  return item?.length ? (
    <div className="collapsible-custom-cell">
      <div
        ref={contentRef}
        className={`${
          enableTransition ? "collapsible-custom-cell__content" : ""
        } ${show ? "collapsible-custom-cell__content--show" : ""}`}
      >
        {item[0]}
        {show &&
          item.slice(1).map((element) => <div key={element}>{element}</div>)}
      </div>
      {item.length > 1 && (
        <div className="collapsible-custom-cell__trigger" onClick={toggleShow}>
          {show ? "Hide" : "Show"}
          <img
            alt="bottom arrow"
            src={ArrowBottomIcon}
            className="collapsible-custom-cell__trigger-icon"
            style={{ transform: `rotate(${show ? 180 : 0}deg)` }}
          />
        </div>
      )}
    </div>
  ) : (
    "—"
  );
};

States.propTypes = {
  item: PropTypes.arrayOf(PropTypes.string),
};
