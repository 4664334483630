import React, { useContext, useEffect, useState } from "react";

import {
  UiToast,
  FormInputText,
  ButtonWithSpinner,
} from "@ask-christee/ui-response";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";

import { axiosInstance } from "../../api";
import { INTEREST_RATES } from "../../config";
import { Loader } from "../../components/atoms";
import { PageWrapper } from "../../components/organisms";
import { CurrentUserProviderContext } from "../../contexts";

const AdminRates = () => {
  const { currentUser } = useContext(CurrentUserProviderContext);

  const [isLoadingRates, setIsLoadingRates] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onTouched", reValidateMode: "onBlur" });

  const isAdmin = currentUser.isAdmin;

  const onSubmit = async (data) => {
    try {
      setIsLoadingSubmit(true);
      const response = await axiosInstance.put(INTEREST_RATES, data);
      if (response) {
        setIsLoadingSubmit(false);
        UiToast.success("Rates was successfully updated");
      }
    } catch (err) {
      setIsLoadingSubmit(false);
      UiToast.error(err.message);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoadingRates(true);
        const response = await axiosInstance(INTEREST_RATES);

        if (response.data) {
          reset(response.data);
          setIsLoadingRates(false);
        }
      } catch (err) {
        setIsLoadingRates(false);
        UiToast.error(err.message);
      }
    };

    getData();
  }, [reset]);

  if (!isAdmin) return <Navigate to="/" />;

  return (
    <PageWrapper className="bg-white">
      <div className="w-100 mt-5 d-flex flex-column align-items-center">
        <h2>Interest Rate Admin</h2>
        <div className="mt-3" style={{ maxWidth: "500px" }}>
          {isLoadingRates ? (
            <div
              style={{ height: "350px" }}
              className="d-flex justify-content-center align-items-center"
            >
              <Loader />
            </div>
          ) : (
            <div className="container content spacebelow">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex flex-column flex-sm-row justify-content-between">
                  <div>
                    <h3 className="text-center">30 Year</h3>
                    <div>
                      <div className="mt-2">
                        <FormInputText
                          label="Conventional"
                          tip="Conventional interest rate"
                          error={errors?.conv30}
                          name="conv30"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="FHA"
                          tip="FHA interest rate"
                          error={errors?.fha30}
                          name="fha30"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="VA"
                          tip="VA interest rate"
                          error={errors?.va30}
                          name="va30"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="USDA"
                          tip="USDA interest rate"
                          error={errors?.usda30}
                          name="usda30"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="Second Home Conv 30"
                          tip="Second Home Conv 30"
                          error={errors?.secondHomeConv30}
                          name="secondHomeConv30"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="HECM"
                          tip="HECM"
                          error={errors?.hecm}
                          name="hecm"
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ms-3">
                    <h3 className="text-center">15 Year</h3>
                    <div>
                      <div className="mt-2">
                        <FormInputText
                          label="Conventional"
                          tip="Conventional interest rate"
                          error={errors?.conv15}
                          name="conv15"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="FHA"
                          tip="FHA interest rate"
                          error={errors?.fha15}
                          name="fha15"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="VA"
                          tip="VA interest rate"
                          error={errors?.va15}
                          name="va15"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="USDA"
                          tip="USDA interest rate"
                          error={errors?.usda15}
                          name="usda15"
                          control={control}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInputText
                          label="Second Home Conv 15"
                          tip="Second Home Conv 15"
                          error={errors?.secondHomeConv15}
                          name="secondHomeConv15"
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 d-flex justify-content-end">
                  <ButtonWithSpinner
                    spinnerVariant="light"
                    data-cy="btn-submit"
                    variant="primary"
                    type="submit"
                    className="mt-4 w-100"
                    loading={isLoadingSubmit}
                    loadingIndicator="Saving..."
                  >
                    Save
                  </ButtonWithSpinner>
                </div>
              </Form>
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default AdminRates;
