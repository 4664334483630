import React from "react";

import PropTypes from "prop-types";

import { PageWrapper } from "../PageWrapper";

import arrowIcon from "../../../assets/images/arrow-right-blue-icon.svg";

import "./styles.scss";

export const SignupSelectFlowWrapper = ({
  title,
  additionalLink,
  children,
}) => (
  <PageWrapper>
    <div className="signup-select">
      <div>
        <div className="signup-select__header">
          <h2 className="signup-select__title">{title}</h2>
          {additionalLink && (
            <a
              href={additionalLink}
              className="btn btn-outline-primary signup-select__button ms-md-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
              <img
                className="signup-select__arrow-icon"
                src={arrowIcon}
                alt="arrow icon"
                width={24}
              />
            </a>
          )}
        </div>
        {children}
      </div>
    </div>
  </PageWrapper>
);

SignupSelectFlowWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
