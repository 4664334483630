import React from "react";

import ArrowBottomBlueIcon from "../../../assets/images/arrow-bottom-blue-icon.svg";

export const ExpendableButton = ({ isOpen }) => {
  return (
    <div className="me-3">
      <img
        src={ArrowBottomBlueIcon}
        alt="bottom arrow"
        width="24"
        style={{
          transform: `rotate(${isOpen ? 180 : 0}deg)`,
          transition: "all 0.25s",
        }}
      />
    </div>
  );
};
