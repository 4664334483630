import { useEffect } from "react";

import { UiToast } from "@ask-christee/ui-response";
import { useLocation, useNavigate } from "react-router-dom";

export const useToastOnQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get("showToast");

    if (message) {
      UiToast.success(message);

      queryParams.delete("showToast");
      navigate(`?${queryParams.toString()}`, { replace: true });
    }
  }, [location, navigate]);
};
