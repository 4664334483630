import React from "react";
import { Link } from "react-router-dom";

import { IconPopoverOverlay } from "../../../components/molecules";

export const ModulesGrid = ({ modulesData }) => {
  return (
    <div className="module-grid home-spacing">
      <div className="container">
        <h2 className="topHeading text-left w-100 mb-2">
          Select a module below to get started
        </h2>
        <div className="row justify-content-center lg-gutters mt-5">
          {modulesData.map((module) => (
            <div key={module.title} className="col-12 col-md-3">
              <div className="module-item">
                <IconPopoverOverlay
                  title={module.title}
                  placement={module.placement}
                >
                  {module.description}
                </IconPopoverOverlay>
                <Link to={module.route}>
                  <img
                    src={module.image}
                    alt={module.title}
                    style={{ display: "block", margin: "auto" }}
                  />
                  <p className="text-center">{module.title}</p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
