import PropTypes from "prop-types";

import { SectionWrapper } from "./SectionWrapper";

import { LANDING_URL } from "../../../config";
import { CustomCheckbox } from "../../../components/atoms";
import { CustomHookFormInput } from "../../../components/molecules";

export const Agreements = ({ agreementPoints, control, errors }) => (
  <SectionWrapper>
    {agreementPoints?.length &&
      agreementPoints.map(({ label, name }) => (
        <CustomHookFormInput
          rules={{
            required: "Required",
          }}
          inputComponent={CustomCheckbox}
          control={control}
          errors={errors}
          key={name}
          name={name}
          label={label}
        />
      ))}

    <CustomHookFormInput
      rules={{
        required: "Required",
      }}
      inputComponent={CustomCheckbox}
      control={control}
      errors={errors}
      name="accept_terms"
      label={
        <span>
          I acknowledge the{" "}
          <a
            href={`${LANDING_URL}/terms`}
            className="privacy-link"
            target="_new"
          >
            Privacy Policy and Terms
          </a>
        </span>
      }
    />
  </SectionWrapper>
);

Agreements.propTypes = {
  agreementPoints: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
