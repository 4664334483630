import React from "react";

import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import "./styles.scss";

export const Loader = ({ size = "md" }) => {
  return (
    <Spinner
      className={`custom-loader--${size}`}
      animation="border"
      variant="primary"
    />
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};
