import React from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import { resolvePath } from "../../../utils";

import "./styles.scss";

export const CustomFormControl = ({
  forwardedRef,
  errors,
  label,
  type = "text",
  disabled = false,
  placeholder = "",
  inputClassName = "",
  ...props
}) => (
  <Form.Control
    ref={forwardedRef}
    type={type}
    disabled={disabled}
    placeholder={placeholder || label}
    isInvalid={!!resolvePath(name, errors)}
    className={`custom-input ${inputClassName || ""}`}
    {...props}
  />
);

CustomFormControl.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  value: PropTypes.any,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
};
