export const referralOptions = [
  {
    label: "Colleague/friend",
    value: "colleague",
  },
  {
    label: "Social media",
    value: "social",
  },
  {
    label: "Search engine (Google, Bing, etc)",
    value: "search",
  },
  {
    label: "Blog/publication",
    value: "blog",
  },
  {
    label: "Other (please specify)",
    value: "other",
  },
];
