import React, { useState } from "react";

import { AuthCover } from "../../components/organisms";

import { LoginForm } from "./LoginForm";
import { OtpVerifyForm } from "./OtpVerifyForm";

const initialAuthState = {
  email: "",
  password: "",
  cognitoUser: null,
};

export const Login = () => {
  const [authInfo, setAuthInfo] = useState(initialAuthState);

  const resetAuthInfo = () => setAuthInfo(initialAuthState);

  return (
    <AuthCover isCentered>
      {authInfo.cognitoUser ? (
        <OtpVerifyForm
          authInfo={authInfo}
          setAuthInfo={setAuthInfo}
          resetAuthInfo={resetAuthInfo}
        />
      ) : (
        <LoginForm setAuthInfo={setAuthInfo} />
      )}
    </AuthCover>
  );
};
