import React from "react";

export const CustomTableNavigation = ({
  hasMore,
  paginationDirection,
  changePage,
}) => {
  return (
    <div className="custom-table-navigation">
      <button
        onClick={() => changePage("prev")}
        disabled={
          (paginationDirection === "prev" && !hasMore) || !paginationDirection
        }
        className="custom-table-navigation__item"
      >
        Previous
      </button>
      <button
        onClick={() => changePage("next")}
        disabled={
          (paginationDirection === "next" || !paginationDirection) && !hasMore
        }
        className="custom-table-navigation__item"
      >
        Next
      </button>
    </div>
  );
};
