import React, { useEffect, useState } from "react";

import Select from "react-select";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

import States from "../../../../common/States";
import Areas from "../../../../common/Areas";
import { CustomHookFormInput, CustomSelect } from "../../../molecules";

import { validateFutureDate } from "../ProfileValidators";

const statesOptions = States.map((item) => ({
  label: item.text,
  value: item.code,
}));

export const LoanOfficerLicenseInfoSection = ({
  control,
  errors,
  primaryServiceState,
  prevPrimaryServiceState,
  setValue,
}) => {
  const [stateAreas, setStateAreas] = useState([]);

  useEffect(() => {
    if (primaryServiceState !== prevPrimaryServiceState) {
      setValue("serviceArea", null);
    }

    setStateAreas(
      Areas(primaryServiceState).map((area) => ({
        label: area.text,
        value: area.text,
      })),
    );
  }, [prevPrimaryServiceState, primaryServiceState, setValue]);

  return (
    <>
      <h6 className="mt-3">License info:</h6>

      <CustomHookFormInput
        rules={{
          required: "Required",
          pattern: {
            value: /^\d{6,10}$/,
            message: "NMLS ID must be between 6 and 10 digits",
          },
        }}
        label="NMLS ID"
        placeholder="NMLS ID"
        name="nmls"
        control={control}
        errors={errors}
      />

      <CustomHookFormInput
        label="NMLS Licensed Expression Date"
        type="date"
        rules={{
          validate: (value) =>
            validateFutureDate(value) ||
            "The date should be greater than the current date.",
        }}
        name="licenseExpiration"
        control={control}
        errors={errors}
      />

      <Form.Group className="mt-2">
        <Form.Label>States Licensed</Form.Label>

        <Controller
          rules={{
            required: true,
          }}
          name="licenseStates"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <>
              <Form.Control type="hidden" isInvalid={!!errors.licenseStates} />
              <Select
                ref={ref}
                isMulti
                classNamePrefix={
                  !!errors.licenseStates ? "small-input-error" : "small-input"
                }
                value={value}
                onChange={onChange}
                options={statesOptions}
              />
            </>
          )}
        />
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </Form.Group>

      <CustomHookFormInput
        name="licenseNumber"
        label="State License Number"
        rules={{
          pattern: {
            value: /^[A-Z0-9-]+$/i,
            message: "Invalid License Number",
          },
        }}
        control={control}
        errors={errors}
      />

      <CustomHookFormInput
        name="primaryServiceState"
        rules={{
          required: "Required",
        }}
        label="Primary Service State"
        control={control}
        errors={errors}
        inputComponent={CustomSelect}
        defaultValue={true}
        options={statesOptions}
      />

      <CustomHookFormInput
        name="serviceArea"
        rules={{
          required: "Required",
        }}
        label="Primary Service County"
        control={control}
        errors={errors}
        inputComponent={CustomSelect}
        defaultValue={true}
        options={stateAreas}
      />

      <Form.Group className="mt-2">
        <Form.Label>Mortgage Types</Form.Label>

        <Controller
          rules={{
            required: true,
          }}
          name="financeType"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <>
              <Form.Control type="hidden" isInvalid={!!errors.financeType} />
              <Select
                ref={ref}
                isMulti
                value={value}
                classNamePrefix={
                  !!errors.financeType ? "small-input-error" : "small-input"
                }
                onChange={onChange}
                options={[
                  { label: "Conventional", value: "Conventional" },
                  { label: "FHA", value: "FHA" },
                  { label: "VA", value: "VA" },
                  { label: "USDA", value: "USDA" },
                  { label: "Reverse", value: "Reverse" },
                ]}
              />
            </>
          )}
        />
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mt-2">
        <Form.Label>Additional Mortgages</Form.Label>

        <Controller
          name="additionalMortgages"
          control={control}
          render={({ field: { onChange, value, ref } }) => (
            <>
              <Form.Control
                type="hidden"
                isInvalid={!!errors.additionalMortgages}
              />
              <Select
                ref={ref}
                isMulti
                value={value}
                classNamePrefix={
                  !!errors.additionalMortgages
                    ? "small-input-error"
                    : "small-input"
                }
                onChange={onChange}
                options={[
                  {
                    label: "Down Payment Assistance Programs",
                    value: "Down Payment Assistance Programs",
                  },
                  {
                    label: "Non-Traditional Income Verification",
                    value: "Non-Traditional Income Verification",
                  },
                  {
                    label: "Individual Tax ID Number",
                    value: "Individual Tax ID Number",
                  },
                  {
                    label: "Debt Service Coverage Ratio",
                    value: "Debt Service Coverage Ratio",
                  },
                  {
                    label: "Commercial Loans",
                    value: "Commercial Loans",
                  },
                ]}
              />
            </>
          )}
        />
        <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

LoanOfficerLicenseInfoSection.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  primaryServiceState: PropTypes.string,
  prevPrimaryServiceState: PropTypes.string,
  serviceArea: PropTypes.string,
  setValue: PropTypes.func,
};
