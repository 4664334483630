import React, { useContext } from "react";
import { CurrentUserProviderContext } from "../../contexts";
import { SelectSubscriptionType } from "../SelectSubscriptionType";

export const RenewSubscription = () => {
  const {
    currentUser: { accountType },
  } = useContext(CurrentUserProviderContext);

  return <SelectSubscriptionType isRenew accountType={accountType} />;
};
