import { useCallback, useContext, useEffect, useState } from "react";

import { UiToast } from "@ask-christee/ui-response";

import {
  AccountDataProviderContext,
  CurrentUserProviderContext,
  SubscriptionPricesProviderContext,
} from "../../../contexts";
import { Loader } from "../../atoms";
import { CurrentPlan } from "../CurrentPlan";
import { axiosInstance } from "../../../api";
import { SubscriptionItems } from "../SubscriptionItems";
import { UserAvailablePlans } from "../UserAvailablePlans";
import {
  LATEST_SUBSCRIPTION_ITEM_DATE,
  NEXT_INVOICE,
  SUBSCRIPTIONS,
} from "../../../config";

export const UserSubscription = () => {
  const { accountData, isDataFetched, updateAccountData, setIsDataFetched } =
    useContext(AccountDataProviderContext);
  const { currentUser } = useContext(CurrentUserProviderContext);
  const { isLoading: isPricesLoading } = useContext(
    SubscriptionPricesProviderContext,
  );

  const [isLoading, setIsLoading] = useState(false);

  const fetchSubscriptionsData = useCallback(async () => {
    try {
      setIsLoading(true);

      const requests = {};
      if (!isDataFetched.nextInvoiceFetched) {
        requests.nextInvoice = axiosInstance(NEXT_INVOICE);
      }
      if (!isDataFetched.subscriptionItemsFetched) {
        requests.subscriptionItems = axiosInstance(SUBSCRIPTIONS);
      }
      if (!isDataFetched.subscriptionLatestEndDate) {
        requests.subscriptionLatestEndDate = axiosInstance(
          LATEST_SUBSCRIPTION_ITEM_DATE,
        );
      }

      if (Object.keys(requests).length > 0) {
        const responses = await Promise.allSettled(Object.values(requests));
        const newAccountData = {};

        responses.forEach((response, index) => {
          const key = Object.keys(requests)[index];
          if (
            response.status === "rejected" &&
            response.reason?.response?.status !== 404
          ) {
            UiToast.error(response.reason?.response || "Something went wrong!");
          }
          newAccountData[key] = response.value?.data;
        });

        updateAccountData(newAccountData);

        setIsDataFetched((prevState) => ({
          ...prevState,
          nextInvoiceFetched: true,
          subscriptionItemsFetched: true,
          subscriptionLatestEndDate: true,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [
    isDataFetched.nextInvoiceFetched,
    isDataFetched.subscriptionItemsFetched,
    isDataFetched.subscriptionLatestEndDate,
    setIsDataFetched,
    updateAccountData,
  ]);

  useEffect(() => {
    fetchSubscriptionsData();
  }, [fetchSubscriptionsData]);

  const showSubscriptionItems = [
    "agent_zip_code",
    "loan_officer_state",
  ].includes(currentUser.subscriptionType);

  if (isLoading || isPricesLoading) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <CurrentPlan
        currentUser={currentUser}
        subscriptionItems={accountData?.subscriptionItems}
        nextInvoiceDate={accountData?.nextInvoice?.createdAt}
        totalSubscriptionsPrice={accountData?.totalSubscriptionsPrice}
        subscriptionLatestEndDate={accountData?.subscriptionLatestEndDate}
      />
      {showSubscriptionItems &&
        isDataFetched.subscriptionItemsFetched &&
        !!accountData?.subscriptionItems && (
          <SubscriptionItems
            subscriptionType={currentUser.subscriptionType}
            subscriptionItems={accountData?.subscriptionItems}
            subscriptionStatus={currentUser.subscriptionStatus}
          />
        )}
      <UserAvailablePlans {...currentUser} />
    </>
  );
};
