const MAX_FILE_SIZE = 10000000;
const ALLOWED_TYPES = ["image/jpeg", "image/png"];

export const profileImageValidator = async (value) => {
  return new Promise((resolve, reject) => {
    if (!(value instanceof File)) {
      resolve(null);
      return;
    }

    if (value.size > MAX_FILE_SIZE) {
      resolve("File size must be less than 10 MB.");
      return;
    }

    if (!ALLOWED_TYPES.includes(value.type)) {
      resolve("Invalid file type. Only PNG, JPEG are allowed.");
      return;
    }

    let reader = new FileReader();

    reader.onerror = () => {
      reject("Error reading the file.");
    };

    reader.onload = (e) => {
      const image = new Image();
      image.onerror = () => {
        resolve("Invalid image content.");
      };
      image.src = e.target.result;

      image.onload = function () {
        const height = this.height;
        const width = this.width;

        if (height < 180 || width < 180) {
          resolve("Height and width must be at least 180px");
        } else {
          resolve(null);
        }
      };
    };

    reader.readAsDataURL(value);
  });
};

export const validateFutureDate = (value) => {
  const selectedDate = new Date(value);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return selectedDate > today;
};
