import { axiosInstance } from "../api";
import { UPLOAD_IMAGE } from "../config";

export const useImageDirectUpload = () => {
  const imageUpload = async ({ image }) => {
    const reader = new FileReader();
    let uploadImage;
    reader.onload = (e) => {
      uploadImage = e.target.result;
    };
    reader.readAsDataURL(image);

    const mimeType = image.type;

    const { data } = await axiosInstance.post(UPLOAD_IMAGE, {
      content_type: mimeType,
    });

    const binary = atob(uploadImage.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    let blobData = new Blob([new Uint8Array(array)], { type: mimeType });
    const imageLinkRes = await fetch(data.uploadURL, {
      method: "PUT",
      body: blobData,
    });

    return imageLinkRes.url.split("?")[0];
  };

  return { imageUpload };
};
