import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

import { LANDING_URL } from "../../../config";

import logoWhile from "../../../assets/images/askchristee-logo-white.svg";

import "./styles.scss";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__top">
          <div className="col-12 col-md-3 col-xl-4 footer__section">
            <div className="footer__logo">
              <a href={LANDING_URL} className="footer__logo-link">
                <img
                  width="234"
                  height="60"
                  src={logoWhile}
                  className="footer__logo-img"
                  alt="Christee®"
                />
              </a>
            </div>
          </div>

          <div className="col-12 col-md-auto col-xl-4 footer__section">
            <nav className="footer__navigation">
              <ul className="footer__navigation-list">
                <li className="footer__navigation-item">
                  <a
                    className="footer__navigation-link"
                    title="Privacy Policy"
                    href={`${LANDING_URL}/privacy-policy`}
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="footer__navigation-item">
                  <a
                    className="footer__navigation-link"
                    title="Terms"
                    href={`${LANDING_URL}/terms`}
                  >
                    Terms
                  </a>
                </li>
                <li className="footer__navigation-item">
                  <a
                    className="footer__navigation-link"
                    title="Contact"
                    href={`${LANDING_URL}/contact`}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>

          <div className="col-12 col-md-auto col-xl-4 footer__section">
            <div className="footer__logos-wrapper">
              <span>
                <a
                  className="footer__logo"
                  href="https://twitter.com/AskChristee"
                  title=""
                  aria-label=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </span>
              <span>
                <a
                  className="footer__logo"
                  href="https://www.instagram.com/askchristee"
                  title=""
                  aria-label=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </span>
              <span>
                <a
                  className="footer__logo"
                  href="https://www.facebook.com/AskChristee"
                  title=""
                  aria-label=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="row justifu-content-between mx-0">
            <div className="col-12 col-md-8 item order-2 order-md-1 footer__section">
              <p className="footer__rights">
                © 2024 AskChristee Software. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
