import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import { ZIP_LOCATION } from "../../../config";
import { usePurchaseValidator } from "../../../hooks";

import { VerifyInput } from "../VerifyInput";
import { CustomHookFormInput } from "../CustomHookFormInput";

import { Messages } from "./Messages";

const FIELD_NAME = "zipCode";
const ZIP_PATTERN = /^\d{5}$/;

export const ZipCodeLocationInput = ({
  inputValue,
  rules,
  control,
  errors,
  trigger,
  setFormValue,
  providedLocation,
  setIsZipCodeValid,
  showInfoMessages = true,
}) => {
  const { isLoading, validate, validationInfo, validationError } =
    usePurchaseValidator({
      fieldName: FIELD_NAME,
      validatorUrl: ZIP_LOCATION,
      inputValue,
      trigger,
    });

  const handleVerifyClick = () => {
    validate();
  };

  useEffect(() => {
    if (providedLocation) {
      setFormValue("state", providedLocation.state);
      setFormValue("area", providedLocation.area);
    }
  }, [providedLocation, setFormValue]);

  useEffect(() => {
    setIsZipCodeValid(!!validationInfo);
    if (validationInfo) {
      setFormValue("state", validationInfo.state);
      setFormValue("area", validationInfo.area);
    }
  }, [setFormValue, setIsZipCodeValid, validationInfo]);

  return (
    <>
      <Controller
        name={FIELD_NAME}
        rules={{
          ...rules,
          pattern: { value: ZIP_PATTERN, message: "Invalid zip code provided" },
        }}
        control={control}
        render={({ field: { onChange, value = "", ref } }) => (
          <VerifyInput
            errors={errors}
            value={value}
            forwardedRef={ref}
            name={FIELD_NAME}
            label="Zip Code"
            onChange={onChange}
            isLoading={isLoading}
            placeholder="Enter zip code"
            handleVerifyClick={handleVerifyClick}
          />
        )}
      />
      <CustomHookFormInput
        name="state"
        control={control}
        disabled={true}
        label="State"
      />
      <CustomHookFormInput
        name="area"
        control={control}
        disabled={true}
        label="Area"
      />
      <Messages
        zipError={validationError}
        zipCodeInfo={validationInfo}
        showInfoMessages={showInfoMessages}
      />
    </>
  );
};

ZipCodeLocationInput.propTypes = {
  inputValue: PropTypes.string,
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
  showInfoMessages: PropTypes.bool,
  errors: PropTypes.object,
  trigger: PropTypes.func.isRequired,
  setFormValue: PropTypes.func.isRequired,
  setIsZipCodeValid: PropTypes.func.isRequired,
  providedLocation: PropTypes.shape({
    state: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired,
  }),
};
