import { CustomUsersTable, PageWrapper } from "../../components/organisms";

export const AdminUsersTable = () => {
  return (
    <PageWrapper className="bg-white">
      <div className="mt-5">
        <h1>Users List</h1>
        <CustomUsersTable />
      </div>
    </PageWrapper>
  );
};
