import React, { useState, useRef, useEffect } from "react";

import { Button, Overlay } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

import { CustomPopover } from "../CustomPopover";

export const IconPopoverOverlay = ({ children, title }) => {
  const [show, setShow] = useState(false);
  const [placement, setPlacement] = useState("right");
  const target = useRef(null);

  const updatePlacement = () => {
    if (window.innerWidth <= 768) {
      setPlacement("bottom");
    } else {
      let newPlacement = "right";

      // Calculate available space
      if (target.current) {
        const rect = target.current.getBoundingClientRect();
        const spaceOnRight = window.innerWidth - rect.right;

        // Check if popover goes out of the right edge
        if (spaceOnRight < 400) {
          newPlacement = "left";
        }
      }

      setPlacement(newPlacement);
    }
  };

  useEffect(() => {
    updatePlacement();
    window.addEventListener("resize", updatePlacement);
    return () => {
      window.removeEventListener("resize", updatePlacement);
    };
  }, []);

  const handleMouseEnter = () => {
    setShow(true);
    updatePlacement();
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  return (
    <div
      className="float-end"
      style={{ position: "absolute", right: 0, top: 0 }}
    >
      <Button
        ref={target}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        variant="link"
      >
        <InfoCircle />
      </Button>

      <Overlay target={target.current} show={show} placement={placement}>
        {(props) => (
          <CustomPopover
            {...props}
            title={title}
            id="icon-popover"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {children}
          </CustomPopover>
        )}
      </Overlay>
    </div>
  );
};
