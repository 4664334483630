import React, { Fragment } from "react";

import PropTypes from "prop-types";

export const LicenseInfo = ({ stateLicenseInfo }) => {
  return (
    <>
      ({stateLicenseInfo.state?.toUpperCase()}&nbsp;{stateLicenseInfo.license}){" "}
      <br />
      Areas:{" "}
      {stateLicenseInfo.areas?.map((area, i) => (
        <Fragment key={area.area}>
          {i ? ", " : ""}
          {area.area}
        </Fragment>
      ))}
    </>
  );
};

LicenseInfo.propTypes = {
  stateLicenseInfo: PropTypes.object.isRequired,
};
