import { useState } from "react";

import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../../../api";
import { snakifyKeys } from "../../../utils";
import { ADMIN_USERS_CSV } from "../../../config";

import downloadWhiteIcon from "../../../assets/images/download-white-icon.svg";

export const DownloadCsv = ({ filter, search }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadCSV = async () => {
    try {
      setIsLoading(true);

      const params = {
        search: search,
        ...snakifyKeys(filter),
      };

      const response = await axiosInstance.get(ADMIN_USERS_CSV, {
        params,
        responseType: "blob",
        headers: {
          "Content-Type": "text/csv",
        },
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "text/csv;charset=utf-8" }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      UiToast.error(error?.response?.data || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ButtonWithSpinner
      spinnerVariant="light"
      className="download-admin-csv"
      loading={isLoading}
      onClick={downloadCSV}
    >
      {!isLoading && <img src={downloadWhiteIcon} alt="download icon" />}
      <div className="ms-1 text-nowrap">Download CSV</div>
    </ButtonWithSpinner>
  );
};
