import React from "react";

import PropTypes from "prop-types";
import { ButtonWithSpinner } from "@ask-christee/ui-response";

import {
  CustomFormControl,
  CustomFormGroup,
  CustomFormSelect,
  CustomInputGroup,
} from "../../atoms";

import "./styles.scss";

export const VerifyInput = ({
  handleVerifyClick,
  options,
  isLoading,
  placeholder,
  ...props
}) => (
  <CustomFormGroup {...props} groupClassName="verify-input">
    <CustomInputGroup {...props}>
      {options ? (
        <CustomFormSelect
          defaultValue={placeholder}
          options={options}
          {...props}
        />
      ) : (
        <CustomFormControl
          disabled={isLoading}
          placeholder={placeholder}
          {...props}
        />
      )}
      <ButtonWithSpinner
        spinnerVariant="light"
        onClick={handleVerifyClick}
        className="verify-input__button"
        loading={isLoading}
      >
        Verify
      </ButtonWithSpinner>
    </CustomInputGroup>
  </CustomFormGroup>
);

VerifyInput.propTypes = {
  handleVerifyClick: PropTypes.func.isRequired,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.any,
  errors: PropTypes.object,
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};
