// TODO: update all places with usage of subscription type
export const subscriptionNames = {
  homebuyer: {
    basic: "homebuyer_basic",
    premium: "homebuyer_premium",
  },
  agent: {
    basic: "agent_basic",
    premium: "agent_zip_code",
  },
  "loan officer": {
    basic: "loan_officer_basic",
    premium: "loan_officer_state",
  },
};
