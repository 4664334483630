import React from "react";

export const SpecialLastRow = ({ itemsToRender }) => (
  <tr className="custom-table__row custom-table__row--no-border ">
    {itemsToRender.map((lastRowItem) => (
      <td key={lastRowItem} className="custom-table__row-item fw-bold">
        {lastRowItem}
      </td>
    ))}
  </tr>
);
