import "./styles.scss";
import PropTypes from "prop-types";

export const PaymentSummary = ({ title, priceItems, className }) => {
  const totalCost = priceItems.reduce((acc, current) => acc + current.price, 0);

  return (
    <div className={`payment-summary ${className}`}>
      <div className="payment-summary__header">Payment summary</div>
      <div className="payment-summary__body">
        <div className="payment-summary__row">
          <div className="payment-summary__collumn-name">{title}</div>
          <div className="payment-summary__collumn-name">Price</div>
        </div>
        {priceItems.map((priceItem) => (
          <div key={priceItem.value} className="payment-summary__row">
            <div className="payment-summary__item">{priceItem.value}</div>
            <div className="payment-summary__item">${priceItem.price}</div>
          </div>
        ))}
      </div>
      <div className="payment-summary__footer">
        <div className="payment-summary__row">
          <div className="payment-summary__total">Total:</div>
          <div className="payment-summary__total">${totalCost}</div>
        </div>
      </div>
    </div>
  );
};

PaymentSummary.propTypes = {
  title: PropTypes.string.isRequired,
  priceItems: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
};
