import React, { Fragment } from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { LicenseInfo } from "../../../../components/atoms/LicenseInfo";

import PhoneIcon from "../../../../assets/images/phone-icon.svg";
import LicenseIcon from "../../../../assets/images/license-icon.svg";
import LocationIcon from "../../../../assets/images/location-icon.svg";
import SuitcaseIcon from "../../../../assets/images/suitcase-icon.svg";

import "./styles.scss";
import { formatPhoneNumber } from "../../../../utils";

const Information = ({
  nmls,
  email,
  states,
  brokerage,
  accountType,
  brokerageZip,
  primaryPhone,
  cellPhone,
  brokerageCity,
  brokerageState,
  licenseNumber,
  brokerageAddress,
}) => {
  return (
    <div className="profile-info-section">
      <Row>
        <Col lg={5} className="profile-info-section__label-wrapper">
          <img src={SuitcaseIcon} alt="suitcase icon" />
          <p className="profile-info-section__label">Company Name:</p>
        </Col>
        <Col lg={7}>
          <p className="profile-info-section__text">{brokerage}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="profile-info-section__label-wrapper">
          <img src={LocationIcon} alt="location icon" />
          <p className="profile-info-section__label">Company Address:</p>
        </Col>
        <Col lg={7}>
          <p className="profile-info-section__text">
            {brokerageAddress} <br /> {brokerageCity}, {brokerageZip}
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="profile-info-section__label-wrapper">
          <img src={LicenseIcon} alt="license icon" />
          <p className="profile-info-section__label">License Number(s):</p>
        </Col>
        <Col lg={7}>
          <p className="profile-info-section__text">
            {accountType === "agent" && (
              <>
                {states?.map((state, index) => (
                  <Fragment key={state.state}>
                    {index ? (
                      <>
                        <br />
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <LicenseInfo key={state.state} stateLicenseInfo={state} />
                  </Fragment>
                ))}
              </>
            )}
            {accountType === "loan officer" && (
              <>
                {brokerageState?.toUpperCase()} {licenseNumber || nmls}
              </>
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="profile-info-section__label-wrapper">
          <img src={PhoneIcon} alt="phone icon" />
          <p className="profile-info-section__label">Cell Phone:</p>
        </Col>
        <Col lg={7}>
          <p className="profile-info-section__text">
            {formatPhoneNumber(cellPhone)}
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={5} className="profile-info-section__label-wrapper">
          <img src={PhoneIcon} alt="phone icon" />
          <p className="profile-info-section__label">Office Phone:</p>
        </Col>
        <Col lg={7}>
          <p className="profile-info-section__text">
            {formatPhoneNumber(primaryPhone)}
          </p>
        </Col>
      </Row>

      <a href={`mailto:${email}`} className="btn btn-primary w-100">
        Contact me
      </a>
    </div>
  );
};

Information.propTypes = {
  email: PropTypes.string,
  states: PropTypes.array,
  brokerage: PropTypes.string,
  brokerageAddress: PropTypes.string,
  brokerageCity: PropTypes.string,
  brokerageZip: PropTypes.string,
  brokerageState: PropTypes.string,
  primaryPhone: PropTypes.string,
  licenseNumber: PropTypes.string,
  accountType: PropTypes.string,
  nmls: PropTypes.string,
};

export default Information;
