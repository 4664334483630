import {
  AmortizationPdf,
  AmortizationReport,
  BudgetPdf,
  BudgetReport,
  BuyerChoiceReport,
  BuyerQualReport,
  BuyersChoicePdf,
  BuyOrRentPdf,
  BuyOrRentReport,
  BuyWaitPdf,
  BuyWaitReport,
  ClosingCostPdf,
  EquityReport,
  EquityPdf,
  PaymentSubsidyReport,
  PaymentSubsidyPdf,
  ConsumerDebtReport,
  ConsumerDebtPdf,
  ClosingCostReport,
  HecmPdf,
  HecmReport,
  InvestmentPdf,
  InvestmentReport,
  SellerNetPdf,
  SellerNetReport,
  VacationOneTwoReport,
  VacationPdf,
} from "@ask-christee/ui-response";

import {
  AMORTIZATION,
  BUDGET,
  BUY_WAIT,
  BUYERS_CHOICE,
  BUYERS_QUAL,
  CLOSING_COST,
  HECM,
  INVEST,
  EQUITY,
  PAYMENT_SUBSIDY,
  CONSUMER_DEBT,
  ROB,
  SELLER_NET,
  VACATION,
} from "../config";

export const ReportViewsHelper = {
  amortization: {
    api: AMORTIZATION,
    htmlReport: AmortizationReport,
    pdfReport: AmortizationPdf,
    dataToString: (data) => ({
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      filing_status: String(data.params.filing_status),
      sp: String(data.params.sp),
      land_value: String(data.params.land_value),
      q_income: String(data.params.q_income),
      int_rate: String(data.params.int_rate),
      term: String(data.params.term),
      condo: String(data.params.condo),
      inputcred: String(data.params.inputcred),
      rent_income: String(data.params.rent_income),
      vac_allow: String(data.params.vac_allow),
      main_amt: String(data.params.main_amt),
      prop_mgt: String(data.params.prop_mgt),
      report_years: String(data.params.report_years),
      prop_app: String(data.params.prop_app),
      rent_increase: String(data.params.rent_increase),
      sales_cost: String(data.params.sales_cost),
      all_cash: String(data.params.all_cash),
      pri_fin: String(data.params.pri_fin),
      cost_seg: String(data.params.cost_seg),
      cost_seg_period: String(data.params.cost_seg_period),
      s_cred: String(data.params.scred),
      input_dol_cred: String(data.params.input_dol_cred),
      input_ma: String(data.params.input_ma),
      input_ltv: String(data.params.input_ltv),
      input_uw_fee: String(data.params.input_uw_fee),
      org_fee: String(data.params.org_fee),
      prop_t: String(data.params.prop_t),
      display_ccost: String(data.params.display_ccost),
      save_to_db: "n",
    }),
  },
  "buy-wait": {
    api: BUY_WAIT,
    htmlReport: BuyWaitReport,
    pdfReport: BuyWaitPdf,
    dataToString: (data) => ({
      doType: data.params.doType,
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      prop_app: String(data.params.prop_app),
      report_years: String(data.params.report_years),
      sp: String(data.params.sp),
      input_ma: String(data.params.input_ma),
      input_ltv: String(data.params.input_ltv),
      filing_status: String(data.params.filing_status),
      int_rate: String(data.params.int_rate),
      int_rate2: String(data.params.int_rate2),
      q_income: String(data.params.q_income),
      m_debts: String(data.params.m_debts),
      ftbuyer: String(data.params.ftbuyer),
      prop_t: String(data.params.prop_t),
      inputcred: String(data.params.inputcred),
      s_cred: String(data.params.s_cred),
      term: String(data.params.term),
      save_to_db: "n",
    }),
  },
  "buyer-choice": {
    api: BUYERS_CHOICE,
    htmlReport: BuyerChoiceReport,
    pdfReport: BuyersChoicePdf,
    dataToString: (data) => ({
      use_ratios: data.params.use_ratios,
      doType: data.params.doType,
      state: data.params.state,
      county_select: String(data.params.county_select),
      t_cash: String(data.params.t_cash),
      q_income: String(data.params.q_income),
      m_debts: String(data.params.m_debts),
      sp: String(data.params.sp),
      prop_t: String(data.params.prop_t),
      interest_rates: String(data.params.interest_rates),
      int_rate: String(data.params.int_rate),
      s_cred: String(data.params.s_cred),
      filing_status: String(data.params.filing_status),
      report_summary: String(data.params.report_summary),
      inputcred: String(data.params.inputcred),
      input_ma: String(data.params.input_ma),
      lim_piti: String(data.params.lim_piti),
      input_ltv: String(data.params.inputcred),
      ftbuyer: String(data.params.ftbuyer),
      term: String(data.params.term),
      va_fee_ex: String(data.params.va_fee_ex),
      save_to_db: "n",
    }),
  },
  "buyer-qual": {
    api: BUYERS_QUAL,
    htmlReport: BuyerQualReport,
    pdfReport: BuyersChoicePdf,
    dataToString: (data) => ({
      use_ratios: data.params.use_ratios,
      doType: data.params.doType,
      state: data.params.state,
      county_select: String(data.params.county_select),
      t_cash: String(data.params.t_cash),
      q_income: String(data.params.q_income),
      m_debts: String(data.params.m_debts),
      interest_rates: data.params.interest_rates,
      int_rate: String(data.params.int_rate),
      s_cred: String(data.params.s_cred),
      filing_status: String(data.params.filing_status),
      report_summary: String(data.params.report_summary),
      inputcred: String(data.params.inputcred),
      lim_piti: String(data.params.lim_piti),
      input_ltv: String(data.params.inputcred),
      ftbuyer: String(data.params.ftbuyer),
      term: String(data.params.term),
      va_fee_ex: String(data.params.va_fee_ex),
      save_to_db: "n",
    }),
  },
  budget: {
    api: BUDGET,
    htmlReport: BudgetReport,
    pdfReport: BudgetPdf,
    dataToString: (data) => ({
      doType: data.params.doType,
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      filing_status: String(data.params.filing_status),
      sp: String(data.params.sp),
      self_emp: String(data.params.self_emp),
      child: String(data.params.child),
      q_income: String(data.params.q_income),
      tfree_income: String(data.params.tfree_income),
      prop_t: String(data.params.prop_t),
      int_rate: String(data.params.int_rate),
      inputcred: String(data.params.inputcred),
      input_ma: String(data.params.input_ma),
      input_ltv: String(data.params.input_ltv),
      other_amt: String(data.params.other_amt),
      otherd: String(data.params.otherd),
      health_ins: String(data.params.health_ins),
      auto_ins: String(data.params.auto_ins),
      condo: String(data.params.condo),
      retire: String(data.params.retire),
      c_cards: String(data.params.c_cards),
      car_pay: String(data.params.car_pay),
      c_phone: String(data.params.c_phone),
      cable: String(data.params.cable),
      input_food: String(data.params.input_food),
      c_care: String(data.params.c_care),
      c_support: String(data.params.c_support),
      alimony: String(data.params.alimony),
      sq_foot: String(data.params.sq_foot),
      coll_debt: String(data.params.coll_debt),
      pets: String(data.params.pets),
      va_fee_ex: String(data.params.va_fee_ex),
      save_to_db: "n",
    }),
  },
  "buy-rent": {
    api: ROB,
    htmlReport: BuyOrRentReport,
    pdfReport: BuyOrRentPdf,
    dataToString: (data) => ({
      doType: data.params.doType,
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      invest_return: String(data.params.invest_return),
      m_rent: String(data.params.m_rent),
      prop_app: String(data.params.prop_app),
      report_years: String(data.params.report_years),
      input_ma: String(data.params.input_ma),
      input_ltv: String(data.params.input_ltv),
      filing_status: String(data.params.filing_status),
      t_bracket: String(data.params.t_bracket),
      interest_rates: String(data.params.interest_rates),
      q_income: String(data.params.q_income),
      ftbuyer: String(data.params.ftbuyer),
      sp: String(data.params.sp),
      prop_t: String(data.params.prop_t),
      inputcred: String(data.params.inputcred),
      s_cred: String(data.params.s_cred),
      term: String(data.params.term),
      save_to_db: "n",
    }),
  },
  "closing-cost": {
    api: CLOSING_COST,
    htmlReport: ClosingCostReport,
    pdfReport: ClosingCostPdf,
    dataToString: (data) => ({
      doType: data.params.doType,
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      sp: String(data.params.sp),
      int_rate: String(data.params.int_rate),
      term: String(data.params.term),
      inputcred: String(data.params.inputcred),
      ftbuyer: String(data.params.ftbuyer),
      filing_status: String(data.params.filing_status),
      close_date: String(data.params.close_date),
      interest_rates: String(data.params.interest_rates),
      prop_t: String(data.params.prop_t),
      input_dol_cred: String(data.params.input_dol_cred),
      s_cred: String(data.params.s_cred),
      lend_cred: String(data.params.lend_cred),
      input_uw_fee: String(data.params.input_uw_fee),
      org_fee: String(data.params.org_fee),
      input_ma: String(data.params.input_ma),
      input_ltv: String(data.params.input_ltv),
      va_fee_ex: String(data.params.va_fee_ex),
      va_first: String(data.params.va_first),
      browser_results: String(data.params.browser_results),
      display_pdf_output: String(data.params.display_pdf_output),
      cust_name: String(data.params.cust_name),
      cust_addr: String(data.params.cust_addr),
    }),
  },
  hecm: {
    api: HECM,
    htmlReport: HecmReport,
    pdfReport: HecmPdf,
    dataToString: (data) => ({
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      age: String(data.params.age),
      sp: String(data.params.sp),
      t_cash: String(data.params.t_cash),
      input_ma: String(data.params.input_ma),
      input_ltv: String(data.params.input_ltv),
      int_rate: String(data.params.int_rate),
      prop_t: String(data.params.prop_t),
      input_rpt_age: String(data.params.input_rpt_age),
      input_orgfee: String(data.params.input_orgfee),
      cust_email: String(data.params.cust_email),
      cust_name: String(data.params.cust_name),
      cust_phone: String(data.params.cust_phone),
    }),
  },
  invest: {
    api: INVEST,
    htmlReport: InvestmentReport,
    pdfReport: InvestmentPdf,
    dataToString: (data) => ({
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      filing_status: String(data.params.filing_status),
      sp: String(data.params.sp),
      land_value: String(data.params.land_value),
      q_income: String(data.params.q_income),
      int_rate: String(data.params.int_rate),
      term: String(data.params.term),
      condo: String(data.params.condo),
      inputcred: String(data.params.inputcred),
      rent_income: String(data.params.rent_income),
      vac_allow: String(data.params.vac_allow),
      main_amt: String(data.params.main_amt),
      prop_mgt: String(data.params.prop_mgt),
      report_years: String(data.params.report_years),
      prop_app: String(data.params.prop_app),
      rent_increase: String(data.params.rent_increase),
      sales_cost: String(data.params.sales_cost),
      all_cash: String(data.params.all_cash),
      pri_fin: String(data.params.pri_fin),
      cost_seg: String(data.params.cost_seg),
      cost_seg_period: String(data.params.cost_seg_period),
      s_cred: String(data.params.s_cred),
      input_dol_cred: String(data.params.input_dol_cred),
      input_ma: String(data.params.input_ma),
      input_ltv: String(data.params.input_ltv),
      input_uw_fee: String(data.params.input_uw_fee),
      org_fee: String(data.params.org_fee),
      prop_t: String(data.params.prop_t),
      display_ccost: String(data.params.display_ccost),
      save_to_db: "n",
    }),
  },
  "seller-net": {
    api: SELLER_NET,
    htmlReport: SellerNetReport,
    pdfReport: SellerNetPdf,
    dataToString: (data) => ({
      state: String(data.params.state),
      county_select: String(data.params.county_select),
      sp: String(data.params.sp),
      ma_bal: String(data.params.ma_bal),
      close_date: String(data.params.close_date),
      mtge_date: String(data.params.mtge_date),
      ex_mtge: String(data.params.ex_mtge),
      term: String(data.params.term),
      int_rate: String(data.params.int_rate),
      taxes_paid: String(data.params.taxes_paid),
      org_fee: String(data.params.org_fee),
      s_cred: String(data.params.s_cred),
      input_dol_cred: String(data.params.input_dol_cred),
      buyer_ma: String(data.params.buyer_ma),
      comm: String(data.params.comm),
      prop_t: String(data.params.prop_t),
      cust_addr: String(data.params.cust_addr),
      doType: data.params.doType || [],
    }),
  },
  vacation: {
    api: VACATION,
    htmlReport: VacationOneTwoReport,
    pdfReport: VacationPdf,
    dataToString: (data) => {
      return {
        ...data.params,
      };
    },
  },
  equity: {
    api: EQUITY,
    htmlReport: EquityReport,
    pdfReport: EquityPdf,
  },
  "payment-subsidy": {
    api: PAYMENT_SUBSIDY,
    htmlReport: PaymentSubsidyReport,
    pdfReport: PaymentSubsidyPdf,
  },
  "consumer-debt": {
    api: CONSUMER_DEBT,
    htmlReport: ConsumerDebtReport,
    pdfReport: ConsumerDebtPdf,
  },
};
