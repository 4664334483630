export const signupContentConfig = {
  agent_zip_code: {
    title: "Create Agent Premium account",
  },
  agent_basic: {
    title: "Create Agent Basic account",
  },
  homebuyer_premium: {
    title: "Create Homebuyer Premium account",
  },
  loan_officer_state: {
    title: "Create Loan Officer Premium account",
  },
  loan_officer_basic: {
    title: "Create Loan Officer Basic account",
  },
};
