import React, { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";

import { PageLoader } from "../PageLoader";
import { CurrentUserProviderContext } from "../../../contexts";

export const SubscribedRoute = () => {
  const {
    currentUser: {
      isLoading,
      signupSubscriptionInProgress,
      subscriptionMeta,
      subscriptionStatus,
    },
  } = useContext(CurrentUserProviderContext);

  if (isLoading) return <PageLoader />;

  if (subscriptionMeta?.purchaseItemExpired && signupSubscriptionInProgress) {
    return <Navigate to="/re-enter-purchase-item" />;
  }

  if (subscriptionStatus === "canceled") {
    return <Navigate to="/subscription/renew" />;
  } else if (!subscriptionStatus) {
    return <Navigate to="/welcome-to-platform" />;
  }

  return <Outlet />;
};
