import React, { useContext, useState } from "react";

import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../../api";
import { Agreements } from "../Signup/sections";
import { subscriptionNames } from "../../common";
import { useHoldSubscriptionItems } from "../../hooks";
import { VerifyState } from "../../components/molecules";
import { VerifyZipCode } from "../../components/molecules";
import { CurrentUserProviderContext } from "../../contexts";
import { AuthCover, Footer, Header } from "../../components/organisms";
import { RE_ENTER_PURCHASE_ITEM, RENEW_CHECKOUT_SESSION } from "../../config";

import { InfoModal } from "./InfoModal";
import { reEnterContentConfig } from "./config";

import "./styles.scss";

export const ReEnterPurchaseItem = ({ subscriptionType, isRenew }) => {
  const {
    currentUser: { subscriptionMeta, subscriptionStatus },
  } = useContext(CurrentUserProviderContext);

  let reEnterSubscriptionType;

  if (subscriptionStatus === "canceled" || subscriptionMeta.subscriptionType) {
    reEnterSubscriptionType =
      subscriptionType || subscriptionMeta.subscriptionType;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isPurchaseItemValid, setIsPurchaseItemValid] = useState(false);

  const {
    control,
    trigger,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  const contentState = reEnterContentConfig[reEnterSubscriptionType];

  useHoldSubscriptionItems({
    itemsType: contentState?.holdItemsName,
    singleSubscriptionItem: isPurchaseItemValid
      ? formValues?.[contentState?.itemName]
      : null,
  });

  const onSubmit = async (submitData) => {
    if (!isPurchaseItemValid) {
      return UiToast.error(contentState?.validateMessage);
    }

    try {
      setIsLoading(true);

      const requestUrl = isRenew
        ? RENEW_CHECKOUT_SESSION
        : RE_ENTER_PURCHASE_ITEM;

      const response = await axiosInstance.post(requestUrl, {
        subscriptionType,
        [contentState?.itemName]: submitData?.[contentState?.itemName],
      });

      if (response) {
        window.location.href = response.data.sessionUrl;
      }
    } catch (error) {
      UiToast.error(error?.response?.data || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  if (!contentState) return <Navigate to="/" />;
  if (
    !subscriptionMeta.purchase_item_expired &&
    (subscriptionMeta.state || subscriptionMeta.zipCode)
  )
    return <Navigate to="/welcome-to-platform" />;

  return (
    <>
      <Header />
      <AuthCover isCentered title={contentState.pageTitle}>
        {!isRenew && <InfoModal modalText={contentState.modalText} />}
        <Form
          className="re-enter-purchase-item"
          onSubmit={handleSubmit(onSubmit)}
        >
          {reEnterSubscriptionType === subscriptionNames.agent.premium && (
            <VerifyZipCode
              setIsZipCodeValid={setIsPurchaseItemValid}
              label="Primary Zip Code"
              trigger={trigger}
              rules={{
                required: "Required",
              }}
              errors={errors}
              control={control}
              inputValue={formValues?.[contentState.itemName]}
            />
          )}

          {reEnterSubscriptionType ===
            subscriptionNames["loan officer"].premium && (
            <VerifyState
              setIsStateValid={setIsPurchaseItemValid}
              label="Primary State"
              placeholder="Enter state"
              trigger={trigger}
              rules={{
                required: "Required",
              }}
              errors={errors}
              control={control}
              inputValue={formValues?.[contentState.itemName]}
            />
          )}

          <Agreements control={control} errors={errors} />

          <ButtonWithSpinner
            spinnerVariant="light"
            variant="primary"
            type="submit"
            className="re-enter-purchase-item__button"
            loading={isLoading}
          >
            Submit
          </ButtonWithSpinner>
        </Form>
      </AuthCover>
      <Footer />
    </>
  );
};
