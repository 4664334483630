import PropTypes from "prop-types";

import "./styles.scss";

export const ItemsToPurchaseSection = ({ className, children }) => (
  <div className={`items-purchase-section ${className}`}>{children}</div>
);

ItemsToPurchaseSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
