import { useCallback } from "react";
import Cookies from "js-cookie";
import { APP_DOMAIN } from "../config";

const baseOptions = {
  path: "/",
  secure: true,
  expires: 365,
  ...(APP_DOMAIN && { domain: APP_DOMAIN }),
};

export const useCookie = (cookieName) => {
  const getCookie = useCallback(() => {
    const cookie = Cookies.get(cookieName);
    try {
      return cookie ? JSON.parse(cookie) : null;
    } catch (e) {
      return cookie;
    }
  }, [cookieName]);

  const setCookie = useCallback(
    ({ value, options = {} }) => {
      const isObject = (val) => typeof val === "object" && val !== null;
      const cookieValue = isObject(value)
        ? JSON.stringify(value)
        : String(value);
      Cookies.set(cookieName, cookieValue, { ...baseOptions, ...options });
    },
    [cookieName],
  );

  const removeCookie = useCallback(
    (options = {}) => {
      Cookies.remove(cookieName, { ...baseOptions, ...options });
    },
    [cookieName],
  );

  return { getCookie, setCookie, removeCookie };
};
