import React from "react";

import { OverlayTrigger } from "react-bootstrap";

import { CustomPopover } from "../../../molecules";
import PropTypes from "prop-types";

export const Referral = ({ item = "" }) => {
  const configItems = [
    { label: "Colleague/friend", value: "colleague" },
    { label: "Social media", value: "social" },
    { label: "Search engine", value: "search" },
    { label: "Blog/publication", value: "blog" },
  ];

  const displayItem = configItems.find((element) => element.value === item);

  if (!item) {
    return "—";
  } else if (!!displayItem) {
    return <div>{displayItem.label}</div>;
  } else {
    return (
      <div className="d-flex">
        <OverlayTrigger
          placement="top-end"
          overlay={<CustomPopover title="Other">{item}</CustomPopover>}
        >
          <div className="referral-custom-cell">Other</div>
        </OverlayTrigger>
      </div>
    );
  }
};

Referral.propTypes = {
  item: PropTypes.string,
};
