import PropTypes from "prop-types";

import BlueCross from "../../../assets/images/blue-cross.svg";
import SuccessIcon from "../../../assets/images/success-icon.svg";
import { capitalizeFirstLetter } from "../../../utils";

export const PurchaseItem = ({
  purchaseItemName,
  descriptionItems,
  value,
  onRemoveItem,
}) => (
  <div className="purchase-item">
    <div className="purchase-item__header">
      <div className="purchase-item__header-text">
        {capitalizeFirstLetter(purchaseItemName)}: {value}
      </div>
      <div className="purchase-item__remove" onClick={onRemoveItem}>
        <img src={BlueCross} width="24" alt="blue cross" />
      </div>
    </div>
    <div className="purchase-item__description">
      {descriptionItems?.map((descriptionItem) => (
        <p key={descriptionItem} className="purchase-item__description-item">
          {descriptionItem}
        </p>
      ))}
    </div>
    <div className="purchase-item__message">
      <img src={SuccessIcon} width="24" alt="green check" />
      <p className="purchase-item__message-text">
        This {purchaseItemName} is available for purchase.
      </p>
    </div>
  </div>
);

PurchaseItem.propTypes = {
  purchaseItemName: PropTypes.string.isRequired,
  descriptionItems: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
};
