import { SubscriptionCard } from "../../molecules";

export const SubscriptionActive = ({ ...props }) => {
  return (
    <SubscriptionCard
      buttonText="Active"
      buttonVariant="outline-primary"
      {...props}
    />
  );
};
