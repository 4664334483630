import React from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import { resolvePath } from "../../../utils";

import "./styles.scss";

export const CustomFormGroup = ({
  errors,
  name,
  label,
  groupClassName = "",
  fieldRequirements,
  children,
}) => {
  const inputError = resolvePath(name, errors);

  return (
    <Form.Group className={`mt-2 ${groupClassName}`}>
      {label && <Form.Label>{label}</Form.Label>}

      {children}

      {(!!inputError || fieldRequirements) && (
        <div
          className={`custom-validation-message ${
            !!inputError ? "custom-validation-message_error" : ""
          }`}
        >
          {fieldRequirements || inputError.message}
        </div>
      )}
    </Form.Group>
  );
};

CustomFormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  groupClassName: PropTypes.string,
  fieldRequirements: PropTypes.string,
};
