import React from "react";

import { Amplify, Auth } from "aws-amplify";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { UiSettingsProvider } from "@ask-christee/ui-response";

import UnavailabilityWrapper from "./components/molecules/UnavailabilityWrapper";

import AppRoutes from "./AppRoutes";
import { STAGE, STRIPE_PK } from "./config";
import { amplifyConfig, cognitoAuthConfig, uiLibApiUrls } from "./appConfig";
import { CurrentUserProvider, SubscriptionPricesProvider } from "./contexts";

import "./App.scss";

Amplify.configure(amplifyConfig);
Auth.configure(cognitoAuthConfig);

const stripePromise = loadStripe(STRIPE_PK);

function App() {
  return (
    <UnavailabilityWrapper>
      <Elements stripe={stripePromise}>
        <UiSettingsProvider
          stage={STAGE}
          apiUrls={uiLibApiUrls}
          amplifyConfig={amplifyConfig}
          recaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
          companyId="ask"
        >
          <CurrentUserProvider>
            <SubscriptionPricesProvider>
              <BrowserRouter>
                <AppRoutes />
              </BrowserRouter>
            </SubscriptionPricesProvider>
          </CurrentUserProvider>
        </UiSettingsProvider>
      </Elements>
    </UnavailabilityWrapper>
  );
}

export default App;
