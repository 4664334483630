import React, { useState } from "react";

import { BaseCard } from "../../molecules";
import { BaseSectionTitle } from "../../atoms";

import penIcon from "../../../assets/images/pen-icon.svg";
import cardIcon from "../../../assets/images/card-icon.svg";

import "./styles.scss";
import { UpdatePaymentMethodModal } from "./UpdatePaymentMethodModal";

export const PaymentMethod = ({ paymentData, updatePaymentData }) => {
  const [showModal, setShowModal] = useState(false);

  const PaymentInfo = () =>
    paymentData ? (
      <div className="payment-method__card-text">
        <p>
          <span className="payment-method__card-brand">
            {paymentData?.brand}
          </span>{" "}
          **** **** **** {paymentData?.last4}
        </p>
        <p>Expires {paymentData?.expiration}</p>
      </div>
    ) : (
      <p className="payment-method__no-payment-method-message">
        Payment method have not been added yet.
      </p>
    );

  return (
    <div>
      <BaseSectionTitle title="Payment method" />
      <BaseCard
        className="payment-method"
        onClick={() => setShowModal(true)}
        body={() => (
          <div className="payment-method__body">
            <div className="payment-method__card-info">
              <img src={cardIcon} alt="googleIcon" width={36} />
              <PaymentInfo />
            </div>
            <div className="payment-method__card-icon">
              <img src={penIcon} alt="googleIcon" width={24} />
            </div>
          </div>
        )}
      />

      <UpdatePaymentMethodModal
        show={showModal}
        updatePaymentData={updatePaymentData}
        handleClose={() => setShowModal(false)}
      />
    </div>
  );
};
