import React from "react";

import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ButtonWithSpinner } from "@ask-christee/ui-response";

import { BaseModal } from "../../atoms";

import "./styles.scss";

export const ActionModal = ({
  title,
  showModal,
  closeModal,
  isLoading,
  onActionClick,
  actionLabel,
  children,
}) => (
  <div className="position-absolute">
    <BaseModal
      show={showModal}
      size="md"
      handleClose={closeModal}
      title={title}
    >
      <div className="action-modal">
        {children}
        <div className="action-modal__buttons">
          <Button
            variant="outline-primary"
            className="action-modal__button"
            onClick={closeModal}
          >
            Close
          </Button>
          <ButtonWithSpinner
            spinnerVariant="light"
            variant="primary"
            onClick={onActionClick}
            className="action-modal__button"
            loading={isLoading}
            loadingIndicator={`${actionLabel}...`}
          >
            {actionLabel}
          </ButtonWithSpinner>
        </div>
      </div>
    </BaseModal>
  </div>
);

ActionModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onActionClick: PropTypes.func.isRequired,
  actionLabel: PropTypes.string.isRequired,
  children: PropTypes.node,
};
