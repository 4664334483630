import React from "react";

import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const BaseModal = ({ size = "lg", title, show, handleClose, children }) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size={size}
        aria-labelledby="title"
      >
        <Modal.Header closeButton>
          <h4 className="fw-bold px-4 my-0">{title}</h4>
          <Modal.Title id="title">&nbsp;</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="container content spacebelow">
              <div className="row">
                <div className="col-md-12">
                  <div className="px-2 px-sm-4">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

BaseModal.propTypes = {
  title: PropTypes.string,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default BaseModal;
