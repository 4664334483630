import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  AmortizationForm,
  BudgetForm,
  BuyOrRentForm,
  BuyWaitForm,
  ClosingCostForm,
  ConsumerDebtForm,
  EquityForm,
  HecmForm,
  InvestmentForm,
  PaymentSubsidyForm,
  SellerNetForm,
  BuyerQualFormV2,
  BuyerChoiceFormV2,
  VacationFormV2,
  BuyOrRentFormV2,
  BuyerChoiceFormQuick,
  BuyerQualFormQuick,
} from "@ask-christee/ui-response";

import {
  Modules,
  UserInfoProfile,
  AdminHome as AdminHomeView,
  AdminRates as AdminRatesView,
  ResetPassword as ResetPasswordView,
  OnBoardMessage as OnBoardMessageView,
  Error404,
  ModuleLayout,
  SelectAccountType,
  SelectSubscriptionType,
  WelcomeToPlatform,
  LoanOfficerSignup,
  AgentSignup,
  HomebuyerSignup,
  ReEnterPurchaseItem,
  UserAccount,
  RenewSubscription,
  ConfirmEmail,
  Login,
  AdminUsersTable,
} from "./views";
import AdminRoute from "./components/molecules/AdminRoute";
import NonHomebuyerRoute from "./components/molecules/NonHomebuyerRoute";
import {
  FillProfileMessageModal,
  PublicOnlyRoute,
  ProtectedRoute,
  SubscribedRoute,
  ReferralModal,
} from "./components/molecules";

import { useGAPageView } from "./hooks";

const AppRoutes = () => {
  useGAPageView();

  return (
    <Routes>
      <Route element={<PublicOnlyRoute redirectRoute="/modules" />}>
        <Route path="/confirm-email" element={<ConfirmEmail />} />

        <Route path="/login" element={<Login />} />

        <Route path="/signup" element={<SelectAccountType />} />

        <Route
          path="/select-subscription/homebuyer"
          element={<SelectSubscriptionType accountType="homebuyer" />}
        />

        <Route
          path="/select-subscription/agent"
          element={<SelectSubscriptionType accountType="agent" />}
        />

        <Route
          path="/select-subscription/loan-officer"
          element={<SelectSubscriptionType accountType="loan officer" />}
        />

        <Route path="/signup/agent-basic" element=<AgentSignup /> />

        <Route
          path="/signup/agent-premium"
          element=<AgentSignup isPremium={true} />
        />

        <Route
          path="/signup/loan-officer-basic"
          element=<LoanOfficerSignup />
        />

        <Route
          path="/signup/loan-officer-premium"
          element=<LoanOfficerSignup isPremium={true} />
        />

        <Route path="/signup/homebuyer-basic" element=<HomebuyerSignup /> />
        <Route
          path="/signup/homebuyer-premium"
          element=<HomebuyerSignup isPremium={true} />
        />

        <Route path="/forgot-password" element={<ResetPasswordView />} />

        <Route path="/onboarding" element={<OnBoardMessageView />} />
      </Route>

      <Route element={<ProtectedRoute redirectRoute="/login" />}>
        <Route path="/welcome-to-platform" element={<WelcomeToPlatform />} />

        <Route
          path="/re-enter-purchase-item"
          element={<ReEnterPurchaseItem />}
        />

        <Route path="/subscription/renew" element={<RenewSubscription />} />

        <Route
          path="/subscription/renew/agent-premium"
          element={
            <ReEnterPurchaseItem isRenew subscriptionType="agent_zip_code" />
          }
        />

        <Route
          path="/subscription/renew/loan-officer-premium"
          element={
            <ReEnterPurchaseItem
              isRenew
              subscriptionType="loan_officer_state"
            />
          }
        />

        <Route element={<SubscribedRoute />}>
          <Route path="/" exact element={<Modules />} />
          <Route path="/modules" element={<Modules />}>
            <Route
              path="fill-profile-modal"
              element={<FillProfileMessageModal />}
            />
            <Route path="referral-source" element={<ReferralModal />} />
          </Route>

          <Route
            path="/agents/:id"
            element={<UserInfoProfile userType="agent" />}
          />
          <Route
            path="/officers/:id"
            element={<UserInfoProfile userType="loan officer" />}
          />

          <Route element={<ModuleLayout />}>
            <Route path="/amortization" element={<AmortizationForm />} />
            <Route path="/budget" element={<BudgetForm />} />
            <Route path="/buy-or-rent" element={<BuyOrRentFormV2 />} />
            <Route path="/buy-or-wait" element={<BuyWaitForm />} />
            <Route path="/buyer-choice" element={<BuyerChoiceFormV2 />} />
            <Route
              path="/buyer-choice/quick"
              element={<BuyerChoiceFormQuick />}
            />
            <Route path="/buyer-prequal" element={<BuyerQualFormV2 />} />
            <Route
              path="/buyer-prequal/quick"
              element={<BuyerQualFormQuick />}
            />
            <Route path="/closing-cost" element={<ClosingCostForm />} />
            <Route path="/consumer-debt" element={<ConsumerDebtForm />} />
            <Route path="/equity" element={<EquityForm />} />
            <Route path="/investment" element={<InvestmentForm />} />
            <Route path="/invest" element={<InvestmentForm />} />
            <Route path="/rent-or-buy" element={<BuyOrRentForm />} />
            <Route path="/reverse" element={<HecmForm />} />
            <Route path="/seller-net" element={<SellerNetForm />} />
            <Route path="/subsidy" element={<PaymentSubsidyForm />} />
            <Route path="/vacation" element={<VacationFormV2 />} />
          </Route>
          <Route element={<NonHomebuyerRoute redirectRoute="/" />}>
            <Route
              path="/agent-private/:id"
              element={<UserInfoProfile userType="agent" />}
            />
            <Route
              path="/officer-private/:id"
              element={<UserInfoProfile userType="loan officer" />}
            />
          </Route>

          <Route path="/account/:tabName" element={<UserAccount />} />

          <Route element={<AdminRoute redirectRoute="/" />}>
            <Route path="/admin" element={<AdminHomeView />} />
            <Route path="/admin/users" element={<AdminUsersTable />} />
            <Route path="/admin/rates" element={<AdminRatesView />} />
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
