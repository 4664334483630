import React from "react";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { NavItems } from "./NavItems";
import { UsernameToggle } from "./UsernameToggle";

import { menuItemsPropTypes } from "../data";

import logOutIcon from "../../../../assets/images/logout-icon.svg";
import personIcon from "../../../../assets/images/person-icon.svg";

export const DesktopNav = ({ userName, menuItems, currentUser, logOut }) => {
  const {
    isLoading,
    isAuthenticated,
    realtorPhoto,
    shouldCompleteProfile,
    accountType,
  } = currentUser;

  return (
    <>
      <div className="col-lg-auto d-none d-lg-block header__section">
        <nav className="mainmenu">
          <NavItems menuItems={menuItems} />
        </nav>
      </div>{" "}
      <div className="col-lg-auto col-xl-3 d-none d-lg-inline">
        {!isLoading ? (
          isAuthenticated ? (
            <div className="w-auto d-flex align-items-center">
              <Dropdown className="header-dropdown">
                <Dropdown.Toggle
                  imgUrl={realtorPhoto}
                  as={UsernameToggle}
                  showCompleteProfileIcon={shouldCompleteProfile}
                >
                  {userName} ({accountType})
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="header-dropdown__menu">
                  <Dropdown.Item
                    className="header-dropdown__item"
                    as={Link}
                    to="/account/profile"
                  >
                    <img
                      className="header-dropdown__icon"
                      src={personIcon}
                      alt="dropdown icon"
                    />
                    <span>My account</span>
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="header-dropdown__item"
                    onClick={logOut}
                  >
                    <img
                      className="header-dropdown__icon"
                      src={logOutIcon}
                      alt="dropdown icon"
                    />
                    <span>Log out</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <nav>
              <ul className="auth-navigation">
                <li className="auth-navigation__login">
                  <Link to="/login">Log In</Link>
                </li>
                <li className="auth-navigation__signup">
                  <Link to="/signup">Sign Up</Link>
                </li>
              </ul>
            </nav>
          )
        ) : (
          <div style={{ width: "275px" }} />
        )}
      </div>
    </>
  );
};

DesktopNav.propTypes = {
  userName: PropTypes.string,
  menuItems: menuItemsPropTypes,
  currentUser: PropTypes.object,
  logOut: PropTypes.func,
};
