import React, { useContext, useEffect, useState } from "react";

import { UiToast } from "@ask-christee/ui-response";

import { Loader } from "../../atoms";
import { axiosInstance } from "../../../api";
import { AccountDataProviderContext } from "../../../contexts";
import { BILLING_HISTORY, NEXT_INVOICE, PAYMENT_METHOD } from "../../../config";

import { NextInvoice } from "../NextInvoice";
import { PaymentMethod } from "../PaymentMethod";
import { BillingHistory } from "../BillingHistory";

export const UserBilling = () => {
  const { updateAccountData, isDataFetched, accountData, setIsDataFetched } =
    useContext(AccountDataProviderContext);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (
        isDataFetched.paymentDataFetched &&
        isDataFetched.nextInvoiceFetched &&
        isDataFetched.billingHistoryFetched
      ) {
        // Data already fetched, no need to fetch again
        return;
      }

      setIsLoading(true);

      const responses = await Promise.allSettled([
        axiosInstance(PAYMENT_METHOD),
        axiosInstance(NEXT_INVOICE),
        axiosInstance(BILLING_HISTORY),
      ]);

      responses.forEach((result) => {
        // don't need to show error message if response not found
        if (result.reason && result.reason?.response?.status !== 404) {
          UiToast.error(result.reason?.response || "Something went wrong!");
        }
      });

      updateAccountData({
        paymentData: responses[0].value?.data,
        nextInvoice: responses[1].value?.data,
        billingHistory: responses[2].value?.data,
      });

      setIsDataFetched((prevState) => ({
        ...prevState,
        paymentDataFetched: true,
        nextInvoiceFetched: true,
        billingHistoryFetched: true,
      }));

      setIsLoading(false);
    };

    getData();
  }, [
    isDataFetched.billingHistoryFetched,
    isDataFetched.nextInvoiceFetched,
    isDataFetched.paymentDataFetched,
    setIsDataFetched,
    updateAccountData,
  ]);

  if (isLoading) {
    return (
      <div className="w-100 d-flex justify-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <PaymentMethod
        paymentData={accountData?.paymentData}
        updatePaymentData={(newPaymentData) =>
          updateAccountData({ paymentData: newPaymentData })
        }
      />
      {accountData?.nextInvoice && (
        <NextInvoice nextInvoice={accountData?.nextInvoice} />
      )}
      <BillingHistory
        billingHistory={accountData?.billingHistory}
        updateBillingHistory={(newBillingHistory) =>
          updateAccountData({ billingHistory: newBillingHistory })
        }
      />
    </div>
  );
};
