import { useState } from "react";

import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { BaseModal } from "../../atoms";
import { axiosInstance } from "../../../api";
import { PAYMENT_METHOD } from "../../../config";

export const UpdatePaymentMethodModal = ({
  show,
  handleClose,
  updatePaymentData,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);

      event.preventDefault();
      if (!stripe || !elements) return;

      const cardNumberElement = elements.getElement(CardNumberElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      if (error) throw new Error(error.message);

      const response = await axiosInstance.put(PAYMENT_METHOD, {
        paymentMethodId: paymentMethod.id,
      });

      updatePaymentData(response.data);
      UiToast.success("Your payment method successfully updated!");
      handleClose();
    } catch (error) {
      UiToast.error(
        error.response?.data ||
          error.message ||
          "An unexpected error has occurred",
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseModal
      show={show}
      size="md"
      handleClose={handleClose}
      title="Edit Payment Method"
    >
      <form onSubmit={handleSubmit} className="update-payment">
        <div>
          <label className="update-payment__input-label">
            Card number
            <CardNumberElement />
          </label>
        </div>

        <div className="update-payment__details">
          <div>
            <label className="update-payment__input-label">
              Expiration date
              <CardExpiryElement />
            </label>
          </div>

          <div>
            <label className="update-payment__input-label">
              CVC
              <CardCvcElement />
            </label>
          </div>
        </div>
        <div className="update-payment__buttons">
          <Button
            variant="outline-primary"
            className="update-payment__button"
            onClick={handleClose}
          >
            Close
          </Button>
          <ButtonWithSpinner
            spinnerVariant="light"
            variant="primary"
            type="submit"
            className="update-payment__button"
            loading={isLoading}
            loadingIndicator="ADD..."
          >
            Add
          </ButtonWithSpinner>
        </div>
      </form>
    </BaseModal>
  );
};

UpdatePaymentMethodModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  updatePaymentData: PropTypes.func.isRequired,
};
