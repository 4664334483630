import React from "react";

import PropTypes from "prop-types";

import locationIcon from "../../../assets/images/location-icon.svg";

import { ZipCodeLocationInput } from "../../../components/molecules";

import { SectionWrapper } from "./SectionWrapper";

export const LocationHomebuyer = ({
  errors,
  control,
  setValue,
  setIsZipCodeValid,
  formValues,
  trigger,
}) => {
  return (
    <SectionWrapper icon={locationIcon} text="Location">
      <ZipCodeLocationInput
        rules={{
          required: "Required",
        }}
        setFormValue={setValue}
        label="Zip Code"
        name="zipCode"
        errors={errors}
        control={control}
        setIsZipCodeValid={setIsZipCodeValid}
        inputValue={formValues.zipCode}
        trigger={trigger}
      />
    </SectionWrapper>
  );
};

LocationHomebuyer.propTypes = {
  formValues: PropTypes.object.isRequired,
  trigger: PropTypes.func,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setIsZipCodeValid: PropTypes.func.isRequired,
};
