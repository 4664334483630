import React, { useContext, useState } from "react";

import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { useCustomReCaptcha } from "../../hooks";
import { Loader } from "../../components/atoms";

import { CurrentUserProviderContext } from "../../contexts";
import { CustomHookFormInput } from "../../components/molecules";

import LeftArrow from "../../assets/images/left-arrow-blue-icon.svg";

export const OtpVerifyForm = ({ authInfo, setAuthInfo, resetAuthInfo }) => {
  const { verifyRecaptcha } = useCustomReCaptcha("login_re_enter");

  const { email, password, cognitoUser } = authInfo;

  const navigate = useNavigate();
  const { loadCurrentUser } = useContext(CurrentUserProviderContext);

  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // in cognito it's not possible directly resend code, so we need to do re-login
  const resendOtp = async () => {
    if (!isResendLoading) {
      try {
        setIsResendLoading(true);
        const captcha = await verifyRecaptcha();

        const newCognitoUser = await Auth.signIn(email, password, {
          captcha: captcha,
        });

        setAuthInfo((prevState) => ({
          ...prevState,
          cognitoUser: newCognitoUser,
        }));
        UiToast.success("Passcode has been resent!");
      } catch (err) {
        UiToast.error(err.message);
      } finally {
        setIsResendLoading(false);
      }
    }
  };

  const onSubmit = async (submitData) => {
    try {
      if (cognitoUser.challengeName === "CUSTOM_CHALLENGE") {
        setIsVerifyLoading(true);
        const challengeRes = await Auth.sendCustomChallengeAnswer(
          cognitoUser,
          submitData.code,
        );

        if (challengeRes.challengeName) {
          UiToast.error("Invalid one-time passcode");
          setIsVerifyLoading(false);
        } else {
          const currentUser = await loadCurrentUser({ processLogin: true });

          UiToast.success("Success login!");

          navigate(
            currentUser?.shouldCompleteProfile
              ? "/modules/fill-profile-modal"
              : "/modules",
          );
        }
      }
    } catch (err) {
      UiToast.error(err.message);
      setIsVerifyLoading(false);
    }
  };

  return (
    <>
      <h3 className="h3 text-center">Confirm your identity</h3>
      <p className="mt-3">
        Please enter the One-Time Passcode sent to your email address.
      </p>
      <Form className="w-100 mt-4" onSubmit={handleSubmit(onSubmit)}>
        <CustomHookFormInput
          label="One-Time Passcode"
          type="text"
          name="code"
          errors={errors}
          control={control}
          rules={{
            required: "Required",
          }}
        />

        <div
          onClick={resendOtp}
          className="mt-3 link-primary text-decoration-underline cursor-pointer"
        >
          Resend Passcode {isResendLoading && <Loader size="xs" />}
        </div>
        <ButtonWithSpinner
          spinnerVariant="light"
          data-cy="btn-submit"
          data-test="btn-submit"
          variant="primary"
          type="submit"
          className="mt-4 w-100"
          loading={isVerifyLoading}
          loadingIndicator="Verifying..."
        >
          Submit
        </ButtonWithSpinner>
      </Form>
      <div
        onClick={resetAuthInfo}
        className="mt-3 d-flex align-items-center cursor-pointer"
      >
        <img width="20" src={LeftArrow} alt="left arrow " />
        <p className="m-0 text-primary ms-1">Back</p>
      </div>
    </>
  );
};

OtpVerifyForm.propTypes = {
  authInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    cognitoUser: PropTypes.object.isRequired,
  }).isRequired,
  setAuthInfo: PropTypes.func.isRequired,
  resetAuthInfo: PropTypes.func.isRequired,
};
