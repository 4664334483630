import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { UiToast } from "@ask-christee/ui-response";

import { USERS } from "../../config";
import { axiosInstance } from "../../api";
import { PageWrapper } from "../../components/organisms";
import { PageLoader } from "../../components/molecules";

import Hero from "./components/Hero";
import About from "./components/About";
import InfoSection from "./components/InfoSection";
import Information from "./components/Information";

import "./styles.scss";

export const UserInfoProfile = ({ userType }) => {
  const { id } = useParams();

  const [user, setUser] = useState();
  const [isLoadingUser, setIsLoadingUser] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingUser(true);

        const res = await axiosInstance(`${USERS}/${id}`);
        setUser(res.data);
        setIsLoadingUser(false);
      } catch (err) {
        const errorMessage = err?.response?.data || err.message;
        setIsLoadingUser(false);
        UiToast.error(errorMessage || "Something went wrong!");
      }
    };

    fetchData();
  }, [id, userType]);

  if (isLoadingUser) return <PageLoader />;

  return (
    <PageWrapper className="custom-profile-wrapper">
      <div className="content agent-public-profile">
        <Row className="mx-0">
          <Col lg={7}>
            <Hero
              accountType={user?.accountType}
              firstName={user?.firstName}
              lastName={user?.lastName}
              realtorPhoto={user?.realtorPhoto}
              brokerage={user?.brokerage}
              speciality={user?.speciality}
              specialityMultiple={user?.specialityMultiple}
              financeType={user?.financeType}
            />
            <div className="mt-5">
              <About bio={user?.bio} />
            </div>
            {user?.serviceAreas && (
              <InfoSection
                title="Service Areas"
                items={user.serviceAreas.map(
                  (serviceArea) =>
                    `${serviceArea.area}, ${serviceArea.state.toUpperCase()}`,
                )}
              />
            )}
          </Col>
          <Col lg={5}>
            <Information
              email={user?.email}
              accountType={user?.accountType}
              brokerage={user?.brokerage}
              brokerageZip={user?.brokerageZip}
              primaryPhone={user?.primaryPhone}
              cellPhone={user?.cellA}
              states={user?.states}
              brokerageState={user?.brokerageState}
              licenseNumber={user?.licenseNumber}
              brokerageCity={user?.brokerageCity}
              brokerageAddress={user?.brokerageAddress}
              nmls={user?.nmls}
            />
          </Col>
        </Row>
      </div>
    </PageWrapper>
  );
};

UserInfoProfile.propTypes = {
  userType: PropTypes.oneOf(["agent", "loan officer"]),
};
