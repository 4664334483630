import { useEffect } from "react";

import { axiosInstance } from "../api";
import { HOLD_SUB_ITEMS } from "../config";

export const useHoldSubscriptionItems = ({
  singleSubscriptionItem,
  subscriptionItems,
  itemsType,
}) => {
  useEffect(() => {
    const holdItemsRequest = async () => {
      const itemsToHold = subscriptionItems?.length
        ? subscriptionItems
        : [singleSubscriptionItem];

      try {
        await axiosInstance.patch(HOLD_SUB_ITEMS, {
          [itemsType]: itemsToHold,
        });
      } catch (error) {
        console.error("Failed to hold items:", error);
      }
    };

    let intervalId;
    if (!!subscriptionItems?.length || singleSubscriptionItem) {
      // Send an initial request immediately if there are items
      holdItemsRequest();

      // make hold request every 1 minute to keep this item on hold when selected (so no other user can take it)
      intervalId = setInterval(holdItemsRequest, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [itemsType, singleSubscriptionItem, subscriptionItems]);
};
