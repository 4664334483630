import React, { useContext } from "react";

import { useParams, useNavigate, Navigate } from "react-router-dom";

import {
  AccountDataProvider,
  CurrentUserProviderContext,
} from "../../contexts";
import { CustomTab, CustomTabs } from "../../components/molecules";
import {
  Profile,
  UserBilling,
  PageWrapper,
  UserSubscription,
} from "../../components/organisms";

import "./styles.scss";

const tabsConfig = [
  { title: "Profile", key: "profile" },
  { title: "Billing", key: "billing" },
  { title: "Subscription", key: "subscription" },
];

export const UserAccount = () => {
  const { currentUser } = useContext(CurrentUserProviderContext);

  const { tabName } = useParams();
  const navigate = useNavigate();

  const isTabInConfig = tabsConfig.some((tab) => tab.key === tabName);

  if (!isTabInConfig) {
    return <Navigate to="/account/profile" />;
  }

  const onTabSelect = (tabKey) => {
    navigate(`/account/${tabKey}`);
  };

  const isHomebuyer = currentUser.accountType === "homebuyer";

  return (
    <PageWrapper>
      <AccountDataProvider>
        <div className="account-content">
          <h2>My Account</h2>

          <div className="mt-4">
            <CustomTabs onSelect={onTabSelect} activeKey={tabName}>
              <CustomTab
                title={tabsConfig[0].title}
                eventKey={tabsConfig[0].key}
                mountOnEnter
                unmountOnExit
              >
                <Profile />
              </CustomTab>
              {!isHomebuyer && (
                <CustomTab
                  title={tabsConfig[1].title}
                  eventKey={tabsConfig[1].key}
                  mountOnEnter
                  unmountOnExit
                >
                  <UserBilling />
                </CustomTab>
              )}
              <CustomTab
                title={tabsConfig[2].title}
                eventKey={tabsConfig[2].key}
                mountOnEnter
                unmountOnExit
              >
                <UserSubscription />
              </CustomTab>
            </CustomTabs>
          </div>
        </div>
      </AccountDataProvider>
    </PageWrapper>
  );
};
