import "./styles.scss";
import PropTypes from "prop-types";

export const Discount = ({ price, discount, className }) => (
  <div className={`discount ${className || ""}`}>
    {discount.percentOff ? (
      <span className="discount__item discount__percent-off">
        -{discount.percentOff}%
      </span>
    ) : (
      ""
    )}
    <span className="discount__item discount__old-price">${price}/mo</span>
  </div>
);

Discount.propTypes = {
  price: PropTypes.number.isRequired,
  discount: PropTypes.shape({
    percentOff: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
};
