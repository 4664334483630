import React from "react";

import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";

export const TableDropdownFilter = ({
  filterTitle,
  currentFilter,
  onFilterChange,
  filterOptions,
  filterKey,
}) => {
  return (
    <Dropdown className="users-table-dropdown">
      <Dropdown.Toggle
        className="users-table-dropdown__toggle"
        variant="outline-primary"
        id="dropdown-basic"
      >
        <span className="users-table-dropdown__filter-title">
          {filterTitle}:
        </span>
        <span className="users-table-dropdown__active-filter">
          {currentFilter}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="users-table-dropdown__menu">
        {filterOptions.map((option) => (
          <Dropdown.Item
            key={option.value}
            className={`${currentFilter === option.value ? "fw-bold" : ""}`}
            onClick={() => onFilterChange(filterKey, option.value)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

TableDropdownFilter.propTypes = {
  filterTitle: PropTypes.string.isRequired,
  currentFilter: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  filterKey: PropTypes.string.isRequired,
};
