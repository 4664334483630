import { CustomBillingTable } from "../../organisms";

import { formatPrice } from "../../../utils";
import { getConfigForInvoiceType, prepareRecords } from "./helpers";

export const InvoiceTable = ({ invoiceData }) => {
  const config = getConfigForInvoiceType(invoiceData.type);
  const recordsToRender = prepareRecords({ invoiceData, config });

  return (
    <CustomBillingTable
      records={recordsToRender}
      columns={config.columns}
      specialLastRow={["Total", formatPrice(invoiceData.total)]}
    />
  );
};
