export const signupOptions = [
  {
    title: "Homebuyer",
    description:
      "Homebuyers can browse various resources and tools to make informed decisions when purchasing a home.",
    link: "/select-subscription/homebuyer",
  },
  {
    title: "Agent",
    description:
      "Access tools to support your clients in their home selection process and provide them with expert financial advice.",
    link: "/select-subscription/agent",
  },
  {
    title: "Loan officer",
    description:
      "For finance professionals specializing in lending. Assist clients in best financial option for their home purchase.",
    link: "/select-subscription/loan-officer",
  },
];
