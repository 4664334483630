import PropTypes from "prop-types";

export const TopLabel = ({ premium }) => (
  <div
    className={`custom-card__top-label ${
      premium
        ? "custom-card__top-label--premium"
        : "custom-card__top-label--basic"
    }`}
  >
    {premium ? "Premium" : "Basic"}
  </div>
);

TopLabel.propTypes = {
  premium: PropTypes.bool.isRequired,
};
