import { subscriptionNames } from "../common";

import { trimData } from "./trim-data";

export const configureCognitoParams = ({
  submitData,
  accountType,
  isPremium,
  captcha,
}) => {
  const baseAttributes = {
    email: submitData.email,
    "custom:firstName": submitData.firstName,
    "custom:lastName": submitData.lastName,
    "custom:accountType": accountType,
    "custom:phone": submitData.phone,
  };

  let extraAttributes = {};
  if (accountType === "agent") {
    extraAttributes = {
      "custom:license": submitData.license,
      "custom:subscriptionZipCode": submitData.zipCode,
      "custom:subscriptionType": isPremium
        ? subscriptionNames.agent.premium
        : subscriptionNames.agent.basic,
    };
  } else if (accountType === "loan officer") {
    extraAttributes = {
      "custom:nmls": submitData.nmls,
      "custom:subscriptionState": submitData.state,
      "custom:subscriptionType": isPremium
        ? subscriptionNames["loan officer"].premium
        : subscriptionNames["loan officer"].basic,
    };
  } else if (accountType === "homebuyer") {
    extraAttributes = {
      "custom:zipCode": submitData.zipCode,
      "custom:state": submitData.state,
      "custom:area": submitData.area,
      "custom:subscriptionType": isPremium
        ? subscriptionNames["homebuyer"].premium
        : subscriptionNames["homebuyer"].basic,
    };
  }

  return trimData({
    username: submitData.email,
    password: submitData.password,
    attributes: { ...baseAttributes, ...extraAttributes },
    clientMetadata: { captcha },
  });
};
