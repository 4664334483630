import React, { useState } from "react";

import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { InputGroup } from "react-bootstrap";

import {
  CustomInputGroup,
  CustomFormGroup,
  CustomFormControl,
} from "../../atoms";

import eyeIcon from "../../../assets/images/eye-icon.svg";
import eyeClosedIcon from "../../../assets/images/eye-closed-icon.svg";

import "./index.scss";

export const PasswordInput = ({ rules, control, errors, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  return (
    <Controller
      name="password"
      rules={rules}
      control={control}
      render={({ field: { onChange, value = "", ref } }) => (
        <CustomFormGroup
          {...props}
          errors={errors}
          name="password"
          groupClassName="password-input"
        >
          <CustomInputGroup name="password" errors={errors}>
            <CustomFormControl
              errors={errors}
              {...props}
              forwardedRef={ref}
              name="password"
              type={isVisible ? "text" : "password"}
              onChange={onChange}
              value={value}
            />
            <InputGroup.Text className="password-input__icon">
              <div onClick={toggleVisibility}>
                <img src={isVisible ? eyeIcon : eyeClosedIcon} alt="eye-icon" />
              </div>
            </InputGroup.Text>
          </CustomInputGroup>
        </CustomFormGroup>
      )}
    />
  );
};

PasswordInput.propTypes = {
  rules: PropTypes.object,
  control: PropTypes.object.isRequired,
  fieldRequirements: PropTypes.string,
  value: PropTypes.any,
  errors: PropTypes.object,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  groupClassName: PropTypes.string,
};
