export const camelizeKeys = (data) => {
  if (Array.isArray(data)) {
    return data.map(camelizeKeys);
  } else if (typeof data === "object" && data !== null) {
    const camelizedObject = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const camelKey = key.replace(/_([a-z0-9])/g, (match, char) =>
          char.toUpperCase(),
        );
        camelizedObject[camelKey] = camelizeKeys(data[key]);
      }
    }
    return camelizedObject;
  } else {
    return data;
  }
};

export const snakifyString = (string) =>
  ["createdAt", "updatedAt"].includes(string)
    ? string
    : string.replace(
        /([a-z])([A-Z0-9])/g,
        (match, firstChar, secondChar) =>
          `${firstChar}_${secondChar.toLowerCase()}`,
      );

export const snakifyKeys = (data) => {
  if (Array.isArray(data)) {
    return data.map(snakifyKeys);
  } else if (typeof data === "object" && data !== null) {
    const snakeCasedObject = {};
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const snakeKey = snakifyString(key);
        snakeCasedObject[snakeKey] = snakifyKeys(data[key]);
      }
    }
    return snakeCasedObject;
  } else {
    return data;
  }
};
