export const STAGE = process.env.REACT_APP_API_STAGE;

console.log("STAGE:", STAGE);
console.log("config v0.0.6");

export let LANDING_URL;
export let APP_DOMAIN;
export let CONTACT_API;
export let SEND_PDF;
export let DOWNLOAD_PDF;
export let EXECUTE_ID;
export let INTEREST_RATES;
export let UPLOAD_IMAGE;
export let ROOT_API;
export let WEBSITE_URL;
export let PURCHASE_ITEMS_URL;

// cognito config
export let COGNITO_REGION;
export let CLIENT_ID;
export let IDENTITY_POOL_ID;
export let COGNITO_USER_POOL;
export let AUTH_DOMAIN;

// stripe
export let STRIPE_PK;

if (STAGE === "prod") {
  LANDING_URL = "https://askchristee.com";

  EXECUTE_ID = "ju58u19x02";
  ROOT_API = "https://taci4cytu6.execute-api.us-east-1.amazonaws.com/prod";
  INTEREST_RATES = `${ROOT_API}/rates/64759bee65094e9b3c0c2f0b`;

  SEND_PDF =
    "https://qeyttz7572.execute-api.us-east-1.amazonaws.com/prod/send-pdf";
  DOWNLOAD_PDF =
    "https://qeyttz7572.execute-api.us-east-1.amazonaws.com/prod/pdf-download";
  CONTACT_API = "https://4cw19887lb.execute-api.us-east-1.amazonaws.com/prod";
  UPLOAD_IMAGE =
    "https://ecc7t5bnv0.execute-api.us-east-1.amazonaws.com/prod/signed-url";

  // cognito config
  COGNITO_REGION = "us-east-1";
  CLIENT_ID = "54tupk7gf8ddg5gnhtn70tv7dh";
  IDENTITY_POOL_ID = "us-east-1:4707e5bf-9c28-4030-86c0-7ab2d35ed72d";
  COGNITO_USER_POOL = "us-east-1_5VvRqdD4S";
  AUTH_DOMAIN = "askchristee.auth.us-east-1.amazoncognito.com";
  APP_DOMAIN = ".askchristee.com";
  WEBSITE_URL = `https://modules${APP_DOMAIN}`;

  PURCHASE_ITEMS_URL =
    "https://oyn7t7lvk7.execute-api.us-east-1.amazonaws.com/prod";
  STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
} else if (STAGE === "stage") {
  LANDING_URL = "https://staging.askchristee.com";

  EXECUTE_ID = "74wt4u92y2";
  ROOT_API = "https://wwon6337gj.execute-api.us-east-1.amazonaws.com/stage";
  INTEREST_RATES = `${ROOT_API}/rates/64707549a6d12a1616a3c82e`;
  SEND_PDF =
    "https://eneuil4lol.execute-api.us-east-1.amazonaws.com/stage/send-pdf";
  DOWNLOAD_PDF =
    "https://eneuil4lol.execute-api.us-east-1.amazonaws.com/stage/pdf-download";
  CONTACT_API = "https://8gy1n6xhik.execute-api.us-east-1.amazonaws.com/stage";
  UPLOAD_IMAGE =
    "https://3pff7f7rfk.execute-api.us-east-1.amazonaws.com/stage/signed-url";

  // cognito config
  COGNITO_REGION = "us-east-1";
  CLIENT_ID = "58iq4voalc1jsuomk9b5i0emnk";
  IDENTITY_POOL_ID = "us-east-1:1041993d-73df-4a34-abbd-c239512adcdf";
  COGNITO_USER_POOL = "us-east-1_Hmqocc5XY";
  AUTH_DOMAIN = "askchristee-stage.auth.us-east-1.amazoncognito.com";
  APP_DOMAIN = ".askchristee.com";
  WEBSITE_URL = `https://staging-modules${APP_DOMAIN}`;

  PURCHASE_ITEMS_URL =
    "https://g2qsabswxf.execute-api.us-east-1.amazonaws.com/stage";
  STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
} else {
  LANDING_URL = "https://staging.askchristee.com";

  EXECUTE_ID = "na9nf69tve";
  ROOT_API = "https://sm9qx9zl87.execute-api.us-east-1.amazonaws.com/dev";
  INTEREST_RATES = `${ROOT_API}/rates/64590c89874919bbf5cb2f05`;

  SEND_PDF =
    "https://89pne16fje.execute-api.us-east-1.amazonaws.com/dev/send-pdf";
  DOWNLOAD_PDF =
    "https://89pne16fje.execute-api.us-east-1.amazonaws.com/dev/pdf-download";
  CONTACT_API = "https://ah0e7114zi.execute-api.us-east-1.amazonaws.com/dev";
  UPLOAD_IMAGE =
    "https://bbhagpiznf.execute-api.us-east-1.amazonaws.com/dev/signed-url";

  // cognito config
  COGNITO_REGION = "us-east-1";
  CLIENT_ID = "45d2tuo7qm31cjk5l8l40jr885";
  IDENTITY_POOL_ID = "us-east-1:1d4d29ab-6d93-4742-b5e8-8cc48d8c9776";
  COGNITO_USER_POOL = "us-east-1_BeXWf6uUk";
  AUTH_DOMAIN = "askchristee-dev.auth.us-east-1.amazoncognito.com";
  WEBSITE_URL = "https://dev.askchristee.com/";

  PURCHASE_ITEMS_URL =
    "https://edpu0ej56a.execute-api.us-east-1.amazonaws.com/dev";
  STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
}

export const REGION = "api.askchristee.com";
export const API = "https://" + REGION + "/" + STAGE;
export const AMORTIZATION = `${API}/engine/amortization`;
export const BUYERS_CHOICE = `${API}/engine/buyer-choice`;
export const BUYERS_QUAL = `${API}/engine/buyer-qual`;
export const BUY_WAIT = `${API}/engine/buy-wait`;
export const ROB = `${API}/engine/buy-rent`;
export const HECM = `${API}/engine/hecm`;
export const BUDGET = `${API}/engine/budget`;
export const INVEST = `${API}/engine/invest`;
export const SELLER_NET = `${API}/engine/seller-net`;
export const VACATION = `${API}/engine/vacation`;
export const CLOSING_COST = `${API}/engine/closing-cost`;
export const EQUITY = `${API}/engine/equity`;
export const PAYMENT_SUBSIDY = `${API}/engine/payment-subsidy`;
export const CONSUMER_DEBT = `${API}/engine/consumer-debt`;
export const ENGINE = `${API}/db/engines`;
export const CMS = "https://christy-cms.s3.amazonaws.com";
export const HELP = "https://christee-help.s3.amazonaws.com";

// help endpoints
export const HELPTCB =
  "https://christee-static-website-001.s3.amazonaws.com/cms/tcb";
export const hAMORTIZATION = HELPTCB + "/quickstart/amortization.html";
export const hBC = HELPTCB + "/quickstart/bc.html";
export const hBQ = HELPTCB + "/quickstart/bq.html";
export const hBW = HELPTCB + "/quickstart/bw.html";
export const hREVERSE = HELPTCB + "/quickstart/reverse.html";
export const hROB = HELPTCB + "/quickstart/rob.html";
export const hBUDGET = HELPTCB + "/quickstart/budget.html";
export const hINVEST = HELPTCB + "/quickstart/invest.html";
export const hTerms = HELP + "/terms.md";
export const hHome = HELP + "/index.md";
export const hFaq =
  "https://christee-static-website-001.s3.amazonaws.com/cms/shared/faq.json";

// Google analytics
export const APP_VERSION = "1.20";
export const DEBUG = false;

// users
export const ADMIN_APPROVE = `${ROOT_API}/users/approve`;
export const USERS = `${ROOT_API}/users`;
export const CURRENT_USER = `${USERS}/me`;
export const REFERRAL = `${USERS}/me/referral`;
export const AGENTS_LO_RECOMMENDATIONS = `${ROOT_API}/recommendations`;
export const ADMIN_HOMEBUYERS = `${ROOT_API}/admin/homebuyers`;
export const ADMIN_USERS = `${ROOT_API}/admin/users`;
export const ADMIN_USERS_CSV = `${ROOT_API}/admin/download-csv`;

// contact
export const LEAD_GENERATION = `${CONTACT_API}/contact/lead`;
export const CONTACT_US = `${CONTACT_API}/contact/ask`;
export const CONTACT_OFFICER_ZIP = `${CONTACT_API}/contact/officer/zip`;
export const CONTACT_AGENT_ZIP = `${CONTACT_API}/contact/agent/zip`;
export const PRICING_REQUEST = `https://owgmz7shc8.execute-api.us-east-1.amazonaws.com/dev/pricing`; // Point to mongo
export const CONTACT_AGENT = `${CONTACT_API}/contact/agent`;
export const ADMIN_EMAIL = `${CONTACT_API}/db/mail`;

// purchase validators
export const ZIP_CODE_VALIDATOR = `${PURCHASE_ITEMS_URL}/zip-code-validate`;
export const STATE_VALIDATOR = `${PURCHASE_ITEMS_URL}/state-validate`;
export const ZIP_LOCATION = `${PURCHASE_ITEMS_URL}/zip-code-location`;

// payments
export const PAYMENTS = `${ROOT_API}/payments`;
export const CHECKOUT_SESSION = `${PAYMENTS}/create-checkout-session`;
export const RE_ENTER_PURCHASE_ITEM = `${PAYMENTS}/re-submit-purchase-item`;
export const RENEW_CHECKOUT_SESSION = `${PAYMENTS}/renew-subscription`;
export const PAYMENT_METHOD = `${PAYMENTS}/payment-method`;
export const BILLING_HISTORY = `${PAYMENTS}/billing-history`;
export const NEXT_INVOICE = `${PAYMENTS}/next-invoice`;

// subscriptions
export const SUBSCRIPTIONS = `${ROOT_API}/subscriptions`;
export const SUBSCRIPTION_ITEMS = `${SUBSCRIPTIONS}/subscription-items`;
export const HOLD_SUB_ITEMS = `${SUBSCRIPTIONS}/hold`;
export const DOWNGRADE_SUBSCRIPTION = `${SUBSCRIPTIONS}/downgrade`;
export const UPGRADE_SUBSCRIPTION = `${SUBSCRIPTIONS}/upgrade`;
export const LATEST_SUBSCRIPTION_ITEM_DATE = `${SUBSCRIPTIONS}/latest-end-date`;
export const SUBSCRIPTIONS_PRICES = `${SUBSCRIPTIONS}/prices`;
