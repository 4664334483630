import PropTypes from "prop-types";

export const Feature = ({ included, text }) => (
  <li
    className={`custom-card__feature ${
      included
        ? "custom-card__feature--included"
        : "custom-card__feature--excluded"
    }`}
  >
    {text}
  </li>
);

Feature.propTypes = {
  included: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
