import React from "react";

import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import { resolvePath } from "../../../utils";

import "./styles.scss";

export const CustomCheckbox = ({
  name,
  forwardRef,
  onChange,
  value,
  label,
  errors,
}) => {
  const checkboxError = resolvePath(name, errors);

  return (
    <Form.Group>
      <Form.Check
        ref={forwardRef}
        onChange={onChange}
        value={!!value}
        className="custom-checkbox"
        id={name}
        name={name}
        type="checkbox"
        label={label}
        isInvalid={!!checkboxError}
      />
      <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
        {checkboxError?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  forwardRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errors: PropTypes.object,
};
