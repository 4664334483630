import { Button } from "react-bootstrap";
import { ButtonWithSpinner } from "@ask-christee/ui-response";
import React from "react";
import { SectionWrapper } from "./SectionWrapper";

export const Buttons = ({ isLoading, navigate }) => {
  return (
    <SectionWrapper>
      <div className="signup-section__submit-buttons">
        <Button
          variant="outline-primary"
          className="signup-section__submit-button"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <ButtonWithSpinner
          spinnerVariant="light"
          variant="primary"
          type="submit"
          className="signup-section__submit-button"
          loading={isLoading}
          loadingIndicator="Sign Up..."
        >
          Sign Up
        </ButtonWithSpinner>
      </div>
    </SectionWrapper>
  );
};
