import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";

import { subItemPropTypes } from "../data";

export const DropdownItem = ({ subItem, index, length }) => (
  <>
    <NavDropdown.Item
      className="menu__dropdown-item"
      as={subItem.link ? undefined : Link}
      href={subItem.link}
      to={subItem.appLink}
    >
      {subItem.name}
    </NavDropdown.Item>
    {index !== length - 1 && <NavDropdown.Divider />}
  </>
);

DropdownItem.propTypes = {
  subItem: subItemPropTypes,
  index: PropTypes.number,
  length: PropTypes.number,
};
