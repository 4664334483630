import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import PlusIcon from "../../../assets/images/plus-icon.svg";

export const AddButton = ({ disabled, onClick, title }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    className="d-flex align-items-center"
  >
    <img src={PlusIcon} alt="plus" />
    <span className="ms-2">{title}</span>
  </Button>
);

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
