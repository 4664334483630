import React, { useContext, useState } from "react";

import PropTypes from "prop-types";
import { UiToast } from "@ask-christee/ui-response";

import { AddButton } from "../../atoms";
import { AddStatesModal } from "../AddStatesModal";
import { AccountDataProviderContext } from "../../../contexts";

export const AddStates = ({ disabled }) => {
  const { accountData, updateAccountData, setIsDataFetched } = useContext(
    AccountDataProviderContext,
  );
  const [showModal, setShowModal] = useState(false);

  const onSuccessSubmit = (response) => {
    updateAccountData({
      subscriptionItems: [...accountData.subscriptionItems, ...response.data],
    });
    setIsDataFetched((prevState) => ({
      ...prevState,
      billingHistoryFetched: false,
    }));

    UiToast.success("New state(s) successfully added!");
  };

  return (
    <>
      <AddButton
        title="Add state"
        disabled={disabled}
        onClick={() => setShowModal(true)}
      />
      <AddStatesModal
        title="Add state"
        showModal={showModal}
        onSuccessSubmit={onSuccessSubmit}
        handleCloseModal={() => setShowModal(false)}
      />
    </>
  );
};

AddStates.propTypes = {
  disabled: PropTypes.bool,
};
