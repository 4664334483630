import React, { useCallback, useContext, useState } from "react";

import PropTypes from "prop-types";
import { UiToast } from "@ask-christee/ui-response";

import {
  ActionModal,
  VerifyZipCode,
  PaymentSummary,
  ItemsToPurchaseSection,
  PurchaseItem,
} from "../../molecules";
import { axiosInstance } from "../../../api";
import { SUBSCRIPTION_ITEMS } from "../../../config";
import { SubscriptionItemForm } from "../SubscriptionItemForm";
import { SubscriptionPricesProviderContext } from "../../../contexts";
import { useHoldSubscriptionItems } from "../../../hooks";

export const AddZipCodesModal = ({
  showModal,
  handleCloseModal,
  text,
  title,
  onSuccessSubmit,
  submitUrl = SUBSCRIPTION_ITEMS,
  maxItems = 10,
  isSubsequentPrice = true,
  submitMethod = "POST",
}) => {
  const { getPriceByType } = useContext(SubscriptionPricesProviderContext);

  const [zipCodes, setZipCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useHoldSubscriptionItems({
    itemsType: "zipCodes",
    subscriptionItems: zipCodes?.map((zipCode) => zipCode.code),
  });

  const onCloseModal = () => {
    handleCloseModal();

    setTimeout(() => {
      setZipCodes([]);
    }, 300);
  };

  const handleAddZipCode = useCallback(({ itemInfo }) => {
    setZipCodes((prev) => [...prev, itemInfo]);
  }, []);

  const handleRemoveZipCode = (index) => {
    setZipCodes((prev) => prev.filter((_, i) => i !== index));
  };

  const purchaseZipCodes = async () => {
    if (!zipCodes.length) {
      UiToast.error("Enter at least one zip code to purchase");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axiosInstance({
        url: submitUrl,
        method: submitMethod,
        data: {
          items: zipCodes.map((zipCode) => zipCode.code),
        },
      });

      if (response) {
        onSuccessSubmit(response);
      }
    } catch (error) {
      UiToast.error(error.response.data || "Something went wrong!");
    } finally {
      setIsLoading(false);
      onCloseModal();
    }
  };

  const zipCodePrice = getPriceByType({
    subscriptionType: "agent_zip_code",
    isSubsequent: isSubsequentPrice,
  })?.amount;

  return (
    <ActionModal
      isLoading={isLoading}
      actionLabel="Pay"
      title={title}
      showModal={showModal}
      onActionClick={purchaseZipCodes}
      closeModal={handleCloseModal}
    >
      {text && <p style={{ fontSize: "14px", textAlign: "justify" }}>{text}</p>}
      <SubscriptionItemForm
        onSubmit={handleAddZipCode}
        onRemove={handleRemoveZipCode}
        items={zipCodes}
        displayFieldName="code"
        fieldName="zipCode"
        label="zip code"
        maxItems={maxItems}
        VerifyItemComponent={VerifyZipCode}
      />

      {!!zipCodes.length && (
        <ItemsToPurchaseSection className="mt-2">
          {zipCodes.map((zipCode, index) => (
            <PurchaseItem
              key={zipCode.code}
              value={zipCode.code}
              descriptionItems={[
                `City: ${zipCode.city}`,
                `County: ${zipCode.area}`,
                `State: ${zipCode.state}`,
              ]}
              onRemoveItem={() => handleRemoveZipCode(index)}
              purchaseItemName="Zip Code"
            />
          ))}
        </ItemsToPurchaseSection>
      )}
      {zipCodePrice && (
        <PaymentSummary
          title="Zip Code"
          className="mt-3"
          priceItems={zipCodes?.map((zipCode) => ({
            value: zipCode.code,
            price: zipCodePrice,
          }))}
        />
      )}
    </ActionModal>
  );
};

AddZipCodesModal.propType = {
  onSuccessSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  submitMethod: PropTypes.string,
  title: PropTypes.string.isRequired,
  isSubsequentPrice: PropTypes.bool,
  maxItems: PropTypes.number,
  text: PropTypes.string,
};
