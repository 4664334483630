import React, { useState } from "react";

import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { useCustomReCaptcha } from "../../hooks";
import { AuthCover } from "../../components/organisms";
import { configureCognitoParams } from "../../utils";

import { signupContentConfig } from "./config";
import {
  Password,
  Agreements,
  LocationHomebuyer,
  PersonalInformation,
} from "./sections";

import "./index.scss";

export const HomebuyerSignup = ({ isPremium = false }) => {
  const { verifyRecaptcha } = useCustomReCaptcha("signup_homebuyer");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isZipCodeValid, setIsZipCodeValid] = useState(false);

  const subscriptionType = isPremium ? "homebuyer_premium" : "homebuyer_basic";
  const contentState = signupContentConfig[subscriptionType];

  const {
    control,
    trigger,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  const onSubmit = async (submitData) => {
    if (!isZipCodeValid) {
      return UiToast.error("Please verify your zip code");
    }

    try {
      setIsLoading(true);

      const captcha = await verifyRecaptcha();

      const params = configureCognitoParams({
        isPremium,
        submitData,
        accountType: "homebuyer",
        captcha,
      });

      await Auth.signUp(params);

      UiToast.success("Success Sign up! Please confirm your email");
      setIsLoading(false);

      navigate("/onboarding");
    } catch (error) {
      // get rid of prefix that comes from cognito
      const errorMessage = error.message.replace(
        "PreSignUp failed with error ",
        "",
      );

      setIsLoading(false);
      UiToast.error(errorMessage);
    }
  };
  ``;
  const agreementPoints = [
    {
      name: "subscriptionAgreement",
      label: "I am not a real estate agent or loan officer.",
    },
  ];

  return (
    <AuthCover title={contentState.title}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation control={control} errors={errors} />

        <LocationHomebuyer
          errors={errors}
          control={control}
          setIsZipCodeValid={setIsZipCodeValid}
          formValues={formValues}
          setValue={setValue}
          trigger={trigger}
        />

        <Password errors={errors} control={control} />

        <Agreements
          agreementPoints={agreementPoints}
          errors={errors}
          control={control}
        />

        <div className="d-flex justify-content-between">
          <Button
            variant="outline-primary"
            className="mt-4 px-4 flex-grow-1 me-3"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <ButtonWithSpinner
            variant="primary"
            type="submit"
            className="mt-4 px-4 flex-grow-1 ms-3"
            loading={isLoading}
            loadingIndicator="Sign Up..."
          >
            Sign Up
          </ButtonWithSpinner>
        </div>
      </Form>
    </AuthCover>
  );
};

HomebuyerSignup.propTypes = {
  isPremium: PropTypes.bool,
};
