import React, { useState } from "react";

import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UiToast } from "@ask-christee/ui-response";

import { useCustomReCaptcha } from "../../hooks";
import { configureCognitoParams } from "../../utils";
import { AuthCover } from "../../components/organisms";

import {
  Agreements,
  Buttons,
  Password,
  PersonalInformation,
  WorkDetailsOfficer,
} from "./sections";
import { signupContentConfig } from "./config";

import "./index.scss";

export const LoanOfficerSignup = ({ isPremium = false }) => {
  const { verifyRecaptcha } = useCustomReCaptcha("signup_officer");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isStateValid, setIsStateValid] = useState(false);

  const subscriptionType = isPremium
    ? "loan_officer_state"
    : "loan_officer_basic";

  const contentState = signupContentConfig[subscriptionType];

  const {
    control,
    trigger,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  const onSubmit = async (submitData) => {
    if (isPremium && !isStateValid) {
      return UiToast.error("Please validate your state");
    }

    try {
      setIsLoading(true);

      const captcha = await verifyRecaptcha();

      const params = configureCognitoParams({
        isPremium,
        submitData,
        accountType: "loan officer",
        captcha,
      });

      await Auth.signUp(params);

      UiToast.success("Success Sign up! Please confirm your email");
      setIsLoading(false);
      navigate("/onboarding");
    } catch (error) {
      // get rid of prefix that comes from cognito
      const errorMessage = error.message.replace(
        "PreSignUp failed with error ",
        "",
      );

      setIsLoading(false);
      UiToast.error(errorMessage);
    }
  };

  return (
    <AuthCover title={contentState.title}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInformation control={control} errors={errors} />

        <WorkDetailsOfficer
          formValues={formValues}
          trigger={trigger}
          errors={errors}
          control={control}
          isPremium={isPremium}
          setIsStateValid={setIsStateValid}
        />

        <Password errors={errors} control={control} />

        <Agreements errors={errors} control={control} />

        <Buttons isLoading={isLoading} navigate={navigate} />
      </Form>
    </AuthCover>
  );
};

LoanOfficerSignup.propTypes = {
  isPremium: PropTypes.bool,
};
