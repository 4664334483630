import { useContext, useState } from "react";

import PropTypes from "prop-types";
import { UiToast } from "@ask-christee/ui-response";

import {
  AccountDataProviderContext,
  CurrentUserProviderContext,
} from "../../../contexts";
import { axiosInstance } from "../../../api";
import { formatDate } from "../../../utils";
import { DOWNGRADE_SUBSCRIPTION } from "../../../config";
import { ActionModal, SubscriptionCard } from "../../molecules";

export const SubscriptionToDowngrade = ({ subscriptionStatus, ...props }) => {
  const { currentUser, updateCurrentUser } = useContext(
    CurrentUserProviderContext,
  );
  const { setIsDataFetched, accountData } = useContext(
    AccountDataProviderContext,
  );

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDowngrade = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.patch(DOWNGRADE_SUBSCRIPTION);

      if (response) {
        updateCurrentUser({
          ...currentUser,
          subscriptionStatus: "pending downgrade",
        });
        setIsDataFetched((prevState) => ({
          ...prevState,
          nextInvoiceFetched: false,
          subscriptionItemsFetched: false,
        }));

        UiToast.success("Subscription downgrade process has been started");
      }
    } catch (error) {
      UiToast.error(error.response.data || "Something went wrong!");
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  return (
    <>
      <SubscriptionCard
        disabled={subscriptionStatus !== "active"}
        buttonText={
          subscriptionStatus === "pending downgrade" ? "Pending" : "Downgrade"
        }
        buttonVariant="primary"
        onButtonClick={() => setShowModal(true)}
        {...props}
      />
      <ActionModal
        isLoading={isLoading}
        showModal={showModal}
        actionLabel="downgrade"
        onActionClick={handleDowngrade}
        closeModal={() => setShowModal(false)}
        title="Are you sure you want to downgrade?"
      >
        By downgrading now, you'll still be able to use your subscription until
        the end of the paid period{" "}
        {formatDate(accountData?.subscriptionLatestEndDate)}.
      </ActionModal>
    </>
  );
};

SubscriptionToDowngrade.propTypes = {
  subscriptionStatus: PropTypes.string.isRequired,
};
