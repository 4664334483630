import React from "react";

import { ButtonCard } from "../../components/molecules";
import { SignupSelectFlowWrapper } from "../../components/organisms";

import { signupOptions } from "./data";

import "./styles.scss";
import { useNavigate } from "react-router-dom";

export const SelectAccountType = () => {
  const navigate = useNavigate();

  return (
    <SignupSelectFlowWrapper title="Select Account Type">
      <div className="account-cards">
        {signupOptions.map((option, i) => (
          <div key={i} className="account-card">
            <ButtonCard
              buttonText="choose"
              onButtonClick={() => navigate(option.link)}
              {...option}
            />
          </div>
        ))}
      </div>
    </SignupSelectFlowWrapper>
  );
};
