import { CustomBillingTable } from "../../organisms";

import { subscriptionPlans } from "../../../shared-data";
import { formatDate, formatPrice } from "../../../utils";

const columnsConfig = [
  {
    field: "paidAt",
    label: "Date",
    format: formatDate,
  },
  {
    field: "type",
    label: "Plan",
    format: (plan) => subscriptionPlans[plan],
  },
  { field: "total", label: "Total", format: formatPrice },
  { field: "special", label: "Invoice" },
];

const expandableColumnsConfig = [
  {
    field: "states",
    columns: [
      { field: "code", label: "State" },
      { field: "price", label: "Price", format: formatPrice },
    ],
  },
  {
    field: "zipCodes",
    columns: [
      { field: "code", label: "Zip Code" },
      { field: "price", label: "Price", format: formatPrice },
    ],
  },
];

export const BillingHistoryTable = ({
  billingHistory,
  changePage,
  direction,
}) => {
  return (
    <CustomBillingTable
      records={billingHistory}
      changePage={changePage}
      paginationDirection={direction}
      columns={columnsConfig}
      expandableColumns={expandableColumnsConfig}
    />
  );
};
