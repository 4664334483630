import React, { useCallback, useContext, useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { UiSettingsProviderContext } from "@ask-christee/ui-response";

import { CURRENT_USER } from "../../config";
import { axiosInstance } from "../../api";

import { transformUserData } from "./transformUserData";
import { useCookie } from "../../hooks";

export const CurrentUserProviderContext = React.createContext({
  currentUser: {},
  updateCurrentUser() {},
});

export const CurrentUserProvider = ({ children }) => {
  const { updateCurrentSettings } = useContext(UiSettingsProviderContext);
  const { setCookie, removeCookie } = useCookie("currentUser");

  const [currentUser, setCurrentUser] = useState({ isLoading: true });

  const updateUiLibSettings = useCallback(
    (userData) => {
      const {
        email,
        firstName,
        lastName,
        accountType,
        isApproved,
        initialCompletion,
        zipCode,
      } = userData;

      updateCurrentSettings({
        name: `${firstName} ${lastName}`,
        email,
        accountType,
        isApproved,
        initialCompletion,
        zipCode: accountType === "homebuyer" && zipCode ? zipCode : null,
      });
    },
    [updateCurrentSettings],
  );

  const updateCurrentUser = useCallback(
    (newUser) => {
      const transformedUserData = transformUserData(newUser);

      updateUiLibSettings(transformedUserData);
      setCurrentUser(transformedUserData);
    },
    [updateUiLibSettings],
  );

  const loadCurrentUser = useCallback(
    async (additionalParams = {}) => {
      try {
        const cognitoUser = await Auth.currentUserInfo();

        if (!cognitoUser) {
          setCurrentUser((prevState) => ({
            ...prevState,
            isLoading: false,
          }));

          return;
        }

        const response = await axiosInstance(CURRENT_USER);
        const userData = response.data;
        const transformedUserData = transformUserData(
          { ...userData, isAuthenticated: true, ...additionalParams },
          cognitoUser,
        );

        updateUiLibSettings(transformedUserData);
        setCurrentUser(transformedUserData);

        return transformedUserData;
      } catch (error) {
        setCurrentUser((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        console.error(error);
      }
    },
    [updateUiLibSettings],
  );

  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  useEffect(() => {
    if (!currentUser.isLoading && currentUser.isAuthenticated) {
      setCookie({
        value: {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          realtorPhoto: currentUser.realtorPhoto,
          accountType: currentUser.accountType,
          shouldCompleteProfile: currentUser.shouldCompleteProfile,
        },
      });
    } else if (!currentUser.isLoading && !currentUser.isAuthenticated) {
      removeCookie();
    }
  }, [currentUser, removeCookie, setCookie]);

  if (process.env.NODE_ENV !== "production") {
    console.log("CURRENT USER: ", currentUser);
  }

  return (
    <CurrentUserProviderContext.Provider
      value={{
        currentUser,
        loadCurrentUser,
        updateCurrentUser,
      }}
    >
      {children}
    </CurrentUserProviderContext.Provider>
  );
};
