import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

import { Loader } from "../../atoms";

import { columnConfig } from "./config";
import { EmptyTableState } from "./EmptyTableState";

export const UsersTable = ({
  data,
  sortOrder,
  isLoading,
  sortField,
  fetchData,
  setSortField,
  setSortOrder,
}) => {
  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const renderSortIndicator = (column) => {
    if (column.dataField === sortField) {
      return sortOrder === "asc" ? " ↑" : " ↓";
    }
    return "";
  };

  return (
    <div className="user-data-section">
      {!!data.length && isLoading && (
        <div className="users-loader-wrapper">
          <Loader />
        </div>
      )}
      <Table className="users-table">
        <thead className="users-table__head">
          <tr className="users-table__row">
            {columnConfig.map((column) => (
              <th
                className={`users-table__head-cell ${
                  column.sort ? "cursor-pointer" : ""
                }`}
                key={column.dataField}
                onClick={() => column.sort && handleSort(column.dataField)}
              >
                {column.text}
                {renderSortIndicator(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="users-table__body">
          {!!data?.length ? (
            data.map((row) => (
              <tr key={row.Id} className="users-table__row">
                {columnConfig.map((column) => (
                  <td
                    className="users-table__cell"
                    key={column.dataField + row.Id}
                  >
                    {column.formatter
                      ? column.formatter(row[column.dataField], row, fetchData)
                      : row[column.dataField] || "—"}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <EmptyTableState isLoading={isLoading} />
          )}
        </tbody>
      </Table>
    </div>
  );
};

UsersTable.propTypes = {
  data: PropTypes.array.isRequired,
  sortOrder: PropTypes.oneOf(["asc", "desc"]).isRequired,
  isLoading: PropTypes.bool.isRequired,
  sortField: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  setSortField: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
};
