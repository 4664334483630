import React from "react";

import PropTypes from "prop-types";

import { FormMessage } from "../../atoms";

export const Messages = ({ showInfoMessages, zipError, zipCodeInfo }) => {
  return (
    <>
      {showInfoMessages && !zipError && !zipCodeInfo && (
        <FormMessage status="info" text="Enter a zip code and verify it." />
      )}
      {zipError && <FormMessage status="error" text={zipError} />}
      {showInfoMessages && zipCodeInfo && (
        <FormMessage
          status="info"
          text="If this ZIP code is correct and aligns with the location, please keep it. If not, enter a different one."
        />
      )}
    </>
  );
};

Messages.propTypes = {
  showInfoMessages: PropTypes.bool.isRequired,
  zipError: PropTypes.string,
  zipCodeInfo: PropTypes.object,
};
