import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { BaseCard } from "../BaseCard";

export const ImageCard = ({ link, image, title, className }) => (
  <Link to={link}>
    <BaseCard
      link={link}
      className={className}
      body={() => (
        <>
          <img src={image} alt={title} className="custom-card__img" />
          <h4 className="text-center mt-2">{title}</h4>
        </>
      )}
    />
  </Link>
);

ImageCard.propTypes = {
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};
