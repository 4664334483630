import React from "react";
import { Link, useLocation } from "react-router-dom";

import { Footer, Header } from "../../components/organisms";

import "./styles.scss";

function Error404() {
  const location = useLocation();

  return (
    <div>
      <Header />
      <main id="maincontent" className="interior mt-5">
        <div className="message-404">
          <h2>The page '{location.pathname}' does not exist!</h2>
          <h3 className="mt-3">
            Would you like to return{" "}
            <Link to="/" className="home-link">
              Home
            </Link>{" "}
            instead?
          </h3>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Error404;
