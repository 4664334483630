import React from "react";

import { ImageCard } from "../../components/molecules";
import { PageWrapper } from "../../components/organisms";
import usersListIcon from "../../assets/images/users-list-icon.svg";
import interestRatesIcon from "../../assets/images/interest-rates-icon.svg";

import "./styles.scss";

const adminOptions = [
  {
    image: usersListIcon,
    title: "Users List",
    link: "/admin/users",
  },
  {
    image: interestRatesIcon,
    title: "Update Interest Rates",
    link: "/admin/rates",
  },
];

const AdminHome = () => {
  return (
    <PageWrapper>
      <div className="admin-home">
        <div className="admin-home__title">
          <h1>Select Type</h1>
        </div>
        <div className="admin-home__cards">
          {adminOptions.map((option, i) => (
            <ImageCard key={i} className="admin-home__card" {...option} />
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default AdminHome;
