import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import { menuItemPropTypes } from "../data";

export const MenuLink = ({ menuItem }) => (
  <Nav.Link
    className="menu__item"
    as={menuItem.link ? undefined : Link}
    href={menuItem.link}
    to={menuItem.appLink}
  >
    {menuItem.name}
  </Nav.Link>
);

MenuLink.propTypes = {
  menuItem: menuItemPropTypes,
};
