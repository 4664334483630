import { formatPrice } from "../../../utils";
import { subscriptionPlans } from "../../../shared-data";

export const columnConfigs = {
  loan_officer_state: {
    field: "states",
    columns: [
      { field: "code", label: "State" },
      { field: "price", label: "Price", format: formatPrice },
    ],
  },
  agent_zip_code: {
    field: "zipCodes",
    columns: [
      { field: "code", label: "Zip Code" },
      { field: "price", label: "Price", format: formatPrice },
    ],
  },
  default: {
    columns: [
      {
        field: "type",
        label: "Plan",
        format: (plan) => subscriptionPlans[plan] || plan,
      },
      { field: "subtotal", label: "Price", format: formatPrice },
    ],
  },
};
