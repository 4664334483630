import React from "react";

import PropTypes from "prop-types";

import {
  CustomFormGroup,
  CustomFormSelect,
  CustomInputGroup,
} from "../../atoms";

export const CustomSelect = ({ ...props }) => (
  <CustomFormGroup {...props}>
    <CustomInputGroup {...props}>
      <CustomFormSelect {...props} />
    </CustomInputGroup>
  </CustomFormGroup>
);

CustomSelect.propTypes = {
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  value: PropTypes.any,
  errors: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  groupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};
