import React from "react";

import "./styles.scss";

function UnavailableMessage() {
  return (
    <div className="wrapper">
      <div className="unavailable-message">
        <h2>
          AskChristee is temporarily unavailable while we perform routine
          maintenance ⚙︎️
        </h2>
        <h2 className="mt-3">
          We appreciate your patience during this time 😌
        </h2>
      </div>
    </div>
  );
}

export default UnavailableMessage;
