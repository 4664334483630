import PropTypes from "prop-types";
import { Card } from "react-bootstrap";

import { VideoLink } from "./VideoLink";

export const CarouselCard = ({
  image,
  title,
  link,
  video,
  linkTitle,
  description,
}) => (
  <Card className="carousel-help-card">
    <Card.Img className="carousel-help-card__img" src={image} />
    <Card.Body className="carousel-help-card__body">
      <div>
        <Card.Title className="carousel-help-card__title">{title}</Card.Title>
        <Card.Text className="carousel-help-card__description">
          {description}
        </Card.Text>
      </div>
      <div>
        <Card.Link className="carousel-help-card__link" href={link}>
          {linkTitle}
        </Card.Link>
        <VideoLink link={video} />
      </div>
    </Card.Body>
  </Card>
);

CarouselCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  video: PropTypes.string,
};
