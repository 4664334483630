import { LANDING_URL } from "../config";

export const subscriptionsInfo = {
  homebuyer: {
    learnMoreLink: `${LANDING_URL}/about/christee-for-home-buyers/`,
    content: [
      {
        type: "homebuyer_premium",
        title: "Homebuyer",
        premium: true,
        link: "/signup/homebuyer-premium",
      },
    ],
  },
  agent: {
    learnMoreLink: `${LANDING_URL}/about/christee-for-real-estate-agents/`,
    content: [
      {
        type: "agent_basic",
        title: "Agent",
        premium: false,
        link: "/signup/agent-basic",
        discount: {
          period: "first year",
        },
      },
      {
        type: "agent_zip_code",
        title: "Agent",
        premium: true,
        link: "/signup/agent-premium",
        renewLink: "/subscription/renew/agent-premium",
      },
    ],
  },
  "loan officer": {
    learnMoreLink: `${LANDING_URL}/about/for-loan-officers/`,
    content: [
      {
        type: "loan_officer_basic",
        title: "Loan Officer",
        premium: false,
        link: "/signup/loan-officer-basic",
        discount: {
          period: "first year",
        },
      },
      {
        type: "loan_officer_state",
        title: "Loan Officer",
        premium: true,
        link: "/signup/loan-officer-premium",
        renewLink: "/subscription/renew/loan-officer-premium",
      },
    ],
  },
};
