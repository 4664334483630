import React from "react";

import PropTypes from "prop-types";

import {
  CustomHookFormInput,
  VerifyZipCode,
} from "../../../components/molecules";

import suitcaseIcon from "../../../assets/images/suitcase-icon.svg";

import { SectionWrapper } from "./SectionWrapper";

export const WorkDetailsAgent = ({
  formValues,
  isPremium,
  errors,
  trigger,
  setIsZipCodeValid,
  control,
}) => {
  return (
    <SectionWrapper icon={suitcaseIcon} text="Work Details">
      <CustomHookFormInput
        label="License Number"
        name="license"
        errors={errors}
        control={control}
        rules={{
          required: "Required",
          pattern: {
            value: /^[A-Z0-9]{6,12}$/,
            message: "Invalid license number",
          },
        }}
      />
      {isPremium && (
        <VerifyZipCode
          setIsZipCodeValid={setIsZipCodeValid}
          label="Primary Zip Code"
          trigger={trigger}
          rules={{
            required: "Required",
          }}
          errors={errors}
          control={control}
          inputValue={formValues.zipCode}
        />
      )}
    </SectionWrapper>
  );
};

WorkDetailsAgent.propTypes = {
  formValues: PropTypes.object.isRequired,
  isPremium: PropTypes.bool,
  trigger: PropTypes.func,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setIsZipCodeValid: PropTypes.func.isRequired,
};
