import { useState } from "react";

import { ButtonWithSpinner, UiToast } from "@ask-christee/ui-response";

import { axiosInstance } from "../../api";
import { CHECKOUT_SESSION } from "../../config";

import mainHouse from "../../assets/images/main.png";

import { PageWrapper } from "../../components/organisms";

import "./styles.scss";

export const WelcomeToPlatform = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateSession = async () => {
    try {
      setIsLoading(true);

      const response = await axiosInstance.post(CHECKOUT_SESSION);

      if (response) {
        window.location.href = response.data.sessionUrl;
      }
    } catch (error) {
      UiToast.error(error?.response?.data || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper>
      <div className="welcome-message">
        <div className="welcome-message__body">
          <img className="welcome-message__image" src={mainHouse} alt="" />
          <h1 className="welcome-message__title">Welcome to Our Platform!</h1>
          <p className="welcome-message__text">
            To maintain full access and use the app, please purchase a
            subscription.
          </p>

          <ButtonWithSpinner
            spinnerVariant="light"
            onClick={handleCreateSession}
            className="welcome-message__button"
            loading={isLoading}
          >
            Continue
          </ButtonWithSpinner>
        </div>
      </div>
    </PageWrapper>
  );
};
