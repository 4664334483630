import React, { useContext } from "react";

import { Navigate, Outlet } from "react-router-dom";

import { CurrentUserProviderContext } from "../../../contexts";

const AdminRoute = ({ redirectRoute }) => {
  const { currentUser } = useContext(CurrentUserProviderContext);

  if (currentUser.isAdmin) return <Outlet />;

  return <Navigate to={redirectRoute} />;
};

export default AdminRoute;
