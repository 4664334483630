import React, { useContext, useState } from "react";

import { UiToast } from "@ask-christee/ui-response";

import { SubscriptionCard } from "../../molecules";
import { AddZipCodesModal } from "../AddZipCodesModal";
import {
  AccountDataProviderContext,
  CurrentUserProviderContext,
} from "../../../contexts";
import { UPGRADE_SUBSCRIPTION } from "../../../config";
import { AddStatesModal } from "../AddStatesModal";

export const SubscriptionToUpgrade = ({ subscriptionStatus, ...props }) => {
  const { currentUser, loadCurrentUser } = useContext(
    CurrentUserProviderContext,
  );
  const { setIsDataFetched } = useContext(AccountDataProviderContext);
  const [showModal, setShowModal] = useState(false);

  const onSuccessSubmit = async () => {
    setIsDataFetched((prevState) => ({
      ...prevState,
      nextInvoiceFetched: false,
      billingHistoryFetched: false,
      subscriptionItemsFetched: false,
      subscriptionLatestEndDate: false,
    }));

    await loadCurrentUser();

    UiToast.success("Your subscription has been successfully upgraded!");
  };

  return (
    <>
      <SubscriptionCard
        disabled={subscriptionStatus !== "active"}
        buttonText={
          subscriptionStatus === "pending downgrade" ? "Pending" : "Upgrade"
        }
        buttonVariant="primary"
        onButtonClick={() => setShowModal(true)}
        {...props}
      />

      {currentUser.accountType === "agent" && (
        <AddZipCodesModal
          maxItems={1}
          title="Upgrade"
          submitMethod="PATCH"
          showModal={showModal}
          isSubsequentPrice={false}
          submitUrl={UPGRADE_SUBSCRIPTION}
          onSuccessSubmit={onSuccessSubmit}
          handleCloseModal={() => setShowModal(false)}
          text="You are upgrading to our Premium plan, unlocking new capabilities. Choose ZIP code and start utilizing your subscription to the fullest."
        />
      )}

      {currentUser.accountType === "loan officer" && (
        <AddStatesModal
          title="Upgrade"
          maxItems={1}
          submitMethod="PATCH"
          showModal={showModal}
          submitUrl={UPGRADE_SUBSCRIPTION}
          onSuccessSubmit={onSuccessSubmit}
          handleCloseModal={() => setShowModal(false)}
          text="You are upgrading to our Premium plan, unlocking new capabilities. Choose State and start utilizing your subscription to the fullest."
        />
      )}
    </>
  );
};
