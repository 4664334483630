import PropTypes from "prop-types";

import infoIcon from "../../../assets/images/info-icon.svg";
import errorIcon from "../../../assets/images/error-icon.svg";
import successIcon from "../../../assets/images/success-icon.svg";

import "./styles.scss";

const messageModeConfig = {
  info: {
    icon: infoIcon,
    modeClass: "form-message_info",
  },
  success: {
    icon: successIcon,
    modeClass: "form-message_success",
  },
  error: {
    icon: errorIcon,
    modeClass: "form-message_error",
  },
};

export const FormMessage = ({ status, text, addition }) => {
  const config = messageModeConfig[status];

  return (
    <div className={`form-message ${config.modeClass}`}>
      <div className={`form-message__text-wrapper ${addition ? "mb-3" : ""}`}>
        <img
          className="form-message__image"
          src={config.icon}
          alt="message-icon"
        />
        <div className="form-message__text">{text}</div>
      </div>
      {addition && <div className="form-message__addition">{addition}</div>}
    </div>
  );
};

FormMessage.propTypes = {
  status: PropTypes.oneOf(Object.keys(messageModeConfig)).isRequired,
  text: PropTypes.string.isRequired,
  addition: PropTypes.element,
};
