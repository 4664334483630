import React from "react";
import PropTypes from "prop-types";

import { PasswordInput } from "../../../components/molecules";

import lockIcon from "../../../assets/images/lock-icon.svg";

import { SectionWrapper } from "./SectionWrapper";

export const Password = ({ control, errors }) => (
  <SectionWrapper icon={lockIcon} text="Password">
    <PasswordInput
      label="Create Password"
      errors={errors}
      rules={{
        required: "Required",
        pattern: {
          value:
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          message: "Invalid password",
        },
      }}
      control={control}
      fieldRequirements="Password should contain: at least 8 characters, one uppercase letter, one lowercase letter, one number, one special character."
    />
  </SectionWrapper>
);

Password.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
