import React from "react";

import PropTypes from "prop-types";

import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

import {
  CustomHookFormInput,
  CustomSelect,
  PhoneHookFormInput,
} from "../../../molecules";

import { profileImageValidator } from "../ProfileValidators";

export const PersonalInfoSection = ({
  currentUser,
  realtorPhoto,
  control,
  errors,
}) => (
  <>
    <h6 className="mt-3">Personal Information</h6>

    {currentUser.accountType === "loan officer" && (
      <CustomHookFormInput
        inputComponent={CustomSelect}
        name="speciality"
        label="Speciality"
        errors={errors}
        control={control}
        options={[
          {
            value: "mortgage broker",
            label: "Mortgage Broker",
          },
          {
            value: "mortgage lender",
            label: "Mortgage Lender",
          },
        ]}
      />
    )}

    <CustomHookFormInput
      label="First Name"
      rules={{
        required: "Required",
        maxLength: { value: 50, message: "Maximum length is 50" },
      }}
      name="firstName"
      control={control}
      errors={errors}
    />

    <CustomHookFormInput
      label="Last Name"
      rules={{
        required: "Required",
        maxLength: { value: 50, message: "Maximum length is 50" },
      }}
      name="lastName"
      control={control}
      errors={errors}
    />

    <CustomHookFormInput
      label="Email Address"
      rules={{ required: "Required" }}
      name="email"
      disabled={true}
      control={control}
      errors={errors}
    />

    <PhoneHookFormInput
      label="Cell phone"
      rules={{
        required: "Required",
      }}
      name="cellA"
      control={control}
      errors={errors}
    />

    <PhoneHookFormInput
      label="Office phone"
      rules={{
        required: "Required",
      }}
      name="primaryPhone"
      control={control}
      errors={errors}
    />
    <CustomHookFormInput
      label="Bio"
      rules={{
        required: "Required",
        minLength: {
          value: 20,
          message: "Minimum length is 20",
        },
        maxLength: { value: 1000, message: "Maximum length is 1000" },
      }}
      name="bio"
      as="textarea"
      control={control}
      errors={errors}
    />
    <Form.Group className="mt-3">
      <Form.Label>Upload Photo</Form.Label>
      <Controller
        rules={{
          validate: profileImageValidator,
        }}
        defaultValue=""
        name="realtorPhoto"
        control={control}
        render={({ field: { onChange, ref } }) => (
          <Form.Control
            accept="image/*"
            name="realtorPhoto"
            data-cy="realtorPhoto"
            type="file"
            className="upload-file"
            onChange={(e) => onChange(e.target.files[0])}
            ref={ref}
            isInvalid={!!errors.realtorPhoto}
          />
        )}
      />
      <Form.Control.Feedback type="invalid">
        {errors.realtorPhoto?.message}
      </Form.Control.Feedback>
    </Form.Group>
    {realtorPhoto && (
      <div className="mt-3 profile-preview">
        <img
          className="profile-preview__image"
          src={
            typeof realtorPhoto === "string"
              ? realtorPhoto
              : URL.createObjectURL(realtorPhoto)
          }
          alt="profile avatar"
        />
      </div>
    )}
  </>
);

PersonalInfoSection.propTypes = {
  currentUser: PropTypes.object,
  realtorPhoto: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  control: PropTypes.object,
  errors: PropTypes.object,
};
