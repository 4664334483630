import { Button, Modal } from "react-bootstrap";

const ResendCodeModal = ({ showModal, handleHideModal, buttonOnClick }) => {
  return (
    <Modal show={showModal} onHide={handleHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>The user has not been confirmed</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        <p style={{ fontSize: "20px" }}>
          Check your inbox for a verification email. Or use the{" "}
          <span style={{ color: "#0e306c" }}>"Resend mail"</span> button below
          to resend it
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          style={{ color: "#fff" }}
          onClick={buttonOnClick}
        >
          Resend mail
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ResendCodeModal;
