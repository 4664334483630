import React from "react";

import { Outlet } from "react-router-dom";
import { MobileOverlay } from "@ask-christee/ui-response";

import { Header, Footer } from "../../organisms";

const ModuleLayout = () => {
  return (
    <>
      <Header />
      <MobileOverlay />
      <main id="maincontent" className="interior">
        <div className="container content spacebelow">
          <Outlet />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ModuleLayout;
