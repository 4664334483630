const SalesPrice = [
  {
    code: "100000",
    text: "Less than $200,000",
  },
  {
    code: "200000",
    text: "$200,000 - $300,000",
  },
  {
    code: "300000",
    text: "$300,000 - $400,000",
  },
  {
    code: "400000",
    text: "$400,000 - $500,000",
  },
  {
    code: "500000",
    text: "$500,000 - $600,000",
  },
  {
    code: "600000",
    text: "$600,000 - $700,000",
  },
  {
    code: "700000",
    text: "$700,000 - $800,000",
  },
  {
    code: "800000",
    text: "$800,000 - $900,000",
  },
  {
    code: "900000",
    text: "$900,000 - $1,000,000",
  },
  {
    code: "1000000",
    text: "Above $1,000,000",
  },
];

export default SalesPrice;
