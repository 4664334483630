import {
  AGENTS_LO_RECOMMENDATIONS,
  AUTH_DOMAIN,
  CLIENT_ID,
  COGNITO_REGION,
  COGNITO_USER_POOL,
  CONTACT_AGENT_ZIP,
  DOWNLOAD_PDF,
  IDENTITY_POOL_ID,
  INTEREST_RATES,
  PRICING_REQUEST,
  SEND_PDF,
  WEBSITE_URL,
} from "./config";

export const amplifyConfig = {
  Auth: {
    region: COGNITO_REGION,
    identityPoolRegion: COGNITO_REGION,
    userPoolWebClientId: CLIENT_ID,
    identityPoolId: IDENTITY_POOL_ID,
    userPoolId: COGNITO_USER_POOL,
    oauth: {
      domain: AUTH_DOMAIN,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: WEBSITE_URL,
      redirectSignOut: WEBSITE_URL,
      responseType: "token",
    },
  },
};

export const cognitoAuthConfig = {
  authenticationFlowType: "CUSTOM_AUTH",
};

export const uiLibApiUrls = {
  sendPdf: SEND_PDF,
  downloadPdf: DOWNLOAD_PDF,
  agentZip: CONTACT_AGENT_ZIP,
  pricingRequest: PRICING_REQUEST,
  interestRates: INTEREST_RATES,
  recommendations: AGENTS_LO_RECOMMENDATIONS,
};
