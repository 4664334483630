import React from "react";

import { PageWrapper } from "../../components/organisms";

const OnBoardMessage = () => (
  <PageWrapper>
    <div style={{ paddingTop: "150px" }}>
      <h1>Onboarding Instructions</h1>
      <p>
        Verify your email by clicking the link we sent. It will lead you to the
        login page. Once you're in, make sure to subscribe for full access to
        the app.
      </p>
    </div>
  </PageWrapper>
);

export default OnBoardMessage;
