export const getTitleConfig = ({
  status,
  nextInvoiceDate,
  subscriptionLatestEndDate,
}) => {
  const statusConfig = {
    "pending cancellation": {
      text: "Active until:",
      date: subscriptionLatestEndDate,
      isRetracted: true,
    },
    "pending downgrade": {
      text: "Active until:",
      date: subscriptionLatestEndDate,
      isRetracted: true,
    },
    past_due: {
      text: "Subscription Past Due",
      isRetracted: true,
    },
  };

  const defaultConfig = {
    text: "Next invoice date:",
    date: nextInvoiceDate,
    isRetracted: false,
  };

  return statusConfig[status] || defaultConfig;
};
