import React from "react";

import { Loader } from "../../atoms";
import { Footer, Header } from "../../organisms";

import "./styles.scss";

export const PageLoader = ({ text }) => {
  return (
    <div>
      <Header />
      <main className="interior mt-5">
        <div className="custom-page-loader">
          <Loader />
          <h2>{text} </h2>
        </div>
      </main>
      <Footer />
    </div>
  );
};
