import React, { useCallback } from "react";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { UiToast } from "@ask-christee/ui-response";

import { FormMessage } from "../../atoms";
import { capitalizeFirstLetter } from "../../../utils";

export const SubscriptionItemForm = ({
  items,
  label,
  maxItems,
  onSubmit,
  fieldName,
  displayFieldName,
  VerifyItemComponent,
}) => {
  const {
    control,
    trigger,
    watch,
    resetField,
    formState: { errors },
  } = useForm();

  const fieldValue = watch(fieldName);

  const preValidation = useCallback(() => {
    if (items.length >= maxItems) {
      UiToast.error(`You can add a maximum of ${maxItems} ${label}s at a time`);
      return false;
    }
    if (items.some((item) => item[displayFieldName] === fieldValue)) {
      UiToast.error(`This ${label} has already been added`);
      return false;
    }
    return true;
  }, [displayFieldName, fieldValue, items, label, maxItems]);

  const onSuccessVerification = useCallback(
    (itemInfo) => {
      if (fieldValue) {
        onSubmit({ itemInfo, fieldValue });
        resetField(fieldName);
      }
    },
    [fieldValue, onSubmit, resetField, fieldName],
  );

  return (
    <>
      <VerifyItemComponent
        enableToastMessages
        errors={errors}
        trigger={trigger}
        control={control}
        fieldName={fieldName}
        preValidation={preValidation}
        placeholder={`Enter ${label}`}
        inputValue={fieldValue}
        label={`${capitalizeFirstLetter(label)}(s)`}
        onSuccessVerification={onSuccessVerification}
      />
      {!items.length && (
        <FormMessage status="info" text={`Enter a ${label} and verify it.`} />
      )}
    </>
  );
};

SubscriptionItemForm.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  maxItems: PropTypes.number.isRequired,
  fieldName: PropTypes.string.isRequired,
  displayFieldName: PropTypes.string.isRequired,
  VerifyItemComponent: PropTypes.elementType.isRequired,
};
