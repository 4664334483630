import PropTypes from "prop-types";
import { Nav, Navbar } from "react-bootstrap";

import { DropdownMenu } from "./DropdownMenu";

import { MenuLink } from "./MenuLink";
import { menuItemPropTypes } from "../data";

export const NavItems = ({ menuItems }) => (
  <Navbar>
    <Navbar.Toggle aria-controls="basic-navbar-nav" className="menu" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="menu">
        {menuItems.map((menuItem) =>
          menuItem.subItems ? (
            <DropdownMenu menuItem={menuItem} key={menuItem.name} />
          ) : (
            <MenuLink menuItem={menuItem} key={menuItem.name} />
          ),
        )}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

NavItems.propTypes = {
  menuItems: PropTypes.arrayOf(menuItemPropTypes),
};
