import PropTypes from "prop-types";

import Pagination from "react-bootstrap/Pagination";

import { DOTS, usePaginationControls } from "./usePaginationControls";

export const CustomPagination = ({
  paginate,
  totalCount,
  currentPage,
  siblingCount = 1,
  pageSize = 10,
}) => {
  const paginationRange = usePaginationControls({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <Pagination className="ms-0">
      <Pagination.Prev
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <Pagination.Ellipsis key={pageNumber + index} />;
        }

        return (
          <Pagination.Item
            key={pageNumber}
            onClick={() => paginate(pageNumber)}
            active={currentPage === pageNumber}
          >
            {pageNumber}
          </Pagination.Item>
        );
      })}
      <Pagination.Next
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === lastPage}
      />
    </Pagination>
  );
};

CustomPagination.propTypes = {
  paginate: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  pageSize: PropTypes.number,
};
