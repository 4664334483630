import React, { useState } from "react";

import { Button } from "react-bootstrap";

import { BaseModal } from "../../components/atoms";

export const InfoModal = ({ modalText }) => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);

  return (
    <BaseModal
      show={showModal}
      size="md"
      handleClose={handleClose}
      title="Reserve time has expired"
      className="re-enter-purchase-modal"
    >
      <div className="re-enter-purchase-modal__body">
        <p className="re-enter-purchase-modal__text">{modalText}</p>
        <Button
          className="re-enter-purchase-modal__button"
          variant="primary"
          onClick={handleClose}
        >
          Continue
        </Button>
      </div>
    </BaseModal>
  );
};
