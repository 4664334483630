import React from "react";

import PropTypes from "prop-types";
import { InputGroup } from "react-bootstrap";

import { resolvePath } from "../../../utils";

import "./styles.scss";

export const CustomInputGroup = ({ errors, name, children }) => (
  <InputGroup
    className={`custom-input-group ${
      !!resolvePath(name, errors) ? "custom-input-group_error" : ""
    }`}
  >
    {children}
  </InputGroup>
);

CustomInputGroup.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
