import React from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { LANDING_URL } from "../../../config";

import mainHouse from "../../../assets/images/main.png";
import logo from "../../../assets/images/askchristee-logo.svg";

import "./styles.scss";

const defaultTitle =
  "Discover a better path to home buying with advanced technology";

export const AuthCover = ({ title, isCentered = false, children }) => {
  return (
    <Row m={0} className="auth-cover">
      <Col xl={4} className="auth-cover__left">
        <a href={LANDING_URL}>
          <img src={logo} alt="logo" className="auth-cover__logo" />
        </a>
        <div className="auth-cover__left-main-section">
          <h3 className="auth-cover__title">{title || defaultTitle}</h3>
          <img
            width={225}
            src={mainHouse}
            alt="main-house"
            className="auth-cover__image"
          />
        </div>
      </Col>
      <Col
        xl={8}
        className={`auth-cover__right ${
          isCentered ? "auth-cover__right--centered" : ""
        }`}
      >
        <div className="auth-container  m-auto">{children}</div>
      </Col>
    </Row>
  );
};

AuthCover.propTypes = {
  title: PropTypes.string,
  isCentered: PropTypes.bool,
  children: PropTypes.node,
};
