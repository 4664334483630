import PropTypes from "prop-types";

import States from "../../../common/States";
import { CustomFormControl } from "../../atoms";

import { DownloadCsv } from "./DownloadCsv";
import { TableDropdownFilter } from "./TableDropdownFilter";

export const TableControls = ({
  filter,
  setFilter,
  setCurrentPage,
  setSearch,
  search,
}) => {
  const handleFilterChange = (fieldName, value) => {
    if (value === "all") {
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter };
        delete newFilter[fieldName];
        return newFilter;
      });
    } else {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [fieldName]: value,
      }));
    }
    setCurrentPage(1);
  };

  return (
    <div className="users-table-controls">
      <div className="users-table-controls__left-section">
        <TableDropdownFilter
          filterTitle="Account Type"
          currentFilter={filter.accountType || "all"}
          onFilterChange={handleFilterChange}
          filterOptions={[
            { value: "all", label: "All" },
            { value: "homebuyer", label: "Homebuyer" },
            { value: "agent", label: "Agent" },
            { value: "loan officer", label: "Loan Officer" },
          ]}
          filterKey="accountType"
        />
        <TableDropdownFilter
          filterTitle="Referral"
          currentFilter={filter.referral || "all"}
          onFilterChange={handleFilterChange}
          filterOptions={[
            { value: "all", label: "All" },
            { label: "Colleague/friend", value: "colleague" },
            { label: "Social media", value: "social" },
            { label: "Search engine", value: "search" },
            { label: "Blog/publication", value: "blog" },
            { label: "Other", value: "other" },
          ]}
          filterKey="referral"
        />
        <TableDropdownFilter
          filterTitle="State"
          currentFilter={filter.state || "all"}
          onFilterChange={handleFilterChange}
          filterOptions={[
            { value: "all", label: "All" },
            ...States.map((item) => ({
              label: item.text,
              value: item.text,
            })),
          ]}
          filterKey="state"
        />
      </div>
      <div className="users-table-controls__right-section">
        <CustomFormControl
          label="Search"
          inputClassName="users-table-controls__search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          name="search"
          placeholder="Search"
        />
        <DownloadCsv filter={filter} search={search} />
      </div>
    </div>
  );
};

TableControls.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string,
};
