import { UnavailableMessage } from "../../../views";

const UnavailabilityWrapper = ({ children }) => {
  if (process.env.REACT_APP_APP_MAINTENANCE === "true") {
    return <UnavailableMessage />;
  }

  return children;
};

export default UnavailabilityWrapper;
