import React from "react";

import { formatDate } from "../../../utils";

import { Action, Referral, States } from "./custom-table-cells";

import "./styles.scss";

export const columnConfig = [
  {
    dataField: "fullName",
    text: "User",
    formatter: (cell, row) => {
      if (!row.isCompletedProfile) {
        return cell;
      }

      if (row.accountType === "agent") {
        return (
          <a
            target="_blank"
            href={`${window.location.origin}/officers/${row.Id}`}
            rel="noreferrer"
          >
            {cell}
          </a>
        );
      } else if (row.accountType === "loan officer") {
        return (
          <a
            target="_blank"
            href={`${window.location.origin}/agents/${row.Id}`}
            rel="noreferrer"
          >
            {cell}
          </a>
        );
      } else {
        return cell;
      }
    },
    sort: true,
  },
  {
    dataField: "accountType",
    text: "Account Type",
    sort: true,
  },
  { dataField: "subscriptionType", text: "Plan Type", sort: true },
  { dataField: "subscriptionStatus", text: "Plan Status", sort: true },
  {
    dataField: "createdAt",
    text: "Created At",
    sort: true,
    formatter: (item) => formatDate(new Date(item)),
  },
  {
    dataField: "subscriptionStates",
    text: "State",
    isFormatterComponent: true,
    formatter: (item) => <States item={item} />,
  },
  { dataField: "email", text: "Email", sort: false },
  {
    dataField: "referral",
    text: "Referral",
    formatter: (item) => <Referral item={item} />,
    sort: false,
  },
  {
    dataField: "action",
    text: "Action",
    formatter: (_, row, fetchData) => (
      <Action row={row} fetchData={fetchData} />
    ),
    sort: false,
  },
];
