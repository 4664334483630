import { useOpenController } from "../../../hooks";

import DownloadIcon from "../../../assets/images/download-icon.svg";

import { ExpandableTableRow } from "./ExpendableTableRow";
import { ExpendableButton } from "./ExpendableButton";

export const CustomTableRow = ({ record, columns, expandableColumns }) => {
  const { isOpen, toggle } = useOpenController(false);

  const renderCell = (column, record) => {
    if (column.format && record[column.field]) {
      return column.format(record[column.field]);
    }
    return record[column.field];
  };

  const isExpandable = expandableColumns?.some(
    (config) => record[config.field]?.length > 0,
  );

  return (
    <>
      <tr
        key={record?.id}
        className={`custom-table__row ${
          isExpandable && "custom-table__row--clickable"
        }`}
        onClick={() => isExpandable && toggle()}
      >
        {columns.map((column, index) => (
          <td
            key={column?.label}
            className={`custom-table__row-item ${
              index === 0 && isExpandable && "d-flex"
            }`}
          >
            {index === 0 && isExpandable && (
              <ExpendableButton isOpen={isOpen} />
            )}

            <span key={column?.label} className="custom-table__cell-content">
              {column?.field === "special" ? (
                <a
                  href={record?.invoicePdf}
                  onClick={(e) => e.stopPropagation()}
                >
                  <img src={DownloadIcon} alt="download" width="28" />
                </a>
              ) : (
                renderCell(column, record)
              )}
            </span>
          </td>
        ))}
      </tr>

      {isOpen &&
        expandableColumns.map((config) =>
          record[config.field]?.length ? (
            <ExpandableTableRow
              key={config.field}
              items={record[config.field]}
              columns={config.columns}
            />
          ) : null,
        )}
    </>
  );
};
