import { Tabs } from "react-bootstrap";

import "./styles.scss";

export const CustomTabs = ({ activeKey, onSelect, children }) => {
  return (
    <Tabs activeKey={activeKey} onSelect={onSelect} className="custom-tabs">
      {children}
    </Tabs>
  );
};
